import { EAccountType } from '@tigertrade/binance-ts'
import { chain, reject } from 'lodash-es'
import { TStatisticsHistoryItem } from '@/redux/overview/overview.types'
import { customLocalStorage } from './customLocalStorage'
import { TExchangesAvailable, TLanguage } from '@/core/constants'

const keys = {
  shownPopupList: 'shownPopupList',
  referralCode: 'referralCode',
  locale: 'locale',
  statisticsCache: 'statisticsCache',
  VerifiedWithTwoFa: 'VerifiedWithTwoFa',
  isBinanceLinkShown: 'isBinanceLinkShown',
}

type TLocalStorafeRefcode = {
  referralCode: string
  date?: number
}

export const storage = {
  cleanAll: () => {
    storage.cleanShownPopupList()
    storage.cleanStatisticsCache()
    storage.clearUserSpecificLocalStorageItems()
    storage.clearStatisticsCache()
    storage.clearVerifiedWithTwoFa()
  },

  /**
   * @deprecated
   * TODO: remove after 01.05.2024
   * Вовращает сохраненный в локалсторадже список уже показаных попапов
   */
  getShownPopupList: () => {
    const item = customLocalStorage.get(keys.shownPopupList)
    return Array.isArray(item) ? item : []
  },

  /**
   * @deprecated
   * TODO: remove after 01.05.2024
   * Добавляет название попапа в локалстораджевый список уже показаных попапов
   */
  addToShownPopupList: <T = string>(popupName: T) => {
    const arr = chain(storage.getShownPopupList()).push(popupName).uniq().value()
    customLocalStorage.set(keys.shownPopupList, arr)
  },

  /**
   * @deprecated
   * TODO: remove after 01.05.2024
   * Удаляет название попапа из локалстораджевый список уже показаных попапов
   */
  removeFromToShownPopupList: <T = string>(popupName: T) => {
    const arr = reject(storage.getShownPopupList(), item => item === popupName)
    customLocalStorage.set(keys.shownPopupList, arr)
  },

  /**
   * @deprecated
   * TODO: remove after 01.05.2024
   * Удаляет из локалстораджа вообще все об показаных попаповх
   */
  cleanShownPopupList: () => {
    localStorage.removeItem(keys.shownPopupList)
  },

  /**
   * @deprecated
   * TODO: remove after 01.05.2024
   * Удаляет из локалстораджа старую статистику
   */
  cleanStatisticsCache: () => {
    localStorage.removeItem(keys.statisticsCache)
  },

  getIsKycDoneNotified: (login: string) => {
    const isKycDoneNotified = customLocalStorage.get(`isKycDoneNotified-${login}`)
    return Boolean(isKycDoneNotified)
  },

  removeIsKycDoneNotified: (login: string) => {
    customLocalStorage.remove(`isKycDoneNotified-${login}`)
  },

  /**
   * Возвращает реферальный код, сохраненный при переходе на страницу регистрации
   */
  getReferralCode: (): TLocalStorafeRefcode => {
    const storageData = customLocalStorage.get(keys.referralCode)
    if (storageData === null || storageData === undefined) {
      return {
        referralCode: '',
      }
    }
    if (typeof storageData === 'string')
      return {
        referralCode: storageData,
      }
    else if ('referralCode' in storageData) return storageData
    return {
      referralCode: '',
    }
  },

  /**
   * Сохраняет реферальный код для использования после регистрации
   */
  setReferralCode: (referralCode: string) => {
    customLocalStorage.set(keys.referralCode, {
      referralCode,
      date: new Date().getTime(),
    })
  },

  /**
   * Удаляет реферальный код
   */
  clearReferralCode: () => {
    localStorage.removeItem(keys.referralCode)
  },

  getLocale: () => {
    return localStorage.getItem(keys.locale)
  },

  setLocale: (locale: TLanguage) => {
    localStorage.setItem(keys.locale, locale)
  },

  getShownBinanceLinkOnce: () => {
    return localStorage.getItem(keys.isBinanceLinkShown)
  },

  setShownBinanceLinkOnce: (value: string) => {
    localStorage.setItem(keys.isBinanceLinkShown, value)
  },

  getVerifiedWithTwoFa: () => {
    return localStorage.getItem(keys.VerifiedWithTwoFa)
  },

  setVerifiedWithTwoFa: (value: string) => {
    localStorage.setItem(keys.VerifiedWithTwoFa, value)
  },

  clearVerifiedWithTwoFa: () => {
    localStorage.removeItem(keys.VerifiedWithTwoFa)
  },

  getUserSpecificLocalStorageItems: () => {
    const storage = { ...localStorage }

    const accountPrefixesKeys = Object.keys(storage).filter(key => {
      return Object.values(EAccountType).find(account => {
        return key.includes(`${account}-u-`)
      })
    })

    return [...accountPrefixesKeys]
  },

  clearUserSpecificLocalStorageItems: () => {
    const storageItemsArray = storage.getUserSpecificLocalStorageItems()
    storageItemsArray.forEach(storageItem => {
      customLocalStorage.remove(storageItem)
    })
  },

  setStatisticsCache: (data: TStatisticsHistoryItem[], exchangeType: TExchangesAvailable) => {
    customLocalStorage.set(exchangeType, JSON.stringify(data))
  },

  getStatisticsCache: (exchangeType: TExchangesAvailable): TStatisticsHistoryItem[] => {
    const storageData = customLocalStorage.get(exchangeType)
    if (storageData === null || storageData === undefined) {
      return []
    }
    if (typeof storageData === 'string') {
      try {
        return JSON.parse(storageData)
      } catch {
        return []
      }
    }
    return []
  },

  clearStatisticsCache: () => {
    customLocalStorage.remove('BINANCE_BROKER_FUTURE')
    customLocalStorage.remove('BINANCE_VIP')
    customLocalStorage.remove('OKX_BROKER')
    customLocalStorage.remove('BYBIT_BROKER')
  },
}
