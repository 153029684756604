import { SelectOption } from '@/ui/kit'
import { ServicesTradeDurationType } from '@/utils/types/contracts.types'
import { t } from '@lingui/macro'

export const dateRangeOption = (isDashboardFilter?: boolean) => {
  const marketTypeOptions: SelectOption<ServicesTradeDurationType>[] = [
    {
      label: t({
        id: 'history.trades.dateRangeToday',
        comment: 'Today',
      }),
      value: ServicesTradeDurationType.TradeDurationTypeToday,
    },
    {
      label: t({
        id: 'history.trades.dateRangeYesterday',
        comment: 'Yesterday',
      }),
      value: ServicesTradeDurationType.TradeDurationTypeYesterday,
    },
    {
      label: t({
        id: 'history.trades.dateRangeThisWeek',
        comment: 'This week',
      }),
      value: ServicesTradeDurationType.TradeDurationType1W,
    },
    {
      label: t({
        id: 'history.trades.dateRangePastWeek',
        comment: 'Past week',
      }),
      value: ServicesTradeDurationType.TradeDurationTypePast1W,
    },
    {
      label: t({
        id: 'history.trades.dateRangeThisMonth',
        comment: 'This month',
      }),
      value: ServicesTradeDurationType.TradeDurationType1M,
    },
    {
      label: t({
        id: 'history.trades.dateRangeSevenDays',
        comment: 'Seven Days',
      }),
      value: ServicesTradeDurationType.TradeDurationType7D,
    },
    {
      label: t({
        id: 'history.trades.dateRangeThirtyDays',
        comment: '30 days',
      }),
      value: ServicesTradeDurationType.TradeDurationType30D,
    },
    {
      label: t({
        id: 'history.trades.dateRangeNinetyDays',
        comment: '90 days',
      }),
      value: ServicesTradeDurationType.TradeDurationType90D,
    },
  ]

  if (isDashboardFilter) {
    marketTypeOptions.unshift({
      label: t({
        id: 'history.trades.withoutFilter',
        comment: 'Without Filter',
      }),
      value: ServicesTradeDurationType.TradeWithoutDuration,
    })
  }

  return marketTypeOptions
}
