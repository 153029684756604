import { ApiKeysAPI, IRestRequestGeneric, TCreateApiKeyPayloadBybit } from '@/backend/api/apiKeyAPI'
import { AxiosResponse } from 'axios'
import { ApiKeyCreationDTO } from '@/backend/models/ApiKeyDTO'
import { IApiWrapper } from '../types'
import { ERROR_API_UNCOMPATIBLE_PAYLOAD } from '@/core/constants/errors/rest'
import { errorAsyncCreator, voidFunction } from '../utils'
import { LeverageApi, OverviewAPI } from '@/backend/api'
import {
  TCancelAllOpenOrdersPayload,
  TCancelAllOpenOrdersPayloadBybitDTO,
  TCancelAllOpenOrdersResponse,
  TCancelAllOpenOrdersResponseBybitDTO,
  TCancelOpenOrderPayloadUnion,
  TCancelOpenOrderPayloadBybit,
  TCancelOpenOrderPayloadBybitDTO,
  TCancelOpenOrderResponse,
  TCancelOpenOrderResponseBybitDTO,
  TCloseAllPositionsPayload,
  TCloseAllPositionsPayloadBybitDTO,
  TCloseAllPositionsResponse,
  TCloseAllPositionsResponseBybitDTO,
  TClosePositionPayload,
  TClosePositionPayloadBybitDTO,
  TClosePositionResponse,
  TClosePositionResponseBybitDTO,
} from '@/backend/models/OverviewDTO'
import {
  TSetLeveragePayloadBybit,
  TSetLeveragePayloadUnion,
  TSetLeverageResponse,
  TSetLeverageResponseBybit,
} from '@/redux/leverage/leverage.types'
import { EBybitInstruments } from '@/services/bybit'
import { POSITION_SIDE_MAP_REVERSE } from '@/services/bybit/mappers'
import { PositionIdx } from 'bybit-api'
import { TSetMarginPayloadBybit, TSetMarginResponseBybit, TSetMarginResponse } from '@/backend/models/LeverageDTO'
import { MARGIN_BYBIT_TO_MARGIN } from '@/core/mappers/values'
import {
  TSellAssetPayload,
  TSellAssetPayloadBybit,
  TSellAssetPayloadUnion,
  TSellAssetResponse,
  TSellAssetResponseByBit,
} from '@/redux/overview/overview.types'

export const BybitApiWrapper: IApiWrapper = {
  methods: {
    apiKey: {
      createApiKey: ApiKeysAPI.createApiKey as IRestRequestGeneric<
        TCreateApiKeyPayloadBybit,
        AxiosResponse<ApiKeyCreationDTO>
      >,
    },
    overview: {
      cancelOpenOrder: OverviewAPI.cancelOpenOrder as IRestRequestGeneric<
        TCancelOpenOrderPayloadBybitDTO,
        AxiosResponse<TCancelOpenOrderResponseBybitDTO>
      >,
      cancelAllOpenOrders: OverviewAPI.cancelAllOpenOrders as IRestRequestGeneric<
        TCancelAllOpenOrdersPayloadBybitDTO,
        AxiosResponse<TCancelAllOpenOrdersResponseBybitDTO>
      >,
      closePosition: OverviewAPI.closePosition as IRestRequestGeneric<
        TClosePositionPayloadBybitDTO,
        AxiosResponse<TClosePositionResponseBybitDTO>
      >,
      closeAllPositions: OverviewAPI.closeAllPositions as IRestRequestGeneric<
        TCloseAllPositionsPayloadBybitDTO,
        AxiosResponse<TCloseAllPositionsResponseBybitDTO>
      >,
      setPositionMode: voidFunction,
      sellAsset: OverviewAPI.sellAsset as IRestRequestGeneric<
        TSellAssetPayloadUnion,
        AxiosResponse<TSellAssetResponseByBit>
      >,
    },
    leverage: {
      setLeverage: LeverageApi.setLeverage as IRestRequestGeneric<
        TSetLeveragePayloadBybit,
        AxiosResponse<TSetLeverageResponseBybit>
      >,
      setMargin: LeverageApi.setMargin as IRestRequestGeneric<
        TSetMarginPayloadBybit,
        AxiosResponse<TSetMarginResponseBybit>
      >,
    },
  },
  preformatters: {
    apiKey: {
      createApiKey: (payload: TCreateApiKeyPayloadBybit) => payload,
    },
    overview: {
      cancelOpenOrder: (payload: TCancelOpenOrderPayloadBybit): TCancelOpenOrderPayloadBybitDTO => ({
        accountType: payload.accountType,
        orderId: payload.orderId,
        symbol: payload.symbol,
        category: payload.instrumentType,
        ...(payload.instrumentType === EBybitInstruments.spot
          ? {
              orderFilter: payload.orderFilter,
            }
          : {}),
      }),
      cancelAllOpenOrders: (payload: TCancelAllOpenOrdersPayload): TCancelAllOpenOrdersPayloadBybitDTO => ({
        accountType: payload.accountType,
        symbols: payload.orders.map(payloadOrder => {
          return {
            category: payload.instrumentType as EBybitInstruments,
            ...(payload.instrumentType === EBybitInstruments.spot
              ? {
                  orderFilter: payloadOrder.orderFilter,
                }
              : {
                  symbol: payloadOrder.symbol,
                }),
          }
        }),
      }),
      closePosition: (payload: TClosePositionPayload): TClosePositionPayloadBybitDTO => {
        return {
          accountType: payload.accountType,
          category: payload.instrumentType as EBybitInstruments,
          symbol: payload.symbol,
          side: POSITION_SIDE_MAP_REVERSE[payload.positionSide],
          qty: payload.positionAmt.toString(),
          positionIdx: payload.positionIdx as PositionIdx,
        }
      },
      closeAllPositions: (payload: TCloseAllPositionsPayload): TCloseAllPositionsPayloadBybitDTO => ({
        accountType: payload.accountType,
        positions: payload.positions.map(sourcePosition => {
          return {
            category: payload.instrumentType as EBybitInstruments,
            qty: sourcePosition.positionAmt.toString(),
            side: POSITION_SIDE_MAP_REVERSE[sourcePosition.positionSide],
            symbol: sourcePosition.symbol,
            positionIdx: sourcePosition.positionIdx as PositionIdx,
          }
        }),
      }),
      setPositionMode: voidFunction,
      sellAsset: (payload: TSellAssetPayload): TSellAssetPayloadBybit => {
        return {
          accountType: payload.accountType,
          qty: payload.quantity,
          symbol: payload.symbol,
        }
      },
    },
    leverage: {
      setLeverage: (payload: TSetLeveragePayloadBybit): TSetLeveragePayloadBybit => {
        return payload
      },
      setMargin: (payload: TSetMarginPayloadBybit): TSetMarginPayloadBybit => {
        return payload
      },
    },
  },
  normalizers: {
    apiKey: {
      createApiKey: (source: ApiKeyCreationDTO): ApiKeyCreationDTO => {
        return {
          ...source,
        }
      },
    },
    overview: {
      cancelOpenOrder: (
        source: TCancelOpenOrderResponseBybitDTO,
        payload: TCancelOpenOrderPayloadUnion
      ): TCancelOpenOrderResponse => {
        return {
          accountType: source.accountType,
          instrumentType: payload.instrumentType,
          orderId: source.orderId,
          success: source.retCode === 0,
          msg: source.retMsg,
        }
      },
      cancelAllOpenOrders: (
        source: TCancelAllOpenOrdersResponseBybitDTO,
        payload: TCancelOpenOrderPayloadUnion
      ): TCancelAllOpenOrdersResponse => {
        return {
          accountType: source.accountType,
          instrumentType: payload.instrumentType,
          success: source.retCode === 0,
          errors: [source.retMsg],
        }
      },
      closePosition: (
        source: TClosePositionResponseBybitDTO,
        payload: TClosePositionPayload
      ): TClosePositionResponse => {
        return {
          accountType: source.accountType,
          instrumentType: payload.instrumentType,
          positionId: payload.positionId,
          success: source.retCode === 0,
          error: source.retMsg,
        }
      },
      closeAllPositions: (
        source: TCloseAllPositionsResponseBybitDTO,
        payload: TCloseAllPositionsPayload
      ): TCloseAllPositionsResponse => {
        return {
          accountType: source.accountType,
          instrumentType: payload.instrumentType,
          success: source.retCode === 0,
          error: source.retMsg,
        }
      },
      setPositionMode: voidFunction,
      sellAsset: (source: TSellAssetResponseByBit, payload: TSellAssetPayloadUnion): TSellAssetResponse => {
        return {
          accountType: source.accountType,
          msg: source.retMsg,
          code: source.retCode,
        }
      },
    },
    leverage: {
      setLeverage: (source: TSetLeverageResponseBybit, payload: TSetLeveragePayloadBybit): TSetLeverageResponse => {
        return {
          accountType: source.accountType,
          leverage: Number(payload.buyLeverage),
          symbol: payload.symbol,
        }
      },
      setMargin: (source: TSetMarginResponseBybit, payload: TSetMarginPayloadBybit): TSetMarginResponse => {
        return {
          accountType: source.accountType,
          marginType: MARGIN_BYBIT_TO_MARGIN[payload.setMarginMode],
        }
      },
    },
  },
  checkers: {
    apiKey: {
      createApiKey: (payload: any): payload is TCreateApiKeyPayloadBybit => {
        return 'spotEnabled' in payload
      },
    },
    overview: {
      cancelOpenOrder: (payload: TCancelOpenOrderPayloadBybit) => {
        return 'orderId' in payload
      },
      cancelAllOpenOrders: (payload: TCancelAllOpenOrdersPayload) => {
        return (
          'orders' in payload && Object.values(EBybitInstruments).includes(payload.instrumentType as EBybitInstruments)
        )
      },
      closePosition: (payload: TClosePositionPayload) => {
        return 'positionIdx' in payload && payload.positionIdx !== undefined
      },
      closeAllPositions: (payload: TCloseAllPositionsPayload) => {
        return (
          Object.values(EBybitInstruments).includes(payload.instrumentType as EBybitInstruments) &&
          payload.positions.map(positionSource => {
            return 'positionIdx' in positionSource && positionSource.positionIdx !== undefined
          })
        )
      },
      setPositionMode: voidFunction,
      sellAsset: (payload: any): payload is TSellAssetPayloadBybit => {
        return 'qty' in payload
      },
    },
    leverage: {
      setLeverage: (payload: TSetLeveragePayloadUnion): payload is TSetLeveragePayloadBybit => {
        return 'category' in payload && Object.values(EBybitInstruments).includes(payload.category as EBybitInstruments)
      },
      setMargin: (payload: any): payload is TSetLeveragePayloadBybit => {
        return 'setMarginMode' in payload
      },
    },
  },
  caller: {
    apiKey: {
      createApiKey: async payload => {
        if (BybitApiWrapper.checkers.apiKey.createApiKey(payload)) {
          const res = await BybitApiWrapper.methods.apiKey.createApiKey(payload)
          return {
            ...res,
            data: BybitApiWrapper.normalizers.apiKey.createApiKey(res.data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
    },
    overview: {
      cancelOpenOrder: async data => {
        if (BybitApiWrapper.checkers.overview.cancelOpenOrder(data)) {
          const payload = BybitApiWrapper.preformatters.overview.cancelOpenOrder(data)

          const res = await BybitApiWrapper.methods.overview.cancelOpenOrder(payload)
          return {
            ...res,
            data: BybitApiWrapper.normalizers.overview.cancelOpenOrder(res.data, data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      cancelAllOpenOrders: async data => {
        if (BybitApiWrapper.checkers.overview.cancelAllOpenOrders(data)) {
          const payload = BybitApiWrapper.preformatters.overview.cancelAllOpenOrders(data)
          const res = await BybitApiWrapper.methods.overview.cancelAllOpenOrders(payload)
          return {
            ...res,
            data: BybitApiWrapper.normalizers.overview.cancelAllOpenOrders(res.data, data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      closePosition: async data => {
        if (BybitApiWrapper.checkers.overview.closePosition(data)) {
          const payload = BybitApiWrapper.preformatters.overview.closePosition(data)
          const res = await BybitApiWrapper.methods.overview.closePosition(payload)
          return {
            ...res,
            data: BybitApiWrapper.normalizers.overview.closePosition(res.data, data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      closeAllPositions: async data => {
        if (BybitApiWrapper.checkers.overview.closeAllPositions(data)) {
          const payload = BybitApiWrapper.preformatters.overview.closeAllPositions(data)

          const res = await BybitApiWrapper.methods.overview.closeAllPositions(payload)
          return {
            ...res,
            data: BybitApiWrapper.normalizers.overview.closeAllPositions(res.data, data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      setPositionMode: async (): Promise<any> => {},
      sellAsset: async data => {
        const payload = BybitApiWrapper.preformatters.overview.sellAsset(data)

        if (BybitApiWrapper.checkers.overview.sellAsset(payload)) {
          const res = await BybitApiWrapper.methods.overview.sellAsset(payload)

          return {
            ...res,
            data: BybitApiWrapper.normalizers.overview.sellAsset(res.data, data),
          }
        }
        throw errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
    },
    leverage: {
      setLeverage: async data => {
        if (BybitApiWrapper.checkers.leverage.setLeverage(data)) {
          const payload = BybitApiWrapper.preformatters.leverage.setLeverage(data)
          const res = await BybitApiWrapper.methods.leverage.setLeverage(payload)
          return {
            ...res,
            data: BybitApiWrapper.normalizers.leverage.setLeverage(res.data, payload),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      setMargin: async data => {
        if (BybitApiWrapper.checkers.leverage.setMargin(data)) {
          const res = await BybitApiWrapper.methods.leverage.setMargin(data)
          return {
            ...res,
            data: BybitApiWrapper.normalizers.leverage.setMargin(res.data, data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
    },
  },
}
