import {
  CancelAllOverviewOpenOrdersTC,
  CancelOverviewOpenOrderTC,
  CloseAllOverviewPositionsTC,
  CloseOverviewPositionTC,
  GetAllCointsTC,
  GetStatisticsSinceMomentTC,
  SellAsset,
} from './overview.thunks'

const overviewAsyncActions = {
  CancelOverviewOpenOrderTC,
  CancelAllOverviewOpenOrdersTC,
  CloseOverviewPositionTC,
  CloseAllOverviewPositionsTC,
  GetAllCointsTC,
  GetStatisticsSinceMomentTC,
  SellAsset,
}

export default overviewAsyncActions
