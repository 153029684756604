import { apiKeyReducer } from './apiKey/apiKey.slice'
import { appReducer } from './app/app.slice'
import { cashbackReducer } from './cashback/cashback.slice'
import { historyReducer } from './history/history.slice'
import { kycReducer } from './kyc/kyc.slice'
import { overviewReducer } from './overview/overview.slice'
import { transactionsReducer } from './transactions/transactions.slice'
import { leverageReducer } from './leverage/leverage.slice'
import { referralsReducer } from './referrals/referrals.slice'
import { profileReducer } from './profile/profile.slice'
import { voucherReducer } from './voucher/voucher.slice'
import { partnerReducer } from './partner/partner.slice'
import { teamReducer } from './team/team.slice'
import { addressReducer } from './address/address.slice'
import { tradesReducer } from './trades/trades.slice'
import { riskManagerReducer } from './riskManager/riskManager.slice'
import { dashboardReducer } from './dashboard/dashboard.slice'
import { okxAffiliateReducer } from './okxAffiliate/okxAffiliate.slice'

export const reducersList = {
  apiKeys: apiKeyReducer,
  overview: overviewReducer,
  app: appReducer,
  cashback: cashbackReducer,
  history: historyReducer,
  transactions: transactionsReducer,
  kyc: kycReducer,
  leverage: leverageReducer,
  referrals: referralsReducer,
  profile: profileReducer,
  voucher: voucherReducer,
  partner: partnerReducer,
  team: teamReducer,
  address: addressReducer,
  trades: tradesReducer,
  riskManager: riskManagerReducer,
  dashboard: dashboardReducer,
  okxAffiliate: okxAffiliateReducer,
}
