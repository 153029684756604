import { urls } from '@/router/urls'
import { InitialState } from './profile.types'
import { getDefaultTradesTablePreset, getUserLocale } from './profile.utils'

export const initialState: InitialState = {
  _metaProfileLocale: null,
  language: getUserLocale(),
  bnbBurnStatus: 'idle',
  spotBNBBurn: undefined,
  multiAssetsMarginStatus: 'idle',
  multiAssetsMargin: undefined,
  bnbDustStatus: 'idle',
  bnbDustEnabled: undefined,
  status: 'idle',
  initialStatus: 'idle',
  loginTwoFaToggleStatus: 'idle',
  verifiedWithTwoFaStatus: 'idle',
  google: {
    enabled: undefined,
  },
  binanceHedge: {
    BINANCE_COIN: undefined,
    BINANCE_FUTURE: undefined,
  },
  loginTwoFa: false,
  vipAccess: false,
  errors: [],
  metadata: {
    isFirstSessionTracked: false,
    messagesShownCashbackIncreased: false,
    messagesShownReducedCommission: false,
    isOrdersPageBalancesEnabled: false,
    redirectTo: urls.wallet,
    exchangeType: null,
    statisticsStartMoment: null,
    firstSesstionTrackedTimestamp: null,
    acceptedOferta: false,
    shownPopupList: [],
    tradesTablePreset: getDefaultTradesTablePreset(),
    isCreatedMasterApiKeyStatusSuccess: false,
    binanceVipConsent: [],
    isStatisticsInit: false,
    flags: {},
  },
}
