import { ApiKeysAPI, IRestRequestGeneric, TCreateApiKeyPayloadOkx } from '@/backend/api/apiKeyAPI'
import { AxiosResponse } from 'axios'
import { ApiKeyCreationDTO } from '@/backend/models/ApiKeyDTO'
import { IApiWrapper } from '../types'
import { ERROR_API_UNCOMPATIBLE_PAYLOAD } from '@/core/constants/errors/rest'
import { errorAsyncCreator, voidFunction } from '../utils'
import { LeverageApi, OverviewAPI } from '@/backend/api'
import {
  TCancelAllOpenOrdersPayload,
  TCancelAllOpenOrdersPayloadOkxDTO,
  TCancelAllOpenOrdersResponse,
  TCancelAllOpenOrdersResponseOkxDTO,
  TCancelOpenOrderPayloadUnion,
  TCancelOpenOrderPayloadOkxDTO,
  TCancelOpenOrderResponse,
  TCancelOpenOrderResponseOkxDTO,
  TCloseAllPositionsPayload,
  TCloseAllPositionsPayloadOkxDTO,
  TCloseAllPositionsResponse,
  TCloseAllPositionsResponseDTO,
  TClosePositionPayload,
  TClosePositionPayloadOkxDTO,
  TClosePositionResponse,
  TClosePositionResponseDTO,
  TCancelOpenOrderPayloadOkx,
} from '@/backend/models/OverviewDTO'
import { EOkxPositionSide } from '@/services/okx'
import { EPositionSide } from '@tigertrade/binance-ts'
import {
  TSetLeveragePayloadOkx,
  TSetLeveragePayloadUnion,
  TSetLeverageResponse,
  TSetLeverageResponseOkx,
} from '@/redux/leverage/leverage.types'
import {
  TSellAssetPayload,
  TSellAssetPayloadOKX,
  TSellAssetPayloadUnion,
  TSellAssetResponse,
} from '@/redux/overview/overview.types'

export const OkxApiWrapper: IApiWrapper = {
  methods: {
    apiKey: {
      createApiKey: ApiKeysAPI.createApiKey as IRestRequestGeneric<
        TCreateApiKeyPayloadOkx,
        AxiosResponse<ApiKeyCreationDTO>
      >,
    },
    overview: {
      cancelOpenOrder: OverviewAPI.cancelOpenOrder as IRestRequestGeneric<
        TCancelOpenOrderPayloadOkxDTO,
        AxiosResponse<TCancelOpenOrderResponseOkxDTO>
      >,
      cancelAllOpenOrders: OverviewAPI.cancelAllOpenOrders as IRestRequestGeneric<
        TCancelAllOpenOrdersPayloadOkxDTO,
        AxiosResponse<TCancelAllOpenOrdersResponseOkxDTO>
      >,
      closePosition: OverviewAPI.closePosition as IRestRequestGeneric<
        TClosePositionPayloadOkxDTO,
        AxiosResponse<TClosePositionResponseDTO>
      >,
      closeAllPositions: OverviewAPI.closeAllPositions as IRestRequestGeneric<
        TCloseAllPositionsPayloadOkxDTO,
        AxiosResponse<TCloseAllPositionsResponseDTO>
      >,
      setPositionMode: voidFunction,
      sellAsset: OverviewAPI.sellAsset as IRestRequestGeneric<
        TSellAssetPayloadUnion,
        AxiosResponse<TSellAssetResponse>
      >,
    },
    leverage: {
      setLeverage: LeverageApi.setLeverage as IRestRequestGeneric<
        TSetLeveragePayloadOkx,
        AxiosResponse<TSetLeverageResponseOkx>
      >,
      setMargin: async () => {}, // doesn't make sense for OKX, because it's possible to open both positions with cross and isolated margin
    },
  },
  preformatters: {
    apiKey: {
      createApiKey: (payload: TCreateApiKeyPayloadOkx) => payload,
    },
    overview: {
      cancelOpenOrder: (payload: TCancelOpenOrderPayloadOkx): TCancelOpenOrderPayloadOkxDTO => ({
        accountType: payload.accountType,
        instId: payload.symbol,
        ordId: payload.orderId,
        type: payload.orderSourceType,
      }),
      cancelAllOpenOrders: (payload: TCancelAllOpenOrdersPayload): TCancelAllOpenOrdersPayloadOkxDTO => ({
        accountType: payload.accountType,
        orders: payload.orders.map(order => ({
          instId: order.symbol,
          ordId: order.orderId,
          type: order.orderSourceType || 'SIMPLE',
        })),
      }),
      closePosition: (payload: TClosePositionPayload): TClosePositionPayloadOkxDTO => {
        return {
          accountType: payload.accountType,
          instId: payload.symbol,
          mgnMode: payload.marginType,
          ...(payload.positionSide !== EPositionSide.BOTH
            ? {
                posSide: payload.positionSide.toLowerCase() as EOkxPositionSide,
              }
            : {}),
          ccy: payload.ccy,
        }
      },
      closeAllPositions: (payload: TCloseAllPositionsPayload): TCloseAllPositionsPayloadOkxDTO => ({
        accountType: payload.accountType,
        positions: payload.positions.map(p => ({
          instId: p.symbol,
          mgnMode: p.marginType,
          ...(p.positionSide !== EPositionSide.BOTH
            ? {
                posSide: p.positionSide.toLowerCase() as EOkxPositionSide,
              }
            : {}),
          ccy: p.ccy,
        })),
      }),
      setPositionMode: voidFunction,
      sellAsset: (payload: TSellAssetPayload): TSellAssetPayloadOKX => {
        return {
          accountType: payload.accountType,
          sz: payload.quantity,
          instId: payload.symbol,
        }
      },
    },
    leverage: {
      setLeverage: (payload: TSetLeveragePayloadOkx): TSetLeveragePayloadOkx => {
        return payload
      },
      setMargin: async () => {},
    },
  },
  normalizers: {
    apiKey: {
      createApiKey: (source: ApiKeyCreationDTO): ApiKeyCreationDTO => {
        return {
          ...source,
        }
      },
    },
    overview: {
      cancelOpenOrder: (
        source: TCancelOpenOrderResponseOkxDTO,
        payload: TCancelOpenOrderPayloadUnion
      ): TCancelOpenOrderResponse => {
        return {
          accountType: source.accountType,
          instrumentType: payload.instrumentType,
          orderId: payload.orderId,
          success: source.sCode === '0',
          msg: source.sMsg,
        }
      },
      cancelAllOpenOrders: (
        source: TCancelAllOpenOrdersResponseOkxDTO,
        payload: TCancelOpenOrderPayloadUnion
      ): TCancelAllOpenOrdersResponse => {
        return {
          accountType: source.accountType,
          instrumentType: payload.instrumentType,
          success: source.data.every(({ sCode }) => sCode === '0'),
          errors: source.data.map(({ sMsg }) => sMsg),
        }
      },
      closePosition: (source: TClosePositionResponseDTO, payload: TClosePositionPayload): TClosePositionResponse => {
        return {
          accountType: source.accountType,
          instrumentType: payload.instrumentType,
          positionId: payload.positionId,
          success: source.code === '0',
          error: source.msg,
        }
      },
      closeAllPositions: (
        source: TCloseAllPositionsResponseDTO,
        payload: TCloseAllPositionsPayload
      ): TCloseAllPositionsResponse => {
        return {
          accountType: source.accountType,
          instrumentType: payload.instrumentType,
          success: source.code === '0',
          error: source.msg,
        }
      },
      setPositionMode: voidFunction,
      sellAsset: voidFunction,
    },
    leverage: {
      setLeverage: (source: TSetLeverageResponseOkx): TSetLeverageResponse => {
        return {
          accountType: source.accountType,
          leverage: Number(source.lever),
          symbol: source.instId,
          mgnMode: source.mgnMode,
          posSide: source.posSide,
        }
      },
      setMargin: async () => {},
    },
  },
  checkers: {
    apiKey: {
      createApiKey: (payload: any): payload is TCreateApiKeyPayloadOkx => {
        return 'passphrase' in payload
      },
    },
    overview: {
      cancelOpenOrder: (payload: TCancelOpenOrderPayloadOkx) => {
        return 'orderId' in payload && 'orderSourceType' in payload
      },
      cancelAllOpenOrders: (payload: TCancelAllOpenOrdersPayloadOkxDTO) => {
        return (
          'orders' in payload &&
          payload.orders.reduce((acc, order) => {
            if (acc === false) return false
            return 'orderSourceType' in order
          }, true)
        )
      },
      closePosition: (payload: TClosePositionPayloadOkxDTO) => {
        return 'instId' in payload
      },
      closeAllPositions: (payload: TCloseAllPositionsPayloadOkxDTO) => {
        return 'positions' in payload
      },
      setPositionMode: voidFunction,
      sellAsset: (payload: any): payload is TSellAssetPayloadOKX => {
        return 'sz' in payload
      },
    },
    leverage: {
      setLeverage: (payload: TSetLeveragePayloadUnion): payload is TSetLeveragePayloadOkx => {
        return 'mgnMode' in payload
      },
      setMargin: async () => {},
    },
  },
  caller: {
    apiKey: {
      createApiKey: async payload => {
        if (OkxApiWrapper.checkers.apiKey.createApiKey(payload)) {
          const res = await OkxApiWrapper.methods.apiKey.createApiKey(payload)
          return {
            ...res,
            data: OkxApiWrapper.normalizers.apiKey.createApiKey(res.data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
    },
    overview: {
      cancelOpenOrder: async data => {
        if (OkxApiWrapper.checkers.overview.cancelOpenOrder(data)) {
          const payload = OkxApiWrapper.preformatters.overview.cancelOpenOrder(data)

          const res = await OkxApiWrapper.methods.overview.cancelOpenOrder(payload)
          return {
            ...res,
            data: OkxApiWrapper.normalizers.overview.cancelOpenOrder(res.data, data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      cancelAllOpenOrders: async data => {
        if (OkxApiWrapper.checkers.overview.cancelAllOpenOrders(data)) {
          const payload = OkxApiWrapper.preformatters.overview.cancelAllOpenOrders(data)
          const res = await OkxApiWrapper.methods.overview.cancelAllOpenOrders(payload)
          return {
            ...res,
            data: OkxApiWrapper.normalizers.overview.cancelAllOpenOrders(res.data, data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      closePosition: async data => {
        const payload = OkxApiWrapper.preformatters.overview.closePosition(data)

        if (OkxApiWrapper.checkers.overview.closePosition(payload)) {
          const res = await OkxApiWrapper.methods.overview.closePosition(payload)
          return {
            ...res,
            data: OkxApiWrapper.normalizers.overview.closePosition(res.data, data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      closeAllPositions: async data => {
        const payload = OkxApiWrapper.preformatters.overview.closeAllPositions(data)

        if (OkxApiWrapper.checkers.overview.closeAllPositions(payload)) {
          const res = await OkxApiWrapper.methods.overview.closeAllPositions(payload)
          return {
            ...res,
            data: OkxApiWrapper.normalizers.overview.closeAllPositions(res.data, data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      setPositionMode: async (): Promise<any> => {},
      sellAsset: async data => {
        const payload = OkxApiWrapper.preformatters.overview.sellAsset(data)

        if (OkxApiWrapper.checkers.overview.sellAsset(payload)) {
          const res = await OkxApiWrapper.methods.overview.sellAsset(payload)
          return res
        }
        throw errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
    },
    leverage: {
      setLeverage: async data => {
        if (OkxApiWrapper.checkers.leverage.setLeverage(data)) {
          const payload = OkxApiWrapper.preformatters.leverage.setLeverage(data)
          const res = await OkxApiWrapper.methods.leverage.setLeverage(payload)
          return {
            ...res,
            data: OkxApiWrapper.normalizers.leverage.setLeverage(res.data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      setMargin: async (payload: any): Promise<any> => {},
    },
  },
}
