import { FC, useCallback, useState } from 'react'
import { t } from '@lingui/macro'
import { Dropdown } from '@/ui/kit'
import { EXCHANGE_NAME, EXCHANGE_ICON, TExchangesAvailable } from '@/core/constants'
import { DropDownItem } from '@/ui/kit/Dropdown/Dropdown'
import { ConsentPopup } from './ConcentPopup'
import { useActions, useAppSelector } from '@/utils'
import { profileActions } from '@/redux/profile/profile.slice'
import style from '../../style.module.scss'
import { useExchangesSource } from '@/utils/hooks/useExchangesSource'

export type TOptionExchange = DropDownItem<TExchangesAvailable>

export const ExchangeTypeSettings: FC = () => {
  const { UpdateMetadata } = useActions(profileActions)

  const {
    metadata: { exchangeType },
  } = useAppSelector(state => state.profile)

  const options = useExchangesSource(false)

  const [selectedOption, setSelectedOption] = useState<TOptionExchange | null>(
    options.find(option => {
      return option.value === exchangeType
    }) || null
  )

  const [modalWith, setModalWith] = useState<TOptionExchange | undefined>()

  const ExchangeIcon = exchangeType ? EXCHANGE_ICON[exchangeType] : null

  const setSelectedCallback = useCallback(
    (value: TOptionExchange) => {
      if (value.value === exchangeType) return
      setModalWith(value)
    },
    [exchangeType]
  )

  const handleAgree = useCallback(() => {
    if (!modalWith) return

    setSelectedOption(modalWith)

    const response = UpdateMetadata({
      exchangeType: modalWith.value,
    })

    if ('error' in response) {
      if (!exchangeType) return

      setSelectedOption({
        label: EXCHANGE_NAME[exchangeType],
        value: exchangeType,
      })
    }

    setModalWith(undefined)
  }, [modalWith, exchangeType])

  const handleCancel = useCallback(() => {
    setModalWith(undefined)
  }, [])

  return (
    <section className={style.section}>
      <ConsentPopup open={!!modalWith} exchange={modalWith} onAgree={handleAgree} onCancel={handleCancel} />

      <h6 className={style.sectionHeading}>{t({ id: 'profile.exchangeType.heading', message: `Exchange` })}</h6>

      <div className={style.setting}>
        {!!ExchangeIcon && <ExchangeIcon />}
        <div>
          <div className={style.settingTitle}>
            {t({ id: 'profile.exchangeType.titleDefault', message: `Set default Exchange` })}
          </div>
          <div className={style.settingStatus}>
            {t({
              id: 'profile.exchangeType.descriptionDefault',
              message: 'Save your default exchange',
            })}
          </div>
        </div>
        <div className={style.settingActions}>
          <Dropdown
            options={options}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedCallback}
            className={style.exchangeSelect}
          />
        </div>
      </div>
    </section>
  )
}
