import { User } from './User'
import { Logout } from './Logout'
import { Language } from './Language'
import { Brilliant } from './Brilliant'
import { BackArrow } from './BackArrow'
import { Voucher } from './Voucher'
import { Home } from './Home'
import { Team } from './Team'
import { AdditionalInfo } from './AdditionalInfo'
import { Trades } from './Trades'

export const Profile = {
  User,
  Logout,
  Language,
  Brilliant,
  BackArrow,
  Voucher,
  Home,
  Team,
  AdditionalInfo,
  Trades,
}
