import { useAuth0 } from '@auth0/auth0-react'
import { t } from '@lingui/macro'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { DataProvider } from '@/providers/DataProvider'
import { ReferralProvider } from '@/providers/DataProvider/ReferralProvider'
import { ExchangeProvider } from '@/providers/ExchangeProvider'
import { SetupProvider } from '@/providers/SetupProvider'
import { overviewActions } from '@/redux/overview/overview.slice'
import { profileActions } from '@/redux/profile/profile.slice'
import { PUBLIC_ROUTES } from '@/router/urls'
import { setupAxiosMainService } from '@/services/axios'
import { ErrorBase } from '@/ui/kit/Errors/ErrorBase'
import { Loader } from '@/ui/molecules/Loader'
import { GoogleAuthenticatorForm } from '@/ui/organisms/GoogleAuthenticatorForm'
import { useActions, useAppSelector } from '@/utils'
import { useLogout } from '@/utils/hooks/useLogout'
import { storage } from '@/utils/lib/storage'
import { useLingui } from '@lingui/react'
import { TExchangesAvailable, TLanguage } from '@/core/constants'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { INCOME_DEFAULT } from '@/redux/overview/overview.defaults'
import { setupOKXAxiosService } from '@/services/axios/utils/setAuthOKXAxiosService'
import * as amplitude from '@amplitude/analytics-browser'
import { E_AmplitudeEventName } from '@/redux/profile/profile.types'

export interface IAuthHandlerProviderProps {
  children: React.ReactNode
}

export const AuthHandlerProvider: React.FC<IAuthHandlerProviderProps> = props => {
  const { pathname } = useLocation()
  const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently, error, user } = useAuth0()
  const { GetProfileTC, setEmail, setTigerTradeId, UpdateMetadata } = useActions(profileActions)
  const { setIncomeStatistics } = useActions(overviewActions)
  const {
    initialStatus: profileStatus,
    metadata,
    loginTwoFa,
    verifiedWithTwoFaStatus,
    errors,
    _metaProfileLocale,
  } = useAppSelector(state => state.profile)
  const logoutWrapper = useLogout()
  const [isSetup, setSetup] = useState(false)
  const { i18n } = useLingui()

  const { exchangeType } = useExchageType()

  const isPublic = useMemo(() => PUBLIC_ROUTES.some(route => pathname.startsWith(route)), [pathname])

  const { setErrors, VerifyTwoFA, UpdateLocale } = useActions(profileActions)
  const { statisticsStartMoment, isFirstSessionTracked } = metadata

  const handleVerifyTwoFa = async (value: string) => {
    VerifyTwoFA({ sms: '', google: value })
  }

  useEffect(() => {
    if (isLoading) return
    if (isAuthenticated) return
    if (!isPublic) return
    setSetup(true)
  }, [isLoading, isAuthenticated, isPublic])

  useEffect(() => {
    if (isLoading) return
    if (isPublic) return
    if (isAuthenticated) return
    loginWithRedirect() // @see https://tigertrade.atlassian.net/browse/TTS-1581
  }, [isLoading, isPublic, isAuthenticated, loginWithRedirect])

  useEffect(() => {
    if (isSetup) return
    if (isLoading) return
    if (!isAuthenticated) return
    loadToken()
  }, [isAuthenticated, isSetup, isLoading])

  useEffect(() => {
    if (user?.email) {
      setEmail(user.email)
    }
  }, [user])

  const loadToken = async () => {
    getAccessTokenSilently()
      .then(token => {
        try {
          const tokenData = JSON.parse(atob(token?.split('.')?.[1]))
          // TODO: move namespace to .env
          setTigerTradeId(tokenData?.['https://tiger.trade/internalId'])
        } catch (e) {}

        setupAxiosMainService({ accessToken: token })
        setupOKXAxiosService({ accessToken: token })
      })

      .catch(e => {
        if (e.error === 'login_required') {
          loginWithRedirect()
        } else if (e.error === 'consent_required') {
          loginWithRedirect()
        } else {
          logoutWrapper()
        }
      })
      .then(() => GetProfileTC())
      .then(() => setSetup(true))
  }

  /**
   * We check whether the locale has arrived
   * if not, then add it manually
   */
  useEffect(() => {
    if (profileStatus === 'succeeded' && !_metaProfileLocale) {
      UpdateLocale({ locale: i18n.locale as TLanguage })
    }
  }, [profileStatus, _metaProfileLocale])

  useEffect(() => {
    if (profileStatus === 'succeeded' && statisticsStartMoment) {
      setIncomeStatistics([
        exchangeType as TExchangesAvailable,
        'BINANCE_FUTURE',
        { ...INCOME_DEFAULT, startMoment: statisticsStartMoment },
      ])
    }
  }, [profileStatus, statisticsStartMoment])

  useEffect(() => {
    if (profileStatus === 'succeeded' && !isFirstSessionTracked) {
      UpdateMetadata({
        isFirstSessionTracked: 'true',
        firstSesstionTrackedTimestamp: new Date().getTime().toString(),
      })
    }
  }, [profileStatus, isFirstSessionTracked])

  useEffect(() => {
    if (!isFirstSessionTracked) {
      amplitude.track(E_AmplitudeEventName.VisitAccountTigerCom)
    }
  }, [isFirstSessionTracked])

  const errorBaseProps = useMemo(() => {
    return {
      customHeader: t({ message: `Couldn’t load data`, id: 'core.notLoadData' }),
      isRefreshButtonVisible: true,
      isSupportMessage: true,
    }
  }, [])

  if (!storage.getVerifiedWithTwoFa() && loginTwoFa) {
    return (
      <>
        <Loader.Overlay>
          <GoogleAuthenticatorForm
            onCancel={logoutWrapper}
            onSubmit={handleVerifyTwoFa}
            errors={errors}
            setErrors={setErrors}
            isSubmitDisabled={verifiedWithTwoFaStatus === 'loading'}
          />
        </Loader.Overlay>
      </>
    )
  }

  if (profileStatus === 'failed')
    return (
      <Loader.Overlay>
        <ErrorBase {...errorBaseProps} />
      </Loader.Overlay>
    )

  if (isLoading || !isSetup || (isAuthenticated && profileStatus !== 'succeeded')) {
    return (
      <Loader.Overlay>
        <Loader.Content />
      </Loader.Overlay>
    )
  }

  if (error) {
    return (
      <Loader.Overlay>
        <ErrorBase
          customHeader={t({ message: `Authentication error`, id: 'core.authError' })}
          customText={JSON.stringify(error)}
          isRefreshButtonVisible
          isSupportMessage
        />
      </Loader.Overlay>
    )
  }

  if (isPublic && !isAuthenticated) {
    return <>{props.children}</>
  }

  return (
    <SetupProvider>
      <ExchangeProvider>
        <DataProvider>
          <ReferralProvider>{props.children}</ReferralProvider>
        </DataProvider>
      </ExchangeProvider>
    </SetupProvider>
  )
}
