interface HourglassProps {
  className?: string
}

export const HourglassAccent = (props: HourglassProps) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <rect width="40" height="40" rx="15" fill="url(#paint0_linear_21492_22536)" />
    <path
      d="M12.4717 13.0833C12.4717 11.2424 13.9641 9.75 15.805 9.75H24.6936C26.5346 9.75 28.0271 11.2426 28.0269 13.0837L28.0269 13.5219C28.0268 14.6363 27.4698 15.6768 26.5426 16.295L22.7472 18.8255C22.0875 19.2653 22.0875 20.2347 22.7472 20.6745L26.5426 23.205C27.4698 23.8232 28.0268 24.8637 28.0269 25.9781L28.0269 26.4163C28.0271 28.2574 26.5347 29.75 24.6936 29.75H15.805C13.9641 29.75 12.4717 28.2576 12.4717 26.4167V25.9784C12.4717 24.8639 13.0287 23.8231 13.956 23.2049L17.7516 20.6745C18.4113 20.2347 18.4113 19.2653 17.7516 18.8255L13.956 16.2951C13.0287 15.6769 12.4717 14.6361 12.4717 13.5216V13.0833Z"
      stroke="#FAFAFA"
      stroke-width="1.5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.292 22.9857C19.8419 22.6191 20.6559 22.619 21.2058 22.9857L25.0013 25.5162C25.1558 25.6192 25.2486 25.7926 25.2487 25.9784L25.2487 26.4166C25.2487 26.7235 25 26.9722 24.6931 26.9722H15.8046C15.4978 26.9722 15.249 26.7235 15.249 26.4167V25.9784C15.249 25.7926 15.3419 25.6192 15.4964 25.5161L19.292 22.9857Z"
      fill="#FAFAFA"
    />
    <defs>
      <linearGradient
        id="paint0_linear_21492_22536"
        x1="0"
        y1="0"
        x2="41.3635"
        y2="1.46347"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F37E9A" />
        <stop offset="1" stop-color="#FF8933" />
      </linearGradient>
    </defs>
  </svg>
)
