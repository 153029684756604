import {
  ServicesMembership,
  ServicesWidgetSource,
  ServicesWidgetType,
  ServicesWidgetType2,
} from '@/utils/types/contracts.types'
import {
  exampleDataDatetimeSimple,
  exampleDataPieSymbol,
  exampleDataNegativeOverDuration,
  exampleDataPositiveOverDuration,
  exampleDataStdDevMAEMMFE,
  exampleDataDatetimeSimpleNegative,
  exampleDataDatetimeSimpleCumulative,
  exampleDataCandleStick,
  tagProfit,
  apiKeyProfit,
  categoryProfit,
  tagProfitCombinations,
  profitByDayOfWeek,
  profitByTimeOfDay,
} from './exampleData'

export enum ServicesWidgetValueType {
  money = 'money',
  number = 'number',
  percent = 'percent',
  duration = 'duration',
}

export enum WidgetCategory {
  income_and_profit = 'income_and_profit',
  math_and_statistics = 'math_and_statistics',
  risk_management = 'risk_management',
  volume_and_turnover = 'volume_and_turnover',
  commissions_and_fees = 'commissions_and_fees',
  balance_and_portfolio = 'balance_and_portfolio',
  trade_metrics = 'trade_metrics',
  time_based_analysis = 'time_based_analysis',
  tagging_and_categories = 'tagging_and_categories',
  custom_and_derived_metrics = 'custom_and_derived_metrics',
}

export enum WidgetTag {
  new = 'new',
  popular = 'popular',
  complex = 'complex',
  simple = 'simple',
  premium = 'premium',
  gem = 'gem',
}

export type WidgetMultiRowServerData = {
  [key: string]: WidgetRowServerData
}

export type WidgetRowServerData = {
  name?: string
  trade_id?: number
  counter?: number
  percent?: string
  dateValue?: string
  value?: string
  value2?: string
  value3?: string
}

export type WidgetSettingType = {
  valueAppend?: string
  valuePrepend?: string
  type: ServicesWidgetValueType
  grouped?: boolean
  xAxis?: string
  xAxisType?: 'category' | 'datetime' | 'numeric'
  multiSeriesName?: string
  disableDefaultSorting?: boolean
  series?: {
    name: string
    valueField: keyof WidgetRowServerData
  }[]
  monochrome?: boolean
  source?: string
  language?: boolean
  total_name?: string
  totalAppend?: string
  totalPrepend?: string
  gauge?: boolean
  inverted?: boolean
  hideYAxis?: boolean
  stacked?: boolean
  vertical?: boolean
  hideCounter?: boolean
  maxDecimals?: number
  type2?: ServicesWidgetType2[]
  visualType: ServicesWidgetType[]
  minMembership?: ServicesMembership
  categories: WidgetCategory[]
  tags?: WidgetTag[]
  hidden?: boolean
  exampleData?: any
  usage?: number
}

export type WidgetSettingsListType = {
  [key in ServicesWidgetSource]: WidgetSettingType
}

export const WidgetSettingsList: WidgetSettingsListType = {
  income_usdt: {
    type: ServicesWidgetValueType.money,
    visualType: [ServicesWidgetType.WidgetTypeChart, ServicesWidgetType.WidgetTypeNumber],
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    categories: [WidgetCategory.income_and_profit],
    exampleData: exampleDataDatetimeSimple,
    usage: 100,
    tags: [WidgetTag.popular, WidgetTag.simple],
  },
  pie_symbol_income_profit: {
    xAxis: 'name',
    type: ServicesWidgetValueType.money,
    type2: [
      ServicesWidgetType2.WidgetType2Pie,
      ServicesWidgetType2.WidgetType2TreeMap,
      ServicesWidgetType2.WidgetType2Bubble,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.income_and_profit],
    exampleData: exampleDataPieSymbol,
    usage: 14.8,
  },
  pie_symbol_income_loose: {
    xAxis: 'name',
    type: ServicesWidgetValueType.money,
    inverted: true,
    type2: [
      ServicesWidgetType2.WidgetType2Pie,
      ServicesWidgetType2.WidgetType2TreeMap,
      ServicesWidgetType2.WidgetType2Bubble,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.income_and_profit, WidgetCategory.risk_management],
    exampleData: exampleDataPieSymbol,
    usage: 18.6,
  },
  income_usdt_candlestick: {
    type: ServicesWidgetValueType.money,
    type2: [ServicesWidgetType2.WidgetType2CandleStick],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.income_and_profit, WidgetCategory.custom_and_derived_metrics],
    exampleData: exampleDataCandleStick,
    usage: 7.6,
    tags: [WidgetTag.gem],
  },
  income_usdt_anonymous_accumulative: {
    type: ServicesWidgetValueType.number,
    hideYAxis: true,
    hidden: true,
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.income_and_profit],
    usage: 6,
  },
  symbol_income: {
    xAxis: 'name',
    type: ServicesWidgetValueType.money,
    type2: [ServicesWidgetType2.WidgetType2Column, ServicesWidgetType2.WidgetType2TreeMap],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.income_and_profit],
    exampleData: exampleDataPieSymbol,
    usage: 12.6,
    tags: [WidgetTag.simple],
  },
  math_expectation: {
    visualType: [ServicesWidgetType.WidgetTypeNumber, ServicesWidgetType.WidgetTypeChart],
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    type: ServicesWidgetValueType.money,
    categories: [WidgetCategory.math_and_statistics, WidgetCategory.income_and_profit],
    exampleData: exampleDataDatetimeSimple,
    usage: 29.8,
    tags: [WidgetTag.popular],
  },
  pay_off_ratio: {
    type: ServicesWidgetValueType.number,
    visualType: [ServicesWidgetType.WidgetTypeNumber, ServicesWidgetType.WidgetTypeChart],
    type2: [
      ServicesWidgetType2.WidgetType2Column,
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
    ],
    categories: [WidgetCategory.math_and_statistics],
    exampleData: exampleDataDatetimeSimple,
    usage: 21.4,
  },
  recovery_factor: {
    type: ServicesWidgetValueType.number,
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.math_and_statistics, WidgetCategory.risk_management],
    minMembership: ServicesMembership.LEVEL_NOVICE_PLUS,
    usage: 7.4,
  },
  average_loss_deviation_percent: {
    type: ServicesWidgetValueType.percent,
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.math_and_statistics, WidgetCategory.risk_management],
    exampleData: exampleDataDatetimeSimple,
    usage: 2.6,
    tags: [WidgetTag.complex],
  },
  pie_symbol: {
    xAxis: 'name',
    hideCounter: true,
    type: ServicesWidgetValueType.number,
    monochrome: true,
    type2: [ServicesWidgetType2.WidgetType2Pie, ServicesWidgetType2.WidgetType2TreeMap],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.trade_metrics],
    exampleData: exampleDataPieSymbol,
    usage: 12.6,
  },
  std_dev_mae_over_duration: {
    xAxis: 'name',
    disableDefaultSorting: true,
    inverted: true,
    type: ServicesWidgetValueType.percent,
    type2: [ServicesWidgetType2.WidgetType2Column],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.risk_management],
    minMembership: ServicesMembership.LEVEL_TRADER,
    exampleData: exampleDataNegativeOverDuration,
    usage: 4.4,
    tags: [WidgetTag.complex, WidgetTag.premium, WidgetTag.new],
  },
  std_dev_mfe_over_duration: {
    xAxis: 'name',
    disableDefaultSorting: true,
    type: ServicesWidgetValueType.percent,
    type2: [ServicesWidgetType2.WidgetType2Column],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.risk_management],
    minMembership: ServicesMembership.LEVEL_TRADER,
    exampleData: exampleDataPositiveOverDuration,
    usage: 4.4,
    tags: [WidgetTag.complex, WidgetTag.premium, WidgetTag.new],
  },
  std_dev_profit_over_volume: {
    xAxis: 'name',
    disableDefaultSorting: true,
    type: ServicesWidgetValueType.money,
    type2: [ServicesWidgetType2.WidgetType2Column],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.income_and_profit],
    minMembership: ServicesMembership.LEVEL_NOVICE_PLUS,
    tags: [WidgetTag.complex, WidgetTag.premium, WidgetTag.new],
    exampleData: {
      '0': {
        name: '10 / 50',
        counter: 135,
        value: '12.49',
        value2: '0',
        value3: '0',
        percent: '0',
      },
      '1': {
        name: '51 / 150',
        counter: 24,
        value: '28.5',
        value2: '0',
        value3: '0',
        percent: '0',
      },
      '2': {
        name: '160 / 380',
        counter: 4,
        value: '123.8',
        value2: '0',
        value3: '0',
        percent: '0',
      },
    },
    usage: 3,
  },
  std_dev_profit_over_duration: {
    xAxis: 'name',
    disableDefaultSorting: true,
    type: ServicesWidgetValueType.money,
    type2: [ServicesWidgetType2.WidgetType2Column],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.income_and_profit, WidgetCategory.time_based_analysis],
    minMembership: ServicesMembership.LEVEL_NOVICE_PLUS,
    exampleData: exampleDataPositiveOverDuration,
    usage: 4.4,
    tags: [WidgetTag.complex, WidgetTag.premium, WidgetTag.new],
  },
  std_dev_mae: {
    xAxis: 'name',
    disableDefaultSorting: true,
    type: ServicesWidgetValueType.number,
    type2: [ServicesWidgetType2.WidgetType2Column],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.risk_management],
    hideCounter: true,
    maxDecimals: 0,
    minMembership: ServicesMembership.LEVEL_TRADER,
    exampleData: exampleDataStdDevMAEMMFE,
    usage: 1.4,
    tags: [WidgetTag.complex, WidgetTag.premium, WidgetTag.new],
  },
  std_dev_mfe: {
    xAxis: 'name',
    disableDefaultSorting: true,
    type: ServicesWidgetValueType.number,
    type2: [ServicesWidgetType2.WidgetType2Column],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.risk_management],
    hideCounter: true,
    maxDecimals: 0,
    minMembership: ServicesMembership.LEVEL_TRADER,
    exampleData: exampleDataStdDevMAEMMFE,
    usage: 1.2,
    tags: [WidgetTag.complex, WidgetTag.premium, WidgetTag.new],
  },
  std_dev_profit: {
    xAxis: 'name',
    disableDefaultSorting: true,
    type: ServicesWidgetValueType.number,
    type2: [ServicesWidgetType2.WidgetType2Column],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.trade_metrics, WidgetCategory.income_and_profit],
    hideCounter: true,
    maxDecimals: 0,
    minMembership: ServicesMembership.LEVEL_NOVICE_PLUS,
    exampleData: exampleDataStdDevMAEMMFE,
    usage: 1.2,
    tags: [WidgetTag.new, WidgetTag.premium],
  },
  pie_side: {
    xAxis: 'name',
    type: ServicesWidgetValueType.number,
    type2: [ServicesWidgetType2.WidgetType2Pie],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.trade_metrics],
    exampleData: {
      '0': {
        name: 'LONG',
        counter: 135,
        value: '135',
      },
      '1': {
        name: 'SHORT',
        counter: 24,
        value: '24',
      },
    },
    usage: 14.8,
    tags: [WidgetTag.simple],
  },
  income_usdt_stacked: {
    stacked: true,
    series: [
      {
        name: 'profit',
        valueField: 'value',
      },
      {
        name: 'commission',
        valueField: 'value2',
      },
    ],
    type: ServicesWidgetValueType.money,
    visualType: [ServicesWidgetType.WidgetTypeChart],
    type2: [
      ServicesWidgetType2.WidgetType2Column,
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
    ],
    categories: [WidgetCategory.income_and_profit, WidgetCategory.commissions_and_fees],
    exampleData: exampleDataDatetimeSimple,
    usage: 2.0,
    tags: [WidgetTag.new],
  },
  average_return: {
    type: ServicesWidgetValueType.percent,
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart, ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.balance_and_portfolio],
    exampleData: exampleDataDatetimeSimple,
    usage: 1.4,
  },
  volume: {
    type: ServicesWidgetValueType.money,
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart, ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.volume_and_turnover],
    exampleData: exampleDataDatetimeSimple,
    usage: 1.4,
    tags: [WidgetTag.simple],
  },
  volume_per_symbol: {
    type: ServicesWidgetValueType.money,
    type2: [
      ServicesWidgetType2.WidgetType2Pie,
      ServicesWidgetType2.WidgetType2Column,
      ServicesWidgetType2.WidgetType2Bubble,
    ],
    xAxis: 'name',
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.volume_and_turnover],
    exampleData: exampleDataPieSymbol,
    usage: 12.6,
  },
  avg_volume: {
    type: ServicesWidgetValueType.money,
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart, ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.volume_and_turnover],
    exampleData: exampleDataDatetimeSimple,
    usage: 1.4,
  },
  avg_volume_per_symbol: {
    type: ServicesWidgetValueType.money,
    type2: [
      ServicesWidgetType2.WidgetType2Pie,
      ServicesWidgetType2.WidgetType2Column,
      ServicesWidgetType2.WidgetType2Bubble,
    ],
    xAxis: 'name',
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.volume_and_turnover],
    exampleData: exampleDataPieSymbol,
    usage: 12.6,
  },
  funding: {
    type: ServicesWidgetValueType.money,
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart, ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.custom_and_derived_metrics],
    exampleData: exampleDataDatetimeSimple,
    usage: 15.4,
  },
  balance: {
    type: ServicesWidgetValueType.money,
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart, ServicesWidgetType.WidgetTypeNumber],
    hideCounter: true,
    categories: [WidgetCategory.balance_and_portfolio],
    minMembership: ServicesMembership.LEVEL_NOVICE_PLUS,
    exampleData: exampleDataDatetimeSimple,
    usage: 31.2,
    tags: [WidgetTag.simple, WidgetTag.simple],
  },
  max_drawdown: {
    type: ServicesWidgetValueType.percent,
    type2: [
      ServicesWidgetType2.WidgetType2Column,
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.balance_and_portfolio, WidgetCategory.risk_management],
    hideCounter: true,
    minMembership: ServicesMembership.LEVEL_NOVICE_PLUS,
    exampleData: exampleDataDatetimeSimpleNegative,
    usage: 8.6,
    tags: [WidgetTag.premium],
  },
  commission_usdt: {
    type: ServicesWidgetValueType.money,
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart, ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.commissions_and_fees],
    exampleData: exampleDataDatetimeSimple,
    usage: 42.8,
    tags: [WidgetTag.simple, WidgetTag.popular],
  },
  income_usdt_stacked_by_api_keys: {
    type: ServicesWidgetValueType.money,
    grouped: true,
    stacked: true,
    type2: [
      ServicesWidgetType2.WidgetType2Column,
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.income_and_profit],
    exampleData: {
      '2024-06-06': {
        bybit: {
          name: 'bybit',
          counter: 6,
          value: '112',
          dateValue: '2024-06-06',
        },
        binance: {
          name: 'binance',
          counter: 10,
          value: '40',
          dateValue: '2024-06-06',
        },
      },
      '2024-06-07': {
        bybit: {
          name: 'bybit',
          counter: 8,
          value: '54',
          dateValue: '2024-06-07',
        },
        binance: {
          name: 'binance',
          counter: 14,
          value: '35',
          dateValue: '2024-06-07',
        },
      },
      '2024-06-08': {
        bybit: {
          name: 'bybit',
          counter: 12,
          value: '189',
          dateValue: '2024-06-07',
        },
        binance: {
          name: 'binance',
          counter: 10,
          value: '68',
          dateValue: '2024-06-07',
        },
      },
    },
    usage: 4.2,
    tags: [WidgetTag.new],
  },
  income_usdt_accumulative_by_api_key: {
    type: ServicesWidgetValueType.money,
    grouped: true,
    stacked: true,
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      // ServicesWidgetType2.WidgetType2LineStep,
      // ServicesWidgetType2.WidgetType2Column,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.income_and_profit],
    exampleData: {
      '2024-06-07': {
        bybit: {
          name: 'bybit',
          counter: 8,
          value: '154',
          dateValue: '2024-06-07',
        },
        binance: {
          name: 'binance',
          counter: 14,
          value: '95',
          dateValue: '2024-06-07',
        },
      },
      '2024-06-08': {
        bybit: {
          name: 'bybit',
          counter: 12,
          value: '189',
          dateValue: '2024-06-08',
        },
        binance: {
          name: 'binance',
          counter: 10,
          value: '128',
          dateValue: '2024-06-08',
        },
      },
      '2024-06-09': {
        bybit: {
          name: 'bybit',
          counter: 12,
          value: '289',
          dateValue: '2024-06-09',
        },
        binance: {
          name: 'binance',
          counter: 10,
          value: '128',
          dateValue: '2024-06-09',
        },
      },
    },
    usage: 1,
    tags: [WidgetTag.new],
  },
  commission_usdt_accumulative: {
    type: ServicesWidgetValueType.money,
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.commissions_and_fees],
    exampleData: exampleDataDatetimeSimpleCumulative,
    usage: 48.6,
    tags: [WidgetTag.popular, WidgetTag.simple],
  },
  volume_usdt_accumulative: {
    type: ServicesWidgetValueType.money,
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.volume_and_turnover],
    exampleData: exampleDataDatetimeSimpleCumulative,
    usage: 3.2,
    tags: [WidgetTag.simple],
  },
  income_usdt_accumulative: {
    type: ServicesWidgetValueType.money,
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.income_and_profit],
    exampleData: exampleDataDatetimeSimpleCumulative,
    usage: 100,
    tags: [WidgetTag.popular, WidgetTag.simple],
  },
  trading_session_math_expectation: {
    type: ServicesWidgetValueType.money,
    type2: [ServicesWidgetType2.WidgetType2Column],
    // xAxis: 'name',
    xAxisType: 'category',
    stacked: false,
    series: [
      {
        name: 'session-none',
        valueField: 'value',
      },
      {
        name: 'session-asia',
        valueField: 'value',
      },
      {
        name: 'session-asia-europe',
        valueField: 'value',
      },
      {
        name: 'session-europe',
        valueField: 'value',
      },
      {
        name: 'session-europe-usa',
        valueField: 'value',
      },
      {
        name: 'session-usa',
        valueField: 'value',
      },
    ],
    minMembership: ServicesMembership.LEVEL_NOVICE_PLUS,
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.math_and_statistics],
    exampleData: {
      '2024-03-01': {
        'session-asia': {
          name: 'session-asia',
          counter: 30,
          value: '120',
          dateValue: '2024-03-01',
        },
        'session-europe': {
          name: 'session-europe',
          counter: 16,
          value: '34',
          dateValue: '2024-03-01',
        },
        'session-europe-usa': {
          name: 'session-europe-usa',
          counter: 37,
          value: '342',
          dateValue: '2024-03-01',
        },
        'session-none': {
          name: 'session-none',
          counter: 12,
          value: '45',
          dateValue: '2024-03-01',
        },
        'session-usa': {
          name: 'session-usa',
          counter: 34,
          value: '234',
          dateValue: '2024-03-01',
        },
      },
      '2024-04-01': {
        'session-asia': {
          name: 'session-asia',
          counter: 21,
          value: '503',
          dateValue: '2024-04-01',
        },
        'session-europe': {
          name: 'session-europe',
          counter: 11,
          value: '-36',
          dateValue: '2024-04-01',
        },
        'session-europe-usa': {
          name: 'session-europe-usa',
          counter: 66,
          value: '203',
          dateValue: '2024-04-01',
        },
        'session-none': {
          name: 'session-none',
          counter: 200,
          value: '66',
          dateValue: '2024-04-01',
        },
        'session-usa': {
          name: 'session-usa',
          counter: 192,
          value: '-71',
          dateValue: '2024-04-01',
        },
      },
    },
    usage: 15.4,
    tags: [WidgetTag.gem, WidgetTag.premium],
  },
  risk_values_usd: {
    type: ServicesWidgetValueType.money,
    type2: [ServicesWidgetType2.WidgetType2Column],
    stacked: false,
    vertical: true,
    hideCounter: true,
    xAxis: 'name',
    series: [
      {
        name: 'allowable_loss',
        valueField: 'value2',
      },
      {
        name: 'stop_trading_loss',
        valueField: 'value',
      },
      {
        name: 'over_trading',
        valueField: 'value3',
      },
      {
        name: 'profit_if_stop_trading',
        valueField: 'percent',
      },
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.risk_management],
    exampleData: {
      '50%': {
        name: '50%',
        counter: 717,
        value: '-51.292172545',
        value2: '-123',
        value3: '54',
        percent: '670',
      },
      '60%': {
        name: '60%',
        counter: 717,
        value: '-33.85589913',
        value2: '-98',
        value3: '89',
        percent: '774',
      },
      '70%': {
        name: '70%',
        counter: 717,
        value: '-23.065592185',
        value2: '-54',
        value3: '120',
        percent: '836',
      },
      '80%': {
        name: '80%',
        counter: 717,
        value: '-19.66650443',
        value2: '-36',
        value3: '187',
        percent: '543',
      },
      '90%': {
        name: '90%',
        counter: 717,
        value: '-7.120405715',
        value2: '-18',
        value3: '230',
        percent: '551',
      },
    },
    usage: 2,
    tags: [WidgetTag.gem, WidgetTag.complex],
  },
  ticker_price: {
    type: ServicesWidgetValueType.money,
    type2: [
      ServicesWidgetType2.WidgetType2CandleStick,
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.custom_and_derived_metrics],
    exampleData: exampleDataCandleStick,
    usage: 2,
    tags: [WidgetTag.simple],
  },
  tag_profit: {
    type: ServicesWidgetValueType.money,
    language: true,
    xAxis: 'tag_id',
    source: 'tags',
    type2: [ServicesWidgetType2.WidgetType2Column, ServicesWidgetType2.WidgetType2Bubble],
    categories: [WidgetCategory.income_and_profit, WidgetCategory.tagging_and_categories],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    exampleData: tagProfit,
    usage: 56,
    tags: [WidgetTag.popular],
  },
  income_usdt_api_keys: {
    type: ServicesWidgetValueType.money,
    xAxis: 'name',
    type2: [ServicesWidgetType2.WidgetType2Column, ServicesWidgetType2.WidgetType2TreeMap],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.income_and_profit],
    exampleData: apiKeyProfit,
    usage: 16,
  },
  counter_api_keys: {
    type: ServicesWidgetValueType.number,
    xAxis: 'name',
    hideCounter: true,
    monochrome: true,
    type2: [ServicesWidgetType2.WidgetType2Column, ServicesWidgetType2.WidgetType2TreeMap],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.trade_metrics],
    exampleData: apiKeyProfit,
    usage: 11,
    tags: [WidgetTag.simple],
  },
  balance_per_api_key: {
    type: ServicesWidgetValueType.money,
    xAxis: 'name',
    hideCounter: true,

    monochrome: true,
    type2: [ServicesWidgetType2.WidgetType2Column, ServicesWidgetType2.WidgetType2TreeMap],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.balance_and_portfolio],
    minMembership: ServicesMembership.LEVEL_NOVICE_PLUS,
    exampleData: apiKeyProfit,
    usage: 30,
    tags: [WidgetTag.simple, WidgetTag.popular],
  },
  category_profit: {
    type: ServicesWidgetValueType.money,
    language: true,
    xAxis: 'category_id',
    source: 'category',
    type2: [ServicesWidgetType2.WidgetType2Column, ServicesWidgetType2.WidgetType2TreeMap],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.income_and_profit, WidgetCategory.tagging_and_categories],
    exampleData: categoryProfit,
    usage: 7,
    tags: [WidgetTag.popular],
  },
  category_win_percentage: {
    type: ServicesWidgetValueType.percent,
    language: true,
    xAxis: 'category_id',
    source: 'category',
    type2: [ServicesWidgetType2.WidgetType2Column],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.math_and_statistics, WidgetCategory.tagging_and_categories],
    exampleData: categoryProfit,
    usage: 5.4,
  },
  tag_percent: {
    type: ServicesWidgetValueType.percent,
    language: true,
    xAxis: 'tag_id',
    source: 'tags',
    type2: [ServicesWidgetType2.WidgetType2Column],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.math_and_statistics, WidgetCategory.tagging_and_categories],
    exampleData: tagProfit,
    usage: 20,
  },
  tag_win_rate_combinations: {
    type: ServicesWidgetValueType.percent,
    language: true,
    xAxis: 'tag_id',
    source: 'tags',
    type2: [ServicesWidgetType2.WidgetType2Column],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.math_and_statistics, WidgetCategory.tagging_and_categories],
    exampleData: tagProfitCombinations,
    usage: 2.4,
    tags: [WidgetTag.gem],
  },
  tag_percent_combinations: {
    type: ServicesWidgetValueType.money,
    language: true,
    xAxis: 'tag_id',
    source: 'tags',
    type2: [ServicesWidgetType2.WidgetType2Column, ServicesWidgetType2.WidgetType2Bubble],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.math_and_statistics, WidgetCategory.tagging_and_categories],
    exampleData: tagProfitCombinations,
    usage: 28.8,
    tags: [WidgetTag.popular, WidgetTag.popular],
  },
  income_percent: {
    type: ServicesWidgetValueType.percent,
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart, ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.income_and_profit, WidgetCategory.math_and_statistics],
    exampleData: exampleDataDatetimeSimple,
    usage: 11.6,
  },
  income_percent_balance: {
    hideCounter: true,
    type: ServicesWidgetValueType.percent,
    visualType: [ServicesWidgetType.WidgetTypeChart],
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    minMembership: ServicesMembership.LEVEL_NOVICE_PLUS,
    categories: [WidgetCategory.balance_and_portfolio],
    exampleData: exampleDataDatetimeSimple,
    usage: 18.2,
  },
  avg_income_percent_balance: {
    type: ServicesWidgetValueType.percent,
    visualType: [ServicesWidgetType.WidgetTypeChart, ServicesWidgetType.WidgetTypeNumber],
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    minMembership: ServicesMembership.LEVEL_NOVICE_PLUS,
    categories: [WidgetCategory.balance_and_portfolio],
    exampleData: exampleDataDatetimeSimple,
    usage: 4.6,
  },
  income_percent_balance_cumulative: {
    hideCounter: true,
    type: ServicesWidgetValueType.percent,
    visualType: [ServicesWidgetType.WidgetTypeChart],
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    minMembership: ServicesMembership.LEVEL_NOVICE_PLUS,
    categories: [WidgetCategory.balance_and_portfolio],
    exampleData: exampleDataDatetimeSimpleCumulative,
    usage: 18,
    tags: [WidgetTag.popular],
  },
  best_usdt: {
    type: ServicesWidgetValueType.money,
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.trade_metrics],
    usage: 16.4,
    tags: [WidgetTag.popular],
  },
  worst_usdt: {
    type: ServicesWidgetValueType.money,
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.trade_metrics],
    usage: 13.8,
  },
  best_percent: {
    type: ServicesWidgetValueType.percent,
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.trade_metrics],
    usage: 9,
  },
  worst_percent: {
    type: ServicesWidgetValueType.percent,
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.trade_metrics],
    usage: 4.4,
  },
  counter: {
    type: ServicesWidgetValueType.number,
    hideCounter: true,
    visualType: [ServicesWidgetType.WidgetTypeChart, ServicesWidgetType.WidgetTypeNumber],
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    categories: [WidgetCategory.trade_metrics],
    exampleData: exampleDataDatetimeSimple,
    usage: 36,
    tags: [WidgetTag.popular, WidgetTag.simple],
  },
  profit_factor: {
    type: ServicesWidgetValueType.number,
    type2: [
      ServicesWidgetType2.WidgetType2Column,
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart, ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.math_and_statistics, WidgetCategory.income_and_profit],
    exampleData: exampleDataDatetimeSimple,
    usage: 59.8,
    tags: [WidgetTag.popular],
  },
  profit_factor_per_symbol: {
    xAxis: 'name',
    type: ServicesWidgetValueType.number,
    type2: [
      ServicesWidgetType2.WidgetType2Column,
      ServicesWidgetType2.WidgetType2Pie,
      ServicesWidgetType2.WidgetType2Bubble,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    categories: [WidgetCategory.math_and_statistics, WidgetCategory.income_and_profit],
    exampleData: exampleDataPieSymbol,
    usage: 4.6,
    tags: [WidgetTag.gem],
  },
  average_usdt: {
    type: ServicesWidgetValueType.money,
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart, ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.income_and_profit],
    exampleData: exampleDataDatetimeSimple,
    usage: 6.4,
  },
  average_usdt_sum: {
    type: ServicesWidgetValueType.money,
    type2: [
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
      ServicesWidgetType2.WidgetType2Column,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart, ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.income_and_profit],
    exampleData: exampleDataDatetimeSimple,
    usage: 5.2,
  },
  average_leverage: {
    valuePrepend: 'x',
    type: ServicesWidgetValueType.number,
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    minMembership: ServicesMembership.LEVEL_NOVICE_PLUS,
    categories: [WidgetCategory.risk_management, WidgetCategory.trade_metrics],
    usage: 11.2,
    tags: [WidgetTag.popular],
  },
  max_leverage: {
    valuePrepend: 'x',
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    type: ServicesWidgetValueType.number,
    minMembership: ServicesMembership.LEVEL_NOVICE_PLUS,
    categories: [WidgetCategory.risk_management, WidgetCategory.trade_metrics],
    usage: 10,
  },
  min_leverage: {
    valuePrepend: 'x',
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    type: ServicesWidgetValueType.number,
    minMembership: ServicesMembership.LEVEL_NOVICE_PLUS,
    categories: [WidgetCategory.risk_management, WidgetCategory.trade_metrics],
    usage: 1,
  },
  average_max_loose_percent: {
    type: ServicesWidgetValueType.percent,
    minMembership: ServicesMembership.LEVEL_TRADER,
    type2: [
      ServicesWidgetType2.WidgetType2Column,
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart, ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.risk_management, WidgetCategory.trade_metrics],
    exampleData: exampleDataDatetimeSimpleNegative,
    usage: 5.8,
  },
  max_max_loose_percent: {
    type: ServicesWidgetValueType.percent,
    minMembership: ServicesMembership.LEVEL_TRADER,
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.risk_management, WidgetCategory.trade_metrics],
    usage: 0.8,
  },
  min_max_loose_percent: {
    type: ServicesWidgetValueType.percent,
    minMembership: ServicesMembership.LEVEL_TRADER,
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.risk_management, WidgetCategory.trade_metrics],
    usage: 6.6,
  },
  average_max_win_percent: {
    type: ServicesWidgetValueType.percent,
    minMembership: ServicesMembership.LEVEL_TRADER,
    type2: [
      ServicesWidgetType2.WidgetType2Column,
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart, ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.risk_management, WidgetCategory.trade_metrics],
    exampleData: exampleDataDatetimeSimple,
    usage: 3.8,
  },
  max_max_win_percent: {
    type: ServicesWidgetValueType.percent,
    minMembership: ServicesMembership.LEVEL_TRADER,
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.risk_management, WidgetCategory.trade_metrics],
    usage: 8.2,
  },
  average_percent: {
    type: ServicesWidgetValueType.percent,
    type2: [
      ServicesWidgetType2.WidgetType2Column,
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
    ],
    visualType: [ServicesWidgetType.WidgetTypeChart, ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.income_and_profit, WidgetCategory.trade_metrics],
    exampleData: exampleDataDatetimeSimple,
    usage: 4.2,
  },
  profit_loss: {
    visualType: [ServicesWidgetType.WidgetTypeCalendar],
    categories: [WidgetCategory.income_and_profit],
    type: ServicesWidgetValueType.money,
    total_name: 'widgets.total_turnover',
    totalPrepend: '$',
    usage: 100,
    tags: [WidgetTag.popular, WidgetTag.simple],
  },
  winning_percent: {
    type: ServicesWidgetValueType.percent,
    total_name: 'widgets.total_trades_count',
    gauge: true,
    visualType: [
      ServicesWidgetType.WidgetTypeCalendar,
      ServicesWidgetType.WidgetTypeChart,
      ServicesWidgetType.WidgetTypeNumber,
    ],
    type2: [ServicesWidgetType2.WidgetType2Column],
    categories: [WidgetCategory.math_and_statistics],
    exampleData: exampleDataDatetimeSimple,
    usage: 64.8,
    tags: [WidgetTag.popular],
  },
  loosing_percent: {
    type: ServicesWidgetValueType.percent,
    gauge: true,
    inverted: true,
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    categories: [WidgetCategory.math_and_statistics],
    usage: 15.6,
    tags: [WidgetTag.popular],
  },
  weekday_percent: {
    type: ServicesWidgetValueType.percent,
    // language: true,
    xAxis: 'name',
    source: 'weekdays',
    categories: [WidgetCategory.math_and_statistics, WidgetCategory.time_based_analysis],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    type2: [ServicesWidgetType2.WidgetType2Column],
    exampleData: profitByDayOfWeek,
    usage: 3.8,
  },
  weekday_profit: {
    type: ServicesWidgetValueType.money,
    // language: true,
    xAxis: 'name',
    source: 'weekdays',

    categories: [WidgetCategory.income_and_profit, WidgetCategory.time_based_analysis],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    type2: [ServicesWidgetType2.WidgetType2Column],
    exampleData: profitByDayOfWeek,
    usage: 17.2,
    tags: [WidgetTag.popular, WidgetTag.simple, WidgetTag.gem],
  },
  hourly_profit: {
    type: ServicesWidgetValueType.money,
    // language: true,
    xAxis: 'name',
    source: 'hourly_intervals',
    categories: [WidgetCategory.income_and_profit, WidgetCategory.time_based_analysis],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    type2: [ServicesWidgetType2.WidgetType2Column],
    exampleData: profitByTimeOfDay,
    usage: 18.2,
    tags: [WidgetTag.popular, WidgetTag.simple, WidgetTag.gem],
  },
  hourly_percent: {
    type: ServicesWidgetValueType.percent,
    // language: true,
    xAxis: 'name',
    source: 'hourly_intervals',
    categories: [WidgetCategory.math_and_statistics, WidgetCategory.time_based_analysis],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    type2: [ServicesWidgetType2.WidgetType2Column],
    exampleData: profitByTimeOfDay,
    usage: 5.4,
  },
  average_winning_usdt: {
    type: ServicesWidgetValueType.money,
    categories: [WidgetCategory.income_and_profit, WidgetCategory.trade_metrics],
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    usage: 20.6,
    tags: [WidgetTag.popular, WidgetTag.simple],
  },
  average_loosing_usdt: {
    type: ServicesWidgetValueType.money,
    categories: [WidgetCategory.income_and_profit, WidgetCategory.trade_metrics],
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    usage: 20.8,
    tags: [WidgetTag.popular, WidgetTag.simple],
  },
  average_winning_percent: {
    type: ServicesWidgetValueType.percent,
    categories: [WidgetCategory.income_and_profit, WidgetCategory.trade_metrics],
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    usage: 24,
    tags: [WidgetTag.popular, WidgetTag.simple],
  },
  average_loosing_percent: {
    type: ServicesWidgetValueType.percent,
    categories: [WidgetCategory.income_and_profit, WidgetCategory.trade_metrics],
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    usage: 23.4,
    tags: [WidgetTag.popular, WidgetTag.simple],
  },
  holding_time_average: {
    type: ServicesWidgetValueType.duration,
    categories: [WidgetCategory.risk_management, WidgetCategory.time_based_analysis],
    visualType: [ServicesWidgetType.WidgetTypeNumber, ServicesWidgetType.WidgetTypeChart],
    type2: [
      ServicesWidgetType2.WidgetType2Column,
      ServicesWidgetType2.WidgetType2Line,
      ServicesWidgetType2.WidgetType2LineSimple,
      ServicesWidgetType2.WidgetType2LineStep,
    ],
    exampleData: {
      '2024-05-15': {
        counter: 1,
        value: '630',
        dateValue: '2024-05-15',
      },
      '2024-05-16': {
        counter: 2,
        value: '154',
        dateValue: '2024-05-16',
      },
      '2024-05-17': {
        counter: 4,
        value: '800',
        dateValue: '2024-05-17',
      },
      '2024-05-18': {
        counter: 1,
        value: '510',
        dateValue: '2024-05-18',
      },
    },
    usage: 23.4,
    tags: [WidgetTag.popular, WidgetTag.simple],
  },
  symbol_duration_average: {
    valueAppend: 'min',
    xAxis: 'name',
    type: ServicesWidgetValueType.duration,
    categories: [WidgetCategory.time_based_analysis],
    visualType: [ServicesWidgetType.WidgetTypeChart],
    type2: [ServicesWidgetType2.WidgetType2Column, ServicesWidgetType2.WidgetType2Pie],
    exampleData: exampleDataPieSymbol,
    usage: 16.4,
  },
  holding_time_max: {
    type: ServicesWidgetValueType.duration,
    categories: [WidgetCategory.trade_metrics, WidgetCategory.time_based_analysis],
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    usage: 10.8,
  },
  holding_time_min: {
    type: ServicesWidgetValueType.duration,
    categories: [WidgetCategory.trade_metrics, WidgetCategory.time_based_analysis],
    visualType: [ServicesWidgetType.WidgetTypeNumber],
    usage: 1.2,
  },
}
