import { t } from '@lingui/macro'
import styles from './style.module.scss'
import { Button, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { useCallback, useState } from 'react'
import { TeamFormDefaultCashback } from '../TeamForm'
import { useTeamData } from '@/utils/hooks/useTeamData'

const MembersDefaultCashback = () => {
  const [isOpen, setIsOpen] = useState(false)

  const {
    userData: {
      teamManagerInfo: { binanceCashbackPercent, bybitCashbackPercent, okxCashbackPercent },
    },
  } = useTeamData()

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <>
      <div className={styles.root}>
        <div className={styles.cashbackTitle}>
          {t({ id: 'teamPage.defaultCashbackTitle', message: 'Default Cashback' })}
        </div>
        <div className={styles.cashbackContainer}>
          <div className={styles.cashbackItem}>
            <div className={styles.cashbackItemName}>
              {t({ id: 'teamPage.defaultBinanceTitle', message: 'Binance' })}
            </div>
            <div className={styles.cashbackPercent}>{binanceCashbackPercent ? binanceCashbackPercent + '%' : ''}</div>
          </div>
          <div className={styles.cashbackItem}>
            <div className={styles.cashbackItemName}>{t({ id: 'teamPage.defaultBybitTitle', message: 'Bybit' })}</div>
            <div className={styles.cashbackPercent}>
              <div className={styles.cashbackPercent}>{bybitCashbackPercent ? bybitCashbackPercent + '%' : ''}</div>
            </div>
          </div>
          <div className={styles.cashbackItem}>
            <div className={styles.cashbackItemName}>{t({ id: 'teamPage.defaultOkxTitle', message: 'OKX' })}</div>
            <div className={styles.cashbackPercent}>{okxCashbackPercent ? okxCashbackPercent + '%' : ''}</div>
          </div>
        </div>
        <Button.Primary
          size={Button.Size.Medium}
          label={t({
            id: 'teamPage.cashbackDefaultSet',
            message: 'Set cashback by default',
          })}
          onClick={() => {
            setIsOpen(true)
          }}
          dataTestId={DataTestIds.MemberCashbackPercentSet}
        />
      </div>
      <InteractiveModal isOpen={isOpen}>
        <InteractiveModalParts.SubHeader
          text={t({ id: 'teamMembers.cashbackDefault.heading', message: 'Cashback by default' })}
        />
        <TeamFormDefaultCashback
          onClose={onClose}
          cashbackDefaultPercent={{
            binanceCashbackPercent,
            bybitCashbackPercent,
            okxCashbackPercent,
          }}
        />
      </InteractiveModal>
    </>
  )
}
export { MembersDefaultCashback }
