import { FC } from 'react'

interface LongArrowDownProps {
  className?: string
  width?: number
  height?: number
}

export const LongArrowDown: FC<LongArrowDownProps> = ({ className, width = 11, height = 13 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.61004 9.78871L4.61004 1.25947C4.61004 0.817645 4.96821 0.459473 5.41004 0.459473C5.85187 0.459473 6.21004 0.817645 6.21004 1.25947L6.21004 9.78739L9.21453 6.90155C9.53318 6.59548 10.0396 6.60568 10.3457 6.92433C10.6517 7.24298 10.6415 7.74941 10.3229 8.05547L5.96353 12.2427C5.65392 12.5401 5.16479 12.5401 4.85518 12.2427L0.495822 8.05547C0.177175 7.74941 0.166974 7.24298 0.473038 6.92433C0.779103 6.60569 1.28553 6.59548 1.60418 6.90155L4.61004 9.78871Z"
        fill="currentColor"
      />
    </svg>
  )
}
