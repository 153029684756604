import { getRULocale } from './ru'
import { getENLocale } from './en'
import { getUKLocale } from './uk'
import { WidgetTag, WidgetCategory } from '../settings'
import { ServicesWidgetSource } from '@/utils/types/contracts.types'
import { TLanguage } from '@/core/constants'

export type ExtraKeyLiterals =
  | 'others'
  | 'example'
  | 'trades_count'
  | 'profit'
  | 'commission'
  | 'loss'
  | 'hourly_intervals_0'
  | 'hourly_intervals_1'
  | 'hourly_intervals_2'
  | 'hourly_intervals_3'
  | 'hourly_intervals_4'
  | 'hourly_intervals_5'
  | 'hourly_intervals_6'
  | 'hourly_intervals_7'
  | 'hourly_intervals_8'
  | 'hourly_intervals_9'
  | 'hourly_intervals_10'
  | 'hourly_intervals_11'
  | 'weekdays_Monday'
  | 'weekdays_Tuesday'
  | 'weekdays_Wednesday'
  | 'weekdays_Thursday'
  | 'weekdays_Friday'
  | 'weekdays_Sunday'
  | 'weekdays_Saturday'
  | 'session-none'
  | 'session-asia'
  | 'session-asia-europe'
  | 'session-europe'
  | 'session-europe-usa'
  | 'session-usa'
  | 'allowable_loss'
  | 'stop_trading_loss'
  | 'over_trading'
  | 'profit_if_stop_trading'

// List of all possible keys for the translation
export type i18nWidgetList = {
  [K in ServicesWidgetSource as `${K}` | `${K}_desc`]: string
} & { [K in WidgetTag]: string } & { [K in WidgetCategory]: string } & {
  [key in ExtraKeyLiterals]: string
}

export type i18nFunc = (key: keyof i18nWidgetList) => string

// Returns a translation function based on the specified locale
function _i18n(locale: TLanguage): i18nFunc {
  switch (locale) {
    case 'ru':
      return function (key: keyof i18nWidgetList): string {
        return getRULocale()[key] || key
      }
    case 'uk':
      return function (key: keyof i18nWidgetList): string {
        return getENLocale()[key] || key
      }
    default:
      return function (key: keyof i18nWidgetList): string {
        return getUKLocale()[key] || key
      }
  }
}

const LOCALES: TLanguage[] = ['en', 'ru', 'uk']

// Returns an array of all translations for the specified key
// Used to work with name based values in the chart config and callbacks
function i18nAll(key: keyof i18nWidgetList): string[] {
  const result: string[] = []

  for (const locale of LOCALES) {
    const i18nFunc = _i18n(locale)
    result.push(i18nFunc(key))
  }

  return result
}

export { _i18n, i18nAll }
