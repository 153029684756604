import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from './dashboard.state'
import { dashboardAsyncActions } from './dashboard.actions'
import { isArray } from 'lodash-es'
import { DashboardFilterType } from './dashboard.types'

const slice = createSlice({
  name: '[Tiger Trade Dashboard]',
  initialState,
  reducers: {
    resetBoard(state) {
      state.dashboardStatus = 'idle'
      state.dashboard = []
    },
    setBoardFilters(state, action: PayloadAction<DashboardFilterType>) {
      state.dashboardFilter = action.payload
    },
  },
  extraReducers: builder => {
    // Board
    builder
      .addCase(dashboardAsyncActions.GetBoardTC.pending, (state, action) => {
        state.dashboardStatus = 'loading'
      })
      .addCase(dashboardAsyncActions.GetBoardTC.rejected, (state, action) => {
        state.dashboardStatus = 'failed'
      })
      .addCase(dashboardAsyncActions.GetBoardTC.fulfilled, (state, action) => {
        const dashboard = action.payload
        state.dashboard = isArray(dashboard) ? dashboard : []
        state.dashboardStatus = 'succeeded'
      })
  },
})

export const dashboardActions = {
  ...slice.actions,
  ...dashboardAsyncActions,
}

export const dashboardReducer = slice.reducer
