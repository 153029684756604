import { SVG } from '@/assets/svg'
import style from '../style.module.scss'
import { SelectOption } from '@/ui/kit'
import { t } from '@lingui/macro'
import { E_TableFilterExchangeType } from '@/redux/trades/trades.types'

export const ExchangeOptions = (availableKeys: string[] | undefined) => {
  const BINANCE_VIP = 'BINANCE_VIP'

  const exchangeFilter: SelectOption<E_TableFilterExchangeType>[] = [
    {
      label: t({
        id: 'history.trades.exchangeFilterAll',
        comment: 'All',
      }),
      value: E_TableFilterExchangeType.All,
    },
    {
      label: (
        <div className={style.exchangeFilterContainer}>
          <div>
            <SVG.Tmm.BinanceIcon className={style.icon} />
          </div>
          <div>Binance Vip</div>
        </div>
      ),
      value: E_TableFilterExchangeType.BinanceVip,
      initialShowedLabel: E_TableFilterExchangeType.BinanceVip,
    },
    {
      label: (
        <div className={style.exchangeFilterContainer}>
          <div>
            <SVG.Tmm.BinanceIcon className={style.icon} />
          </div>
          <div>Binance</div>
        </div>
      ),
      value: E_TableFilterExchangeType.Binance,
      initialShowedLabel: E_TableFilterExchangeType.Binance,
    },
    {
      label: (
        <div className={style.exchangeFilterContainer}>
          <div>
            <SVG.Tmm.BybitIcon className={style.icon} />
          </div>
          <div>ByBit</div>
        </div>
      ),
      value: E_TableFilterExchangeType.Bybit,
      initialShowedLabel: E_TableFilterExchangeType.Bybit,
    },
    {
      label: (
        <div className={style.exchangeFilterContainer}>
          <div>
            <SVG.Tmm.OkxIcon className={style.icon} />
          </div>
          <div>OKX</div>
        </div>
      ),
      value: E_TableFilterExchangeType.OKX,
      initialShowedLabel: E_TableFilterExchangeType.OKX,
    },
  ]
  const index = exchangeFilter.findIndex(option => option.value === E_TableFilterExchangeType.BinanceVip)
  if (index !== -1 && availableKeys && !availableKeys.includes(BINANCE_VIP)) {
    exchangeFilter.splice(index, 1)
  }

  return exchangeFilter
}
