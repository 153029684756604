import React, { FC } from 'react'
import s from '../style.module.scss'
import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'

export const RiskManagerBanner: FC = () => {
  return (
    <div className={s.banner}>
      <div className={s.titleText}>
        <span className={s.textGradient}>(βeta)</span>{' '}
        {t({
          id: 'teamRiskSubscriptionForm.heading',
        })}
      </div>
      <div className={s.text}>
        <Trans
          id="riskManagerSubscription.riskManagerIsUnderDevelopment"
          message="Риск-менеджер находится в разработке и <whiteText> в данный момент работает только для Binance.</whiteText>"
          components={{
            whiteText: <span className={s.textWhite} />,
          }}
        />
        <div className={s.comingSoon}>{t({ id: 'riskManager.comingSoon', message: 'Bybit, OKX coming soon!' })}</div>
      </div>
    </div>
  )
}
