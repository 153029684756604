import { FC, useEffect, useMemo, useState } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Routes as Components } from './routes'
import { useAppSelector, useMobileSizeDetect } from '@/utils'
import { AvailableScreenSize } from '@/types'
import { PUBLIC_ROUTES, urls } from './urls'
import { KYCTier } from '@/backend/models/KycDTO'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { Layout } from '@/layouts'
import { KYCVerificationError } from '@/ui/organisms'
import { useExchangeBlockedOverlay } from '@/utils/hooks'
import BinanceTechnicalWorks from '@/ui/organisms/BinanceTechicalWorks'
import { KycStatusModal } from '@/ui/molecules/KycStatusModal'
import { E_KYC_STATUS_USER_VIEW } from '@/redux/kyc/kyc.types'
import { isExchangeBinance } from '@/utils/lib/exchange'
import * as amplitude from '@amplitude/analytics-browser'
import { TEMPORARILY_BINANCE_LIMIT_DATE } from '@/core/config/common'
import { InteractiveModal } from '@/ui/kit'
import { BinanceVipConsentForm } from '@/ui/molecules/BinanceVipConsentForm'
import { checkMetadataBinanceVipPermisions } from '@/redux/profile/profile.utils'
import { EXCHANGES_TYPE } from '@/core/constants'

interface AppRouterProps {
  /**
   * First render flag for enabling some animation on the page
   */
  isFirstRender?: boolean
}

const AvailableUrlsBeforeKYC = [...PUBLIC_ROUTES, urls.kyc, urls.partner, urls.chooseExchange]

const AppRouter: FC<AppRouterProps> = props => {
  const [isOpenPopup, setIsOpenPopup] = useState(true)
  const { isFirstRender } = props
  const { pathname } = useLocation()
  const [isMobile, setMode] = useMobileSizeDetect()
  const { userKYC, userKycLinkState } = useAppSelector(state => state.kyc)
  const { createdApiReadOnlyStatusInitial } = useAppSelector(state => state.apiKeys)
  const {
    vipAccess,
    metadata: { binanceVipConsent },
  } = useAppSelector(state => state.profile)
  const location = useLocation()

  const { metadata } = useAppSelector(state => state.profile)
  const { firstSesstionTrackedTimestamp } = metadata

  const [kycStatusUserView, setKycStatusUserView] = useState<E_KYC_STATUS_USER_VIEW | null>()

  const { exchangeType } = useExchageType()
  const isBlockedTier = userKYC.isBlocked || userKYC.isUsaMode
  const { shouldShowBlockingOverlay } = useExchangeBlockedOverlay(exchangeType)
  const { availableUsersTrading, availableUsersTradingStatus } = useAppSelector(state => state.trades)

  const isShowBinanceVipConsent = useMemo(() => {
    return exchangeType === 'BINANCE_VIP' && vipAccess && !checkMetadataBinanceVipPermisions(binanceVipConsent)
  }, [exchangeType, vipAccess, binanceVipConsent])

  const navigate = useNavigate()

  useEffect(() => {
    document.body.scrollTo(0, 0)
  }, [pathname])

  const handleNavigateToKyc = () => {
    navigate(urls.kyc)
    setKycStatusUserView(null)
  }

  const navigateChooseExchangePage = () => {
    navigate(`${urls.chooseExchange}?binance=false`)
    setKycStatusUserView(null)
  }

  function closePopup() {
    setIsOpenPopup(false)
  }

  useEffect(() => {
    if (
      exchangeType === 'BINANCE_VIP' &&
      (pathname === urls.api || pathname === urls.cashback || pathname === urls.how)
    ) {
      navigate(urls.root)
    }
  }, [exchangeType, pathname])

  // navigate unverified users to kyc page for Binance
  useEffect(() => {
    if (
      isExchangeBinance(exchangeType) &&
      ([KYCTier.Unverified, KYCTier.Basic].includes(userKYC.kycTier) || createdApiReadOnlyStatusInitial === 'failed') && // if tier is lower than Standard OR subaccounts are not created yet
      !AvailableUrlsBeforeKYC.includes(pathname) &&
      pathname !== urls.kyc
    ) {
      if (isMobile) {
        setMode(AvailableScreenSize.Desktop)
      }
      navigate(urls.kyc)
    }
  }, [pathname, exchangeType, isMobile, userKYC.kycTier, createdApiReadOnlyStatusInitial])

  const isNewUser = useMemo(() => {
    if (userKYC.kycTier === KYCTier.Unverified) {
      return true
    }

    return Number(firstSesstionTrackedTimestamp) > TEMPORARILY_BINANCE_LIMIT_DATE.getTime()
  }, [firstSesstionTrackedTimestamp, userKYC.kycTier])

  useEffect(() => {
    amplitude.setUserId(userKYC.userId)
  }, [userKYC])

  useEffect(() => {
    if (isExchangeBinance(exchangeType) && !AvailableUrlsBeforeKYC.includes(pathname) && isNewUser) {
      if (userKycLinkState.status === 'INIT') {
        setKycStatusUserView(E_KYC_STATUS_USER_VIEW.NEW_USER_INIT)
      }
      if (userKycLinkState.status === 'PENDING') {
        setKycStatusUserView(E_KYC_STATUS_USER_VIEW.NEW_USER_PENDING)
      }
      if (userKycLinkState.status === 'FINAL_REJECT') {
        setKycStatusUserView(E_KYC_STATUS_USER_VIEW.NEW_USER_REJECTED)
      }
    }
  }, [exchangeType, isNewUser, pathname, userKycLinkState.status])

  // showing modal  to kyc page for all exchange types
  /*
  useEffect(() => {
    if (
      exchangeType &&
      ([KYCTier.Unverified, KYCTier.Basic].includes(userKYC.kycTier)) && // if tier is lower than Standard OR subaccounts are not created yet
      !AvailableUrlsBeforeKYC.includes(pathname) &&
      pathname !== urls.kyc
    ) {
      setKycStatusUserView(E_KYC_STATUS_USER_VIEW.UNVERIFIED)
    }
    if (KYCTier.Basic === userKYC.kycTier && userKYC.activity === KYCActivity.WorkflowInProgress) {
      setKycStatusUserView(E_KYC_STATUS_USER_VIEW.PENDING)
    }
    if (KYCTier.Basic === userKYC.kycTier && userKYC.activity === KYCActivity.WorkflowError) {
      setKycStatusUserView(E_KYC_STATUS_USER_VIEW.REJECTED)
    }
  }, [pathname, exchangeType, isMobile, userKYC.kycTier, userKYC.activity, createdApiReadOnlyStatusInitial])
  */

  if (isBlockedTier && !PUBLIC_ROUTES.includes(pathname)) {
    return (
      <Layout.Blocked enableHeaderAnimation={isFirstRender}>
        <KYCVerificationError />
      </Layout.Blocked>
    )
  }
  if (shouldShowBlockingOverlay) {
    return (
      <Layout.Main>
        <BinanceTechnicalWorks />
      </Layout.Main>
    )
  }
  return (
    <>
      <Routes>
        {/* RO DO REFACTORING!  */}
        {Components({ isFirstRender, isMobile }).map(route => {
          let component = route.component

          if (route.onlyAvailable === AvailableScreenSize.Desktop && isMobile) {
            if (exchangeType === EXCHANGES_TYPE.OKX_AFFILIATE) {
              if (location.pathname !== urls.okxAffiliate) {
                component = <Navigate key={route.path} to={urls.okxAffiliate} />
              }
              return <Route key={route.path} path={route.path} element={component} />
            }

            component = <Navigate key={route.path} to={urls.orders} />
          }

          if (route.onlyAvailable === AvailableScreenSize.Mobile && !isMobile) {
            component = <Navigate key={route.path} to={urls.root} />
          }

          return <Route key={route.path} path={route.path} element={component} />
        })}
        {/*<Route path="*" element={<Page404 />} />*/}
      </Routes>
      {kycStatusUserView && (
        <KycStatusModal
          isOpen={isOpenPopup}
          onClose={closePopup}
          handleNavigateToKyc={handleNavigateToKyc}
          kycStatusUserView={kycStatusUserView}
          handleNavigateToChooseExchange={navigateChooseExchangePage}
        />
      )}
      {
        <InteractiveModal isOpen={isShowBinanceVipConsent}>
          <BinanceVipConsentForm />
        </InteractiveModal>
      }
    </>
  )
}

export default AppRouter
