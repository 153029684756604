import { t } from '@lingui/macro'
import { i18nWidgetList } from './index'
import { memoize } from 'lodash-es'

export const getRULocale = memoize(
  (): i18nWidgetList => ({
    income_and_profit: 'Доходы и прибыль',
    math_and_statistics: 'Математика и статистика',
    risk_management: 'Управление рисками',
    volume_and_turnover: 'Объем и оборот',
    commissions_and_fees: 'Комиссии и сборы',
    balance_and_portfolio: 'Баланс и портфель',
    trade_metrics: 'Метрики торговли',
    time_based_analysis: 'Анализ, основанный на времени',
    tagging_and_categories: 'Маркировка и категории',
    custom_and_derived_metrics: 'Пользовательские и производные метрики',

    new: 'Новый',
    popular: 'Популярный',
    complex: 'Сложный',
    simple: 'Простой',
    premium: 'Премиум',
    gem: 'Уникальный',

    example: t({ id: 'trades.dashboard.example', comment: 'example' }),
    trades_count: 'Количество сделок',

    others: 'Другие',

    profit: 'Прибыль',
    commission: 'Комиссия',
    loss: 'Убыток',
    hourly_intervals_0: '00:00 - 02:00',
    hourly_intervals_1: '02:00 - 04:00',
    hourly_intervals_2: '04:00 - 06:00',
    hourly_intervals_3: '06:00 - 08:00',
    hourly_intervals_4: '08:00 - 10:00',
    hourly_intervals_5: '10:00 - 12:00',
    hourly_intervals_6: '12:00 - 14:00',
    hourly_intervals_7: '14:00 - 16:00',
    hourly_intervals_8: '16:00 - 18:00',
    hourly_intervals_9: '18:00 - 20:00',
    hourly_intervals_10: '20:00 - 22:00',
    hourly_intervals_11: '22:00 - 23:59',
    weekdays_Monday: 'Понедельник',
    weekdays_Tuesday: 'Вторник',
    weekdays_Wednesday: 'Среда',
    weekdays_Thursday: 'Четверг',
    weekdays_Friday: 'Пятница',
    weekdays_Sunday: 'Воскресенье',
    weekdays_Saturday: 'Суббота',

    income_usdt_stacked_by_api_keys: 'Совокупная прибыль по API ключам ($)',
    income_usdt_stacked_by_api_keys_desc:
      'Этот виджет отображает совокупную прибыль за определенный период времени, разбитую по индивидуальным API ключам. Он позволяет трейдерам отслеживать общую прибыль, принесенную различными API ключами, и выявлять наиболее успешные стратегии.',
    income_usdt_accumulative_by_api_key: t({
      id: 'trades.dashboard.income_usdt_accumulative_by_api_key',
      comment: 'Accumulative',
    }),
    income_usdt_accumulative_by_api_key_desc: `Этот виджет отображает накопительную прибыль за определенное время, разбитую по индивидуальным API ключам. Он позволяет трейдерам отслеживать эффективность различных API ключей, предоставляя информацию о том, какие стратегии или боты приносят наибольшую прибыль.`,
    income_usdt_stacked: 'Прибыль и комиссия ($)',
    income_usdt_stacked_desc: `Этот виджет визуализирует чистую прибыль и соответствующие комиссионные сборы за указанный период. Он предоставляет четкое представление о тенденциях прибыли наряду с затратами на транзакции.`,
    std_dev_profit_over_volume: 'Распределение прибыли по объёму сделки',
    std_dev_mae_over_duration: 'Распределение МПУ по времени в сделке',
    std_dev_mfe_over_duration: 'Распределение МПП по времени в сделке',
    std_dev_profit_over_duration: 'Распределение прибыли по времени в сделке',
    std_dev_mfe: 'Распределение сделок по МПП',
    std_dev_mae: 'Распределение сделок по МПУ',
    std_dev_profit: 'Распределение сделок по прибыли',
    std_dev_profit_over_volume_desc:
      'Этот виджет отображает стандартное распределение прибыли в зависимости от объёма сделки. Он позволяет трейдерам увидеть, как прибыль коррелирует с размером их сделок, предоставляя информацию о том, приносят ли более крупные или меньшие объёмы сделок большую прибыль.',
    std_dev_mae_over_duration_desc:
      'Этот виджет визуализирует стандартное распределение МПУ (максимального плавающего убытка) по различным временным интервалам в сделке. Он подсвечивает типичные моменты во время сделки, когда происходят наибольшие отрицательные ценовые движения, предоставляя информацию для уточнения тактики управления рисками.',
    std_dev_mfe_over_duration_desc:
      'Этот виджет показывает стандартное распределение МПП (максимальной плавающей прибыли) во времени в рамках сделки. Он раскрывает, когда обычно наблюдаются наибольшие потенциальные доходы, помогая определить оптимальные моменты для закрытия позиций с максимизацией прибыли.',
    std_dev_profit_over_duration_desc:
      'Этот виджет отображает стандартное распределение прибыли по различным временным промежуткам в сделке. Он помогает понять, в какие периоды обычно реализуются прибыль или убытки, что руководит временем открытия и закрытия сделок.',
    std_dev_mfe_desc:
      'Этот виджет отображает стандартное распределение МПП (максимальной плавающей прибыли) для серии сделок. Трейдеры могут анализировать общность благоприятных ценовых экскурсий, чтобы скорректировать стратегии для лучшего использования положительных движений.',
    std_dev_mae_desc:
      'Этот виджет представляет стандартное распределение МПУ (максимального плавающего убытка) для серии сделок. Он ценен для понимания регулярности отрицательных ценовых движений, что критически важно для установления соответствующих ордеров стоп-лосс.',
    std_dev_profit_desc:
      'Этот виджет показывает стандартное распределение прибыли для группы сделок. Отображая обычные тенденции прибыли или убытка, он служит основным инструментом для оценки и улучшения стратегии.',
    balance_per_api_key: 'Баланс по API ключам',
    pay_off_ratio: 'Коэффициент прибыльности',
    max_drawdown: 'Максимальная просадка депозита (%)',
    income_usdt_api_keys: 'Прибыль по каждому апи ключу ($)',
    balance: 'Баланс',
    profit_factor: 'Профит фактор',
    profit_factor_per_symbol: 'Профит фактор по монете',
    pie_symbol_income_profit: 'Распределение прибыли по монетам',
    pie_symbol_income_loose: 'Распределение убытков по монетам',
    funding: 'Фандинг (Funding) ($)',
    average_max_win_percent: 'Средний МПП (%)',
    // min_max_win_percent: "Минимальный МПП (%)",
    max_max_win_percent: 'Максимальный МПП (%)',
    average_max_loose_percent: 'Средний МПУ (%)',
    min_max_loose_percent: 'Минимальный МПУ (%)',
    max_max_loose_percent: 'Максимальный МПУ (%)',
    pie_symbol: 'Распределение по монетам',
    pie_side: 'Распределение по лонг/шорт',
    avg_volume: 'Средний объём ($)',
    avg_volume_per_symbol: 'Средний объём по монете ($)',
    commission_usdt: 'Комиссия ($)',
    commission_usdt_accumulative: 'Кумулятивная Комиссия ($)',
    symbol_income: 'Прибыль по монете ($)',
    category_profit: 'Прибыль по Категории ($)',
    category_win_percentage: 'Процент выигрыша по категории (%)',
    income_usdt: 'Прибыль ($)',
    average_return: 'Средняя доходность портфеля (%)',
    average_return_desc:
      'Средняя доходность портфеля - это показатель производительности инвестиционного портфеля, выраженный в процентах. Он рассчитывается путем деления общей доходности портфеля на общий объем инвестированного капитала, а затем умножения результата на 100%. Этот показатель говорит о средней доходности ваших инвестиций в портфеле. Если средняя доходность портфеля положительна, то это означает, что портфель приносит прибыль. Если же она отрицательна, то портфель понес убытки.',
    income_usdt_accumulative: 'Кумулятивная прибыль ($)',
    income_usdt_candlestick: 'Кумулятивная прибыль свечной график ($)',
    income_usdt_anonymous_accumulative: 'Приватная кумулятивная прибыль ($)',
    income_percent: 'Сумма процентов (%)',
    income_percent_balance: t({ id: 'trades.dashboard.income_percent_balance', comment: 'Percent balance' }),
    avg_income_percent_balance: 'Средний прирост к депозиту (%)',
    income_percent_balance_cumulative: t({
      id: 'trades.dashboard.income_percent_balance_cumulative',
      comment: 'Accumulative profit %',
    }),
    best_usdt: 'Лучшая сделка ($)',
    worst_usdt: 'Худшая сделка ($)',
    best_percent: 'Лучшая сделка (%)',
    worst_percent: 'Худшая сделка (%)',
    counter: 'Счетчик сделок',
    volume: 'Оборот ($)',
    volume_per_symbol: 'Объём по монете ($)',
    average_usdt: 'Средняя прибыль по сделке ($)',
    average_usdt_sum: 'Средняя сумма прибыли за день ($)',
    average_percent: 'Средний процент по сделке (%)',
    average_leverage: 'Среднее плечо',
    max_leverage: 'Максимальное плечо',
    min_leverage: 'Минимальное плечо',
    average_winning_usdt: 'Средняя плюсовая сделка ($)',
    average_winning_percent: 'Средняя плюсовая сделка (%)',
    average_loosing_usdt: 'Средняя минусовая сделка ($)',
    average_loosing_percent: 'Средняя минусовая сделка (%)',
    holding_time_average: 'Среднее время удержания',
    holding_time_min: 'Минимальное время удержания',
    holding_time_max: 'Максимальное время удержания',
    loosing_percent: 'Процент минусовых сделок',
    ticker_price: 'График цены актива',
    ticker_price_desc:
      'График цены актива - это линейный или свечной график, отображающий изменения цены актива для визуального сравнения с результатами вашей торговли. Поддерживаются тикеры BTCUSDT и ETHUSDT. Вы можете выбрать нужный тикер в настройках виджета (иконка карандаша в углу). Чтобы изменить период, измените фильтр "группировать данные" по минутам/часам/...',
    winning_percent: 'Процент плюсовых сделок',
    volume_usdt_accumulative: 'Кумулятивный оборот ($)',
    profit_loss: 'Чистая прибыль к убытку',
    tag_profit: 'Прибыль по причинам входа',
    tag_percent: 'Процент выигрыша по причинам входа',
    tag_percent_combinations: 'Прибыль по комбинациям причин входа',
    tag_win_rate_combinations: 'Процент выигрыша по комбинациям причин входа',
    weekday_profit: 'Прибыль по дням недели',
    weekday_percent: 'Процент выигрыша по дням недели',
    hourly_profit: 'Прибыль по времени суток',
    hourly_percent: 'Процент выигрыша по времени суток',
    math_expectation: 'Математическое ожидание',
    counter_api_keys: 'Счетчик сделок по АПИ ключу',
    symbol_duration_average: 'Среднее время сделки по монете (минут)',
    risk_values_usd: 'Пределы убытка в день ($)',
    allowable_loss: 'Допустимая просадка',
    stop_trading_loss: 'Стоп торги',
    over_trading: 'Переторговка',
    profit_if_stop_trading: 'Разница в прибыли',
    recovery_factor: 'Коэффициент восстановления',
    average_loss_deviation_percent: 'Отклонение от среднего убытка (%)',
    trading_session_math_expectation: `Мат. ожидание по торговым сессиям  ($)`,
    'session-none': 'Отсутствует',
    'session-asia': 'Азия',
    'session-europe': 'Европа',
    'session-usa': 'Америка',
    'session-asia-europe': 'Азия/Европа',
    'session-europe-usa': 'Европа/Америка',

    volume_usdt_accumulative_desc: `Кумулятивный оборот по сделкам. Оборот включает в себя как открывающие ордера так и закрывающие (например: закрытая сделка по покупка монеты на 100$ будет учтена как покупка 100$ и продажа ~100$, оборот 200$)`,

    recovery_factor_desc: `Коэффициент восстановления — это показатель, который измеряет доходность инвестиционной или торговой стратегии с учетом риска. Он рассчитывается как общая прибыль деленная на максимальное падение. Коэффициент восстановления дает представление о том, насколько эффективно инвестиция преобразовала риск в доход.

  Высокий (> 2): Эффективное соотношение риска и дохода.
  Средний (1 до 2): Средняя эффективность, требуется осторожность.
  Низкий (< 1): Низкая эффективность, высокий риск для низкой доходности.
  Отрицательный: Убытки, высокий риск.`,

    trading_session_math_expectation_desc:
      'Этот виджет предоставляет разбивку эффективности торгов по основным мировым торговым сессиям. Группируя сделки из азиатских, европейских и американских рынков, а также периоды их пересечения, он рассчитывает математическое ожидание для каждой сессии, помогая инвесторам понять ожидаемую доходность на основе исторических данных.',

    average_loss_deviation_percent_desc: `Данный виджет считает соотношение среднего убытка к его стандартному отклонению за выбранный период. Значения выше 100% означает что имеются убытки значительно превышающие ваш средний убыток. Такие показатели говорят о несоблюдении риск менеджмента. 
  
  Формула: стандартное отклонение убытка деленное на средний убыток умноженное на 100.`,

    risk_values_usd_desc: `Данный виджет считает ваши пределы убытка после которых стоит остановить торги или начать следить за торговлей особенно внимательно. 
  Виджет рассчитывает 4 показателя: 
  1. Стоп торги - когда вы достигаете такого убытка за день вам стоит остановиться. Проценты указанные снизу, например 70% означает что в 70% дней по достижению показателей сверху вы закрывали день в убыток. 
  2. Уровень допустимой просадки - этот показатель говорит о вашей стандартной просадки после который вы с вероятностью указанной снизу имеете шанс погасить убыток и выйти в плюс. 
  3. Переторговка - этот показатель считает сколько вы отдаете профита от вашего максимального показателя за день. 
  4. Разница в прибыли - показывает разницу в вашем кумулятивном доходе, если бы вы следовали правилу стоп торгов. 
  
  Значения убытка за день находящиеся между вторым показателем и первым - это серая зона, в которой продолжение торгов возможно но с особой внимательностью к каждой совершаемой сделке. 
  
  Какой процент выбрать? Тут все зависит от вашего уровня риска, чем ниже процент слева, тем выше допустимая просадка, но тем меньше шанс что вы из нее выберетесь. Поэтому для высокорисковых трейдеров рекомендуется ориентироваться на 60-70% а для консервативных 80-90%.`,

    symbol_duration_average_desc: 'Сколько времени в среднем занимают сделки по определённой монете в минутах.',

    income_usdt_desc:
      'Виджет показывает вашу чистую прибыль за минусом всех комиссий биржи. Данные сгруппированы по дням открытия сделки по умолчанию. Виджет доступен в виде линейного графика или столбчатого.',
    income_usdt_accumulative_desc:
      'Сумма прибыли за весь предыдущий период включая текущую дату. Этот виджет показывает рост или потерю депозита даже не зная ваш баланс. Отрицательные значения говорят об убыточности торговли. Виджет доступен в виде линейного графика или столбчатого.',
    income_usdt_candlestick_desc:
      'Сумма прибыли за весь предыдущий период включая текущую дату. Этот виджет показывает рост или потерю депозита даже не зная ваш баланс. Отрицательные значения говорят об убыточности торговли. Виджет доступен в виде свечного графика.',
    income_usdt_anonymous_accumulative_desc:
      'Сумма прибыли за весь предыдущий период включая текущую дату но абсолютные значения переведены в проценты. Этот виджет показывает рост или потерю депозита даже не зная ваш баланс. Отрицательные значения говорят об убыточности торговли. Виджет доступен в виде линейного графика или столбчатого.',
    counter_desc: 'Сумма сделок за отрезок времени. Виджет доступен в виде линейного графика или столбчатого.',
    commission_usdt_desc:
      'Сумма комиссии выплаченных в USD(T) за отрезок времени. Виджет доступен в виде линейного графика или столбчатого.',
    commission_usdt_accumulative_desc:
      'Сумма комиссий за весь предыдущий период включая текущую дату. Виджет доступен в виде линейного графика или столбчатого.',
    profit_factor_desc:
      'Это результат отношения общего количества всех сделок, принесших прибыль, к сумме убыточных торговых операций за определённый временной интервал (общий профит деленный на общий убыток). Значения выше единицы (для большей уверенности 1,6) говорят об прибыльности торговой стратегии. Меньше убыточности.',
    profit_factor_per_symbol_desc:
      'Это результат отношения общего количества всех сделок, принесших прибыль, к сумме убыточных торговых операций за определённый временной интервал (общий профит деленный на общий убыток). Значения выше единицы (для большей уверенности 1,6) говорят об прибыльности торговой стратегии. Меньше убыточности.',
    max_drawdown_desc:
      'Процентное соотношение между самым низким значением баланса депозита к самому высокому. Слишком большие потери (больше 30%) говорят о несоблюдении риск менеджмента.',
    income_usdt_api_keys_desc:
      'Сумма чистой прибыли разбитая по каждому вашему апи ключу. В скобках указано количество сделок.',
    balance_per_api_key_desc: 'Баланс разбитый по каждому вашему апи ключу.',
    counter_api_keys_desc: 'Количество сделок разбитое по каждому вашему апи ключу.',
    pie_symbol_income_profit_desc:
      'Круговая диаграмма, показывающая распределение прибыли по монетам. Помогает выявить ваши фаворитные монеты. которые приносят больше всего прибыли.',
    pie_symbol_income_loose_desc:
      'Круговая диаграмма, показывающая распределение убытков по монетам. Помогает выявить монеты, которые приносят больше всего убытков и требуют смены стратегии или исключения из торговли.',
    weekday_percent_desc:
      'Процент успешных сделок совершенных в определенный день недели. Помогает понять дни когда торговля приоритетна а когда стоит сделать выходной.',
    weekday_profit_desc: 'Распределение прибыли по дню недели. Помогает выявить лучший день для отдыха от торговли',

    hourly_profit_desc:
      'Распределение прибыли по времени суток (используется часовой пояс указанный в настройках вашего профиля). Торговый день состоит из нескольких сессий (Азия, Европа, Америка) и поведение рынка может меняться в разное время суток. Найдите ваши “рабочие” часы с помощью данного виджета. Если прибыль не соответствует проценту выигрыша то это повод для более детального анализа сделок в рассматриваемый период.',
    hourly_percent_desc:
      'Процент успешных сделок совершенных в определенное время суток. Торговый день состоит из нескольких сессий (Азия, Европа, Америка) и поведение рынка может меняться в разное время суток. Найдите ваши “рабочие” часы с помощью данного виджета. Если прибыль не соответствует проценту выигрыша то это повод для более детального анализа сделок в рассматриваемый период.',
    average_usdt_desc: 'Среднее значение прибыли за отрезок времени. ',
    average_usdt_sum_desc: 'Среднее сумма прибыли за отрезок времени (по умолчанию день). ',
    average_percent_desc:
      'Среднее значение процента за отрезок времени. Процент рассчитывается как разница между средней точкой входа и средней точкой выхода.',
    winning_percent_desc:
      'Этот виджет вычисляет процент сделок, которые завершились чистой прибылью, определяя их как “выигрышные сделки”. Он показывает долю успешных сделок относительно общего числа, предоставляя ценные сведения об эффективности вашей торговой стратегии.',
    holding_time_average_desc:
      'Отображает на графике среднее время удержание сделок. Отличный индикатор для тех кто спешит с выходом и не дает профиту расти.',
    income_percent_desc:
      'Сумма процентов взятых по сделкам за период времени. Считается процент от средней точки входа до средней точки выхода.',
    avg_volume_desc:
      'Среднее значение объёма в сделка. Для расчета берется пиковое значение объёма и умножается на среднюю точку входа.',
    avg_volume_per_symbol_desc:
      'Среднее значение объёма в сделках. Для расчета берется пиковое значение объёма и умножается на среднюю точку входа.',
    volume_desc:
      'Оборот включает в себя как открывающие ордера так и закрывающие (например: закрытая сделка по покупка монеты на 100$ будет учтена как покупка 100$ и продажа ~100$, оборот 200$)',
    volume_per_symbol_desc:
      'Примерный денежный объем в сделке. Считается как произведение суммы контрактов на среднюю цену входа.',
    avg_income_percent_balance_desc:
      'Средний показатель прироста к депозиту по сделкам. Прирост к депозиту высчитывается от баланса на момент открытия сделки.',
    income_percent_balance_desc:
      'Отражает ваш прирост депозита к предыдущей дате. Можно группировать как по дням так и по часам и месяцам. Самый достоверный показатель прибыли для трейдера.',
    income_percent_balance_cumulative_desc:
      'Отражает ваш прирост депозита к предыдущей дате. Можно группировать как по дням так и по часам и месяцам. Самый достоверный показатель прибыли для трейдера.',
    average_max_loose_percent_desc:
      'МПУ - максимальный плавающий убыток полученный за период сделки. Отражает насколько цена шла в противоположную стороны от вашей точки входа. Показывает насколько вы склонны “пересиживать” стоп лоссы. Среднее значение подходит для подбора самого оптимального размера стоп-лосса. ',
    average_max_win_percent_desc:
      'МПП - максимальная плавающая прибыль за период сделки. Это значение пиково значения прибыли по сделки. Если ваши тейки сильно выше или сильно ниже этого значения значит вы не дополучаете прибыль.',
    profit_loss_desc:
      'Эффективно контролируйте свои торговые результаты с помощью календаря, который визуально различает ежедневную прибыль (зелёным цветом) и убытки (красным цветом). Он предоставляет быстрый обзор с отображением общего дохода, общих убытков, чистой прибыли и средней ежедневной прибыли в верхней части. Идеален для трейдеров, которым необходимо чётко понимать свои ежедневные финансовые результаты.',
    pie_symbol_desc:
      'Распределение количества сделок по монетам. Сам по себе виджет не несет большой пользы. Но если добавить в настройках, например, “только прибыльные” и сравнить с “только убыточные”, то можно найти полезные закономерности.',
    pie_side_desc:
      'Распределение сделок между лонг и шорт в процентном соотношении. Сам по себе виджет не несет большой пользы. Но если добавить в настройках например “только прибыльные” и сравнить с “только убыточные” можно найти полезные закономерности.',
    tag_profit_desc:
      'Очень важный виджет позволяющий найти рабочие сетапы и исключить причины которые приносят только убытки. Необходимо заполнять причины входа в разделе “мои сделки”.',
    tag_percent_desc:
      'Виджет позволяющий отсечь убыточные причины входа. При группировке с виджетом “Прибыль по причинам входа” может показать где сетап успешный но не приносит должной прибыли и может быть оптимизирован. Необходимо заполнять причины входа в разделе “мои сделки”.',
    tag_percent_combinations_desc:
      'Пузырьковая диаграмма всех комбинаций причин входа. Ось X — прибыль, ось Y — количество сделок. Правый верхний угол – самая прибыльная комбинация. Левый нижний угол — самая убыточная комбинация.',
    tag_win_rate_combinations_desc:
      'Процент выигрыша для каждой комбинации причин входа в сделку в дневнике. Узнайте в каких комбинациях вы чаще всего выигрываете и в каких проигрываете.',
    category_profit_desc:
      'Сумма прибыли по сделкам сгруппированные по категории. Для переноса сделки в категорию выберите чекбокс в разделе “мои сделки”',
    category_win_percentage_desc:
      'Процент выигрышных сделок сгруппированных по категории. Для переноса сделки в категорию выберите чекбокс в разделе “мои сделки”',
    funding_desc:
      'Фандинг, он же ставка финансирования. Это дополнительная комиссия призванная убрать перекосы в рынке. Если слишком много людей стоят в лонг то они платят комиссию шортам и наоборот. На бирже Binance фандинг платится раз в 8 часов. На бирже FTX раз в час но меньше.',
    balance_desc:
      'Ваш баланс, чтобы виджет заработал, после добавления нужно нажать иконку “карандаша” в углу виджета и выбрать апи ключ в фильтрах.',
    symbol_income_desc: 'Виджет, показывающий ваши самые прибыльные монеты и самые убыточные.',
    best_usdt_desc: 'Самая прибыльная сделка. В углу виджета есть иконка “цепи” по которой можно перейти к сделке.',
    worst_usdt_desc:
      'Сделка по которой вы получили максимальный убыток. В углу виджета есть иконка “цепи” по которой можно перейти к сделке.',
    average_winning_usdt_desc: 'Среднее значение прибыли по сделкам закрытым в плюс.',
    average_loosing_usdt_desc: 'Среднее значение убытка по сделкам закрытым в минус.',

    best_percent_desc:
      'Самая прибыльная сделка по взятому проценту движения без учета плеча. В углу виджета есть иконка “цепи” по которой можно перейти к сделке.',
    worst_percent_desc:
      'Самая худшая сделка по взятому проценту движения без учета плеча. В углу виджета есть иконка “цепи” по которой можно перейти к сделке.',
    loosing_percent_desc:
      'Ваш процент минусовых сделок. При риск менеджменте 1 к 3 даже 30% успешных сделок могут приносить прибыль.',
    average_loosing_percent_desc:
      'Среднее значение процента по сделкам получившим убыток. Считается процент от средней точки входа до средней точки выхода.',
    average_winning_percent_desc:
      'Среднее значение процента по сделкам закрытым в плюс. Считается процент от средней точки входа до средней точки выхода.',
    math_expectation_desc:
      'Математическое ожидание показывает значение вашей потенциальной прибыли на 100 сделок вперёд. Если оно отрицательное, значит ваша торговая стратегия требует изменения. Не стоит воспринимать это число как гарантированный заработок в будущем. Мат ожидание больше работает как “профит фактор” и рассматривается как плюс/минус.',
    pay_off_ratio_desc:
      'Он же pay-off ratio, считается как средняя прибыльная сделка деленная на среднюю убыточную сделку. Очень похоже на профит фактор но отличается большим усреднением. По нашему мнению профит фактор вместе с математическим ожиданием работает более надежно чем данный виджет.',
    average_leverage_desc:
      'Плечо считается относительно к депозиту и подходит в первую очередь для оценки взятого риска на сделку.',
    min_leverage_desc:
      'Минимальное значение плеча за период времени. Плечо считается относительно к депозиту и подходит в первую очередь для оценки взятого риска на сделку.',
    max_leverage_desc:
      'Максимальное значение плеча за период времени. Плечо считается относительно к депозиту и подходит в первую очередь для оценки взятого риска на сделку.',
    max_max_loose_percent_desc:
      'МПУ - максимальный плавающий убыток полученный за период сделки. Отражает насколько цена шла в противоположную стороны от вашей точки входа. Показывает насколько вы склонны “пересиживать” стоп лоссы. Среднее значение подходит для подбора самого оптимального размера стоп-лосса. ',
    min_max_loose_percent_desc:
      'МПУ - максимальный плавающий убыток полученный за период сделки. Отражает насколько цена шла в противоположную стороны от вашей точки входа. Показывает насколько вы склонны “пересиживать” стоп лоссы. Среднее значение подходит для подбора самого оптимального размера стоп-лосса. ',
    max_max_win_percent_desc:
      'МПП - максимальная плавающая прибыль за период сделки. Это пиковое значение прибыли по сделки. Если ваши тейки сильно выше или сильно ниже этого значения значит вы не дополучаете прибыль.',
    holding_time_max_desc: 'Максимальное значение времени нахождения в сделке за период времени',
    holding_time_min_desc: 'Минимальное значение времени нахождения в сделке за период времени',
  })
)
