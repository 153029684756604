import { FC, useEffect } from 'react'
import { TwoColumnPage } from '@/layouts/TwoColumnPage'
import { ReferralHowLeftBar } from '@/ui/molecules/ReferralHowLeftBar'
import { ReferralHowRightBar } from '@/ui/molecules/ReferralHowRightBar'
import s from './style.module.scss'
import { useActions, useAppSelector } from '@/utils'
import { referralsActions } from '@/redux/referrals/referrals.slice'
import { useExchageType } from '@/utils/hooks/useExchangeType'

const ReferralHowItWorks: FC = () => {
  const { GetReferralBenefitsTC } = useActions(referralsActions)
  const {
    benefits: { benefitsStatus },
  } = useAppSelector(state => state.referrals)
  const { exchangeType } = useExchageType()

  useEffect(() => {
    if (exchangeType === undefined || benefitsStatus !== 'idle') return
    GetReferralBenefitsTC(exchangeType)
  }, [GetReferralBenefitsTC, exchangeType, benefitsStatus])

  return (
    <TwoColumnPage className={s.root}>
      <ReferralHowLeftBar />
      <ReferralHowRightBar />
    </TwoColumnPage>
  )
}

export { ReferralHowItWorks }
