import { FC, ReactNode, useEffect, useRef } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { referralsActions } from '@/redux/referrals/referrals.slice'
import { storage } from '@/utils/lib/storage'
import { useActions, useAppSelector } from '@/utils'
import cashbackAsyncActions from '@/redux/cashback/cashback.actions'
import { partnerActions } from '@/redux/partner/partner.slice'

interface IDataProviderProps {
  children: ReactNode
}

const ReferralProvider: FC<IDataProviderProps> = props => {
  const { isLoading, isAuthenticated } = useAuth0()
  const { GetReferralOverviewTC, ApplyReferralCodeTC } = useActions(referralsActions)
  const { GetPartnersListTC } = useActions(partnerActions)
  const { GetCashbackTC } = useActions(cashbackAsyncActions)

  const {
    overview: { referrerCode, referrerLocked },
    status,
    statusApply,
  } = useAppSelector(state => state.referrals)

  const {
    metadata: { isFirstSessionTracked },
  } = useAppSelector(state => state.profile)

  const code = useRef<string>()

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      const storageRefCode = storage.getReferralCode()
      if (storageRefCode.date) code.current = storageRefCode.referralCode
      else storage.clearReferralCode()

      if (code.current) {
        GetReferralOverviewTC()
      }
    }
  }, [isLoading, isAuthenticated, GetReferralOverviewTC])

  useEffect(() => {
    if (
      statusApply === 'idle' &&
      code.current &&
      status === 'succeeded' &&
      !referrerCode &&
      !isFirstSessionTracked &&
      !referrerLocked
    ) {
      ApplyReferralCodeTC({ referrerCode: code.current })
      storage.clearReferralCode()
    } else if (status === 'succeeded' && referrerCode) {
      storage.clearReferralCode()
    }
  }, [code, referrerCode, ApplyReferralCodeTC, status, statusApply, isFirstSessionTracked, referrerLocked])

  useEffect(() => {
    if (statusApply === 'succeeded') {
      GetCashbackTC()
      GetPartnersListTC()
    }
  }, [statusApply, GetCashbackTC, GetPartnersListTC])

  return <>{props.children}</>
}

export { ReferralProvider }
