import { TabBar } from '@/ui/molecules'
import { TabBarPropsType, TabSetter } from '@/ui/molecules/TabBar'
import { FC, useCallback, useMemo, useState } from 'react'
import { getPartnerTranslations } from '@/translations/partner'
import { Achievements } from '@/ui/molecules/Achievements'
import { useAchievementsData } from './PartnerRightBar.hooks/useAchievementsData'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import { useAppSelector } from '@/utils'
import { PARTNERS_CONTENT } from '@/core/config/partner'
import { Articles } from '../Articles'
import { InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { EInteractiveModalVariant } from '@/ui/kit/Modals/InteractiveModal/InteractiveModal'

import styles from './style.module.scss'

const TAB_KEYS = ['video', 'achievement', 'different', 'study'] as const

export const PartnerRightBar: FC = () => {
  const { partner } = useAppSelector(state => state.partner)
  const [playerVisible, setPlayerVisible] = useState(false)
  const [videoId, setVideoId] = useState<string>()

  const translations = useMemo(() => {
    return getPartnerTranslations()
  }, [])

  const tabs = useMemo<TabBarPropsType['tabs']>(() => {
    return TAB_KEYS.map(key => {
      return {
        id: key,
        text: translations.tabs[key as keyof (typeof translations)['tabs']],
      }
    })
  }, [translations])

  const [activeTab, setActiveTab] = useState<(typeof TAB_KEYS)[number]>(TAB_KEYS[1])

  const showPlayer = useCallback((videoId: string) => {
    setPlayerVisible(true)
    setVideoId(videoId)
  }, [])

  const closePlayer = useCallback(() => {
    setPlayerVisible(false)
    setVideoId(undefined)
  }, [])

  const { achievementsSource, differentSource, studySource, videoSource } = useAchievementsData(showPlayer)

  if (partner === undefined) return null

  return (
    <div className={styles.rightBar}>
      <div className={styles.header}>
        <div className={styles.icon}>{PARTNERS_CONTENT[partner].icon}</div>
        <TitleH1 label={translations.brands[partner].title} />
      </div>
      <TabBar tabs={tabs} setActiveTab={setActiveTab as TabSetter} activeTab={activeTab} />
      {activeTab === 'video' && <Articles articles={videoSource} />}
      {activeTab === 'achievement' && <Achievements achievementsSource={achievementsSource} />}
      {activeTab === 'different' && <Articles articles={differentSource} />}
      {activeTab === 'study' && (
        <Articles
          articles={studySource}
          emptyProps={{
            title: translations.study.empty.title,
            label: translations.study.empty.subtitle,
          }}
        />
      )}
      <InteractiveModal isOpen={playerVisible} variant={EInteractiveModalVariant.WIDE} onClose={closePlayer}>
        {videoId && <InteractiveModalParts.Player videoId={videoId} />}
      </InteractiveModal>
    </div>
  )
}
