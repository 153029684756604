import { Layout } from '@/layouts'
import {
  History,
  KycLevelsPage,
  KYCPage,
  LeveragePage,
  OrdersAndPositions,
  PrivacyPolicy,
  ReferralHowItWorks,
  ReferralPage,
  SignInPage,
  SignUpPage,
  TermsAndConditions,
  TigetTokenPage,
  Transactions,
  Voucher,
  Wallet,
  RiskManager,
  DiaryMonthAnalyzePage,
} from '@/pages'
import { ApiCreateKeyPage } from '@/pages/ApiCreateKeyPage'
import { ApiEditKeyPage } from '@/pages/ApiKeyEditPage'
import { ApiPage } from '@/pages/ApiPage'
import { Assets } from '@/pages/Assets'
import { Cashback } from '@/pages/Cashback'
import { ChooseExchangePage } from '@/pages/ChooseExchangePage'
import { HowItWorksPage } from '@/pages/HowItWorksPage'
import { PartnerPage } from '@/pages/Partner'
import { ProfilePage } from '@/pages/ProfilePage'
import { ReferralEarnings } from '@/pages/ReferralEarnings'
import { Root } from '@/pages/Root'
import { AvailableScreenSize, RouterComponentType } from '@/types'
import {
  ROUTER_HISTORY_PARAM_NAME,
  urls,
  ROUTER_TEAM_PARAM_NAME,
  TRADE_ID,
  TRADE_DIARY_WEEK,
  TRADE_ANALYZE_MONTH,
} from './urls'
import { Team } from '@/pages/Team'
import { AddressesPage } from '@/pages/AddressesPage'
import { CreateAddressesPage } from '@/pages/CreateAddressesPage'
import { EditAddressesPage } from '@/pages/EditAddressesPage'
import HistoryTrades from '@/ui/organisms/HistoryTrades'
import { TradeInfo } from '@/pages/TradeInfo/TradeInfo'
import { DiaryPage } from '@/pages/DiaryPage'
import { DiaryWeekPage } from '@/pages/DiaryWeekPage'
import { DashboardPage } from '@/pages/DashboardPage/DashboardPage'
import { OKXAffiliate } from '@/pages/OKXAfiliate'

// {
// 	path: urls.policyTerms,
// 	onlyAvailable: AvailableScreenSize.Mobile,
// 	component: (
// 	  <Layout.Main isBackgroundHidden>
// 		<PolicyTerms />
// 	  </Layout.Main>
// 	),
//   },

interface Props {
  isFirstRender?: boolean
  isMobile?: boolean
}

export const Routes = ({ isFirstRender, isMobile }: Props): RouterComponentType[] => {
  return [
    {
      path: urls.root,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isBackButtonHidden enableHeaderAnimation={isFirstRender}>
          <Root />
        </Layout.Main>
      ),
    },
    {
      path: urls.wallet,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isBackButtonHidden enableHeaderAnimation={isFirstRender}>
          <Wallet />
        </Layout.Main>
      ),
    },
    {
      path: 'okx-affiliate',
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isBackButtonHidden enableHeaderAnimation={isFirstRender}>
          <OKXAffiliate />
        </Layout.Main>
      ),
    },
    {
      path: urls.signin,
      component: (
        <Layout.Main isBackButtonHidden>
          <SignInPage />
        </Layout.Main>
      ),
    },
    {
      path: urls.signup,
      component: (
        <Layout.Main isBackButtonHidden>
          <SignUpPage />
        </Layout.Main>
      ),
    },
    {
      path: urls.profile,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isBackButtonHidden>
          <ProfilePage />
        </Layout.Main>
      ),
    },
    {
      path: urls.referrals,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isBackButtonHidden>
          <ReferralPage />
        </Layout.Main>
      ),
    },
    {
      path: urls.referralEarnings,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isBackButtonHidden>
          <ReferralEarnings />
        </Layout.Main>
      ),
    },
    {
      path: urls.howReferral,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main>
          <ReferralHowItWorks />
        </Layout.Main>
      ),
    },
    {
      path: urls.orders,
      component: (
        <Layout.Main isBackButtonHidden>
          <OrdersAndPositions />
        </Layout.Main>
      ),
    },
    {
      path: urls.cashback,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main>
          <Cashback />
        </Layout.Main>
      ),
    },
    {
      path: urls.transactions,
      component: (
        <Layout.Main isPopupMode={!isMobile}>
          <Transactions />
        </Layout.Main>
      ),
    },
    {
      path: `${urls.historyRoot}/:${ROUTER_HISTORY_PARAM_NAME}`,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isPopupMode>
          <History />
        </Layout.Main>
      ),
    },
    {
      path: urls.trades,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isPopupMode size="xl">
          <HistoryTrades />
        </Layout.Main>
      ),
    },
    {
      path: urls.summary,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isPopupMode size="xl">
          <DiaryPage />
        </Layout.Main>
      ),
    },
    {
      path: urls.dashboard,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isPopupMode>
          <DashboardPage />
        </Layout.Main>
      ),
    },
    {
      path: `${urls.summary}/:${TRADE_DIARY_WEEK}`,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isPopupMode size="xl">
          <DiaryWeekPage />
        </Layout.Main>
      ),
    },
    {
      path: `${urls.summaryAnalyze}/:${TRADE_ANALYZE_MONTH}`,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isPopupMode size="xl">
          <DiaryMonthAnalyzePage />
        </Layout.Main>
      ),
    },
    {
      path: `${urls.trades}/:${TRADE_ID}`,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isPopupMode size="xl">
          <TradeInfo />
        </Layout.Main>
      ),
    },
    {
      path: urls.tigerToken,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main>
          <TigetTokenPage />
        </Layout.Main>
      ),
    },
    {
      path: urls.leverage,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isBackButtonHidden>
          <LeveragePage />
        </Layout.Main>
      ),
    },
    {
      path: urls.assets,
      component: (
        <Layout.Main isPopupMode={!isMobile}>
          <Assets />
        </Layout.Main>
      ),
    },
    {
      path: urls.api,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isBackButtonHidden>
          <ApiPage />
        </Layout.Main>
      ),
    },
    {
      path: urls.apiCreate,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isPopupMode>
          <ApiCreateKeyPage />
        </Layout.Main>
      ),
    },
    {
      path: urls.apiEdit,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isPopupMode>
          <ApiEditKeyPage />
        </Layout.Main>
      ),
    },
    {
      path: urls.how,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isPopupMode>
          <HowItWorksPage />
        </Layout.Main>
      ),
    },
    {
      path: urls.kyc,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main>
          <KYCPage />
        </Layout.Main>
      ),
    },
    {
      path: urls.terms,
      component: (
        <Layout.Main isPopupMode isBackgroundHidden={isMobile}>
          <TermsAndConditions />
        </Layout.Main>
      ),
    },
    {
      path: urls.policy,
      component: (
        <Layout.Main isPopupMode isBackgroundHidden={isMobile}>
          <PrivacyPolicy />
        </Layout.Main>
      ),
    },
    {
      path: urls.kycLevels,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isPopupMode>
          <KycLevelsPage />
        </Layout.Main>
      ),
    },
    {
      path: urls.voucher,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isBackButtonHidden>
          <Voucher />
        </Layout.Main>
      ),
    },
    {
      path: urls.chooseExchange,
      component: (
        <Layout.Main isBackButtonHidden isTopMenuHidden>
          <ChooseExchangePage />
        </Layout.Main>
      ),
    },
    {
      path: urls.partner,
      component: (
        <Layout.Main isBackButtonHidden>
          <PartnerPage />
        </Layout.Main>
      ),
    },
    {
      path: `${urls.teamRoot}/:${ROUTER_TEAM_PARAM_NAME}`,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isBackButtonHidden enableHeaderAnimation={isFirstRender}>
          <Team />
        </Layout.Main>
      ),
    },
    {
      path: urls.riskManager,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isBackButtonHidden enableHeaderAnimation={isFirstRender}>
          <RiskManager />
        </Layout.Main>
      ),
    },
    {
      path: urls.addresses,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isBackButtonHidden>
          <AddressesPage />
        </Layout.Main>
      ),
    },
    {
      path: urls.createAddresses,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isBackButtonHidden>
          <CreateAddressesPage />
        </Layout.Main>
      ),
    },
    {
      path: urls.editAddresses,
      onlyAvailable: AvailableScreenSize.Desktop,
      component: (
        <Layout.Main isBackButtonHidden>
          <EditAddressesPage />
        </Layout.Main>
      ),
    },
    // {
    //   path: '/404',
    //   component: <Page404 />,
    // },
  ]
}
