import { FC, useCallback, useMemo } from 'react'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import { Button } from '@/ui/kit'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import { TitleH3 } from '@/ui/atoms/TitleH3'
import { Waiting } from '@/ui/atoms/Waiting'
import { OrdersTable } from '@/ui/organisms/OrdersTable'
import { PositionsTable } from '@/ui/organisms/PositionsTable'
import { useOverview } from '@/utils/hooks/useOverview'
import s from './style.module.scss'
import { PopupSequence } from '@/ui/organisms/PopupSequence'
import { useAppSelector, useGoogleAuthCode, useGoogleAuthSettings } from '@/utils/hooks'
import { OrdersPageWidget } from '@/ui/organisms/OrdersPageWidget'
import { OrdersStatisticsWidget } from '@/ui/organisms/OrdersStatisticsWidget'
import clsx from 'clsx'
import { DataTestIds } from '@/utils/lib/dataTestIds'

const OrdersAndPositionsPC: FC = () => {
  const googleAuthSettings = useGoogleAuthSettings()
  const googleAuthCode = useGoogleAuthCode()
  const {
    metadata: { isOrdersPageBalancesEnabled },
  } = useAppSelector(state => state.profile)

  const {
    allOpenOrders,
    allPositions,
    isClosingOrders,
    isClosingPositions,
    cancelAllOrders,
    cancelAllPositions,
    cancelAllOrdersAndPositions,
  } = useOverview()

  const hasOrders = useMemo(() => !!allOpenOrders?.length, [allOpenOrders])
  const hasPositions = useMemo(() => !!allPositions?.length, [allPositions])

  const handleCancelOrders = useCallback(() => cancelAllOrders(), [cancelAllOrders])

  const handleCancelPositions = useCallback(() => cancelAllPositions(), [cancelAllPositions])

  const handleCancelOrdersAndPositions = useCallback(() => cancelAllOrdersAndPositions(), [cancelAllOrdersAndPositions])

  return (
    <div className={s.root}>
      <PopupSequence
        googleAuthSettings={googleAuthSettings}
        googleAuthCode={googleAuthCode}
        allowedPopups={['promomessage']}
      />

      <div className={s.heading}>
        <TitleH1 label={t({ message: 'Orders and positions', id: 'orders.heading' })} />
        {hasOrders || hasPositions ? (
          <Button.White
            label={t({ message: 'Close all', id: 'core.button.closeAll' })}
            disabled={isClosingOrders || isClosingPositions}
            onClick={handleCancelOrdersAndPositions}
            dataTestId={DataTestIds.OPCloseAllButton}
          />
        ) : null}
      </div>

      {isOrdersPageBalancesEnabled && (
        <div className={clsx(s.columns, s.statistics)}>
          <div className={s.columnLeft}>
            <OrdersPageWidget />
          </div>
          <div className={s.columnRight}>
            <OrdersStatisticsWidget />
          </div>
        </div>
      )}

      <div className={s.columns}>
        <div className={s.columnLeft}>
          <div className={s.columnHeading}>
            <TitleH3 label={t({ message: 'Orders', id: 'core.orders' })} />
            {isClosingOrders && (
              <Waiting
                label={t({ message: 'Closing orders', id: 'orders.closingOrders' })}
                dataTestId={DataTestIds.OPClosingOrders}
              />
            )}
            {hasOrders && !isClosingOrders && (
              <Button.Primary
                label={t({ message: 'Close all', id: 'orders.closeAll' })}
                rightIcon={<SVG.Additional.Close />}
                onClick={handleCancelOrders}
                dataTestId={DataTestIds.OPCloseAllOrdersButton}
              />
            )}
          </div>

          <OrdersTable />
        </div>

        <div className={s.columnRight}>
          <div className={s.columnHeading}>
            <TitleH3 label={t({ message: 'Positions', id: 'core.positions' })} />
            {isClosingPositions && (
              <Waiting
                label={t({ message: 'Closing positions', id: 'orders.closingPositions' })}
                dataTestId={DataTestIds.OPClosingPositions}
              />
            )}
            {hasPositions && !isClosingPositions && (
              <Button.Primary
                label={t({ comment: 'Close positions', id: 'positions.closeAll' })}
                rightIcon={<SVG.Additional.Close />}
                onClick={handleCancelPositions}
                dataTestId={DataTestIds.OPCloseAllPositionsButton}
              />
            )}
          </div>
          <PositionsTable />
        </div>
      </div>
    </div>
  )
}

export { OrdersAndPositionsPC }
