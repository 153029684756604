export const formatText = (value: string, charsAfterEllipsis: number = 4) => {
  const num = Number(value)

  if (!isNaN(num) && Math.abs(num) < 1) {
    const decimalPart = value.split('.')[1]

    if (decimalPart && decimalPart.length >= 8) {
      return `${value[0]}.${decimalPart.slice(0, 1)}…${decimalPart.slice(-charsAfterEllipsis)}`
    }
    return value
  } else {
    if (value.length > 10) {
      return `${value.slice(0, 6)}…`
    }
    return value
  }
}
