import { EPositionSide, IBalanceAsset, TMarginType, TOrderSide, TPositionSideHedgeMode } from '@tigertrade/binance-ts'
import {
  ContractHistoricOrder,
  LinearInverseInstrumentInfoV5,
  PositionV5,
  TradeModeV5,
  WalletBalanceV5Coin,
} from 'bybit-api'
import { AccountOverviewPositionsView, OverviewOpenOrder } from '@/backend/models/OverviewDTO'
import { TInstrumentType } from '@/core/types/overview'
import { EBybitInstruments } from './types'
import { BN_ZERO } from '@/core/constants/common'
import BigNumber from 'bignumber.js'

const MARGIN_MODE_MAP: Record<TradeModeV5, Lowercase<TMarginType>> = {
  '0': 'crossed',
  '1': 'isolated',
}

const POSITION_SIDE_MAP: Record<PositionV5['side'], EPositionSide> = {
  Buy: EPositionSide.LONG,
  Sell: EPositionSide.SHORT,
  None: '' as EPositionSide,
}

export const POSITION_SIDE_MAP_REVERSE: Record<EPositionSide, PositionV5['side']> = {
  LONG: 'Buy',
  SHORT: 'Sell',
  BOTH: '' as PositionV5['side'],
}

const orderSideMapper: Record<string, TOrderSide> = {
  Buy: 'BUY',
  Sell: 'SELL',
}

const getOrderTypeAndPrice = (orderSource: ContractHistoricOrder): { typeOutput: string; priceOutput: string } => {
  const priceOutput = orderSource.stopOrderType === '' ? orderSource.price : orderSource.triggerPrice
  const typeOutput =
    orderSource.stopOrderType === ''
      ? orderSource.orderType
      : orderSource.stopOrderType === 'Stop'
      ? 'Conditional'
      : orderSource.stopOrderType
  return {
    priceOutput,
    typeOutput,
  }
}

export const bybitDataMappers = {
  balanceMapper: (balanceCoin: WalletBalanceV5Coin): IBalanceAsset => {
    return {
      assetId: balanceCoin.coin,
      assetBalance: Number(balanceCoin.walletBalance),
      quoteBalance: {
        USD: Number(balanceCoin.usdValue),
      },
    }
  },
  positionMapper: (
    positionSource: PositionV5,
    instrumentsById: Record<string, LinearInverseInstrumentInfoV5>
  ): AccountOverviewPositionsView => {
    const instrument = instrumentsById[positionSource.symbol]
    const marginMode = MARGIN_MODE_MAP[positionSource.tradeMode]
    const positionSide = POSITION_SIDE_MAP[positionSource.side]
    const positionSideOutput = positionSide as TPositionSideHedgeMode

    /** 0 to 5 */
    const adlRankIndicator = (positionSource as PositionV5 & { adlRankIndicator?: number }).adlRankIndicator ?? 0

    return {
      adl: adlRankIndicator,
      baseAsset: instrument?.baseCoin || '',
      entryPrice: positionSource.avgPrice,
      instrumentType: EBybitInstruments.linear,
      liquidationPrice: positionSource.liqPrice,
      marginType: marginMode,
      positionAmt: positionSource.size,
      positionSide: positionSide,
      positionSideOutput: positionSideOutput,
      quoteAsset: instrument?.quoteCoin || '',
      symbol: positionSource.symbol,
      uid: `${positionSource.symbol}-${positionSource.positionIdx}`,
      unrealizedPnl: positionSource.unrealisedPnl,
      unrealizedPnlPercent: '',
      updateTime: Number(positionSource.updatedTime),
      positionIdx: positionSource.positionIdx,
    }
  },
  orderMapper: (orderSource: ContractHistoricOrder, instrumentType: TInstrumentType): OverviewOpenOrder => {
    const side = orderSideMapper[orderSource.side]

    const { typeOutput, priceOutput } = getOrderTypeAndPrice(orderSource)
    return {
      executedQty: orderSource.cumExecQty,
      instrumentType: instrumentType,
      isAlgo: false,
      orderId: orderSource.orderId,
      type: orderSource.orderType,
      orderTypeOutput: typeOutput,
      origQty: orderSource.qty,
      price: orderSource.price,
      priceOutput: priceOutput,
      side: side,
      status: orderSource.orderStatus,
      stopPrice: priceOutput,
      symbol: orderSource.symbol,
      time: Number(orderSource.createdTime),
      updateTime: Number(orderSource.updatedTime),
      stopOrderType: orderSource.stopOrderType || 'Order',
    }
  },
  calculateMargin: (coins: WalletBalanceV5Coin[]): string => {
    return coins
      .reduce((acc, coin) => {
        const price = new BigNumber(coin.usdValue).dividedBy(coin.walletBalance)
        return acc.plus(new BigNumber(coin.availableToWithdraw).multipliedBy(price))
      }, BN_ZERO)
      .toString()
  },
}
