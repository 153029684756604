import { FC, ReactNode, useEffect } from 'react'
import { t } from '@lingui/macro'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Loader } from '@/ui/molecules/Loader'
import { ErrorBase } from '@/ui/kit/Errors/ErrorBase'
import { apiKeyAsyncActions } from '@/redux/apiKey/apiKey.actions'
import { appActions } from '@/redux/app/app.slice'
import { useActions, useAppSelector } from '@/utils'
import { storage } from '@/utils/lib/storage'
import { urls, INIT_SEARCH_PARAM } from '@/router/urls'
import { kycActions } from '@/redux/kyc/kyc.slice'
import { isStatusFinal } from '@/utils/lib/isStatusFinal'
import { defineMetadata } from '@/utils/lib/metadata/mapper'
import { profileActions } from '@/redux/profile/profile.slice'
import { isExchangeBinance } from '@/utils/lib/exchange'
import { TExchangesAvailable } from '@/core/constants'
import { riskManagerActions } from '@/redux/riskManager/riskManager.slice'

export interface ISetupProviderProps {
  children: ReactNode
}

const SetupProvider: FC<ISetupProviderProps> = props => {
  const { children } = props

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { GetUserKycTC } = useActions(kycActions)
  const { CreateMasterApiKeysTC } = useActions(apiKeyAsyncActions)
  const { setSetup } = useActions(appActions)
  const { UpdateMetadata } = useActions(profileActions)
  const { GetRiskManagerAvailableTC } = useActions(riskManagerActions)

  const { userKycStatus, userKycLinkStatusRequestStatus } = useAppSelector(state => state.kyc)
  const { createdMasterApiKeyStatus, createdApiReadOnlyStatusInitial } = useAppSelector(state => state.apiKeys)

  const {
    metadata: { exchangeType, isCreatedMasterApiKeyStatusSuccess },
  } = useAppSelector(state => state.profile)

  const { isSetup } = useAppSelector(state => state.app)

  const isInit = searchParams.has(INIT_SEARCH_PARAM)

  useEffect(() => {
    if (userKycStatus === 'idle') GetUserKycTC()
  }, [userKycStatus, GetUserKycTC])

  useEffect(() => {
    if (isSetup) return
    if (userKycStatus !== 'succeeded') return
    if (isExchangeBinance(exchangeType as TExchangesAvailable) && userKycLinkStatusRequestStatus === 'succeeded') return
    if (isInit && createdMasterApiKeyStatus !== 'succeeded' && !isCreatedMasterApiKeyStatusSuccess) return
    setSetup(true)
  }, [isSetup, isInit, createdMasterApiKeyStatus, userKycStatus, exchangeType, userKycLinkStatusRequestStatus])

  useEffect(() => {
    if (isSetup) return
    if (!isInit) return
    CreateMasterApiKeysTC() // @see https://tigertrade.atlassian.net/browse/TC-870
  }, [isSetup, isInit])

  useEffect(() => {
    if (createdMasterApiKeyStatus === 'succeeded' && isCreatedMasterApiKeyStatusSuccess !== true) {
      UpdateMetadata(defineMetadata({ isCreatedMasterApiKeyStatusSuccess: true }))
    }
  }, [createdMasterApiKeyStatus, UpdateMetadata, isCreatedMasterApiKeyStatusSuccess])

  useEffect(() => {
    if (isInit && createdMasterApiKeyStatus === 'succeeded') {
      searchParams.delete(INIT_SEARCH_PARAM)
      setSearchParams(searchParams)

      if (storage.getReferralCode().date !== undefined && exchangeType) {
        navigate(urls.referrals)
        return
      }
    }
    if (!exchangeType && !window.location.href.includes(urls.chooseExchange)) {
      navigate(urls.chooseExchange)
    }
  }, [isInit, navigate, createdMasterApiKeyStatus, searchParams, setSearchParams, exchangeType])

  useEffect(() => {
    if (!isSetup) return
    GetRiskManagerAvailableTC()
  }, [isSetup])

  if ((createdMasterApiKeyStatus === 'failed' || userKycStatus === 'failed') && !isCreatedMasterApiKeyStatusSuccess) {
    return (
      <Loader.Overlay>
        <ErrorBase
          customHeader={t({ message: `Couldn’t load data`, id: 'core.notLoadData' })}
          isRefreshButtonVisible
          isSupportMessage
        />
      </Loader.Overlay>
    )
  }

  if (!isSetup) {
    return (
      <Loader.Overlay>
        <Loader.Content />
      </Loader.Overlay>
    )
  }

  return (
    <>
      {!isStatusFinal(createdApiReadOnlyStatusInitial) && (
        <Loader.Overlay>
          <Loader.Content />
        </Loader.Overlay>
      )}
      {children}
    </>
  )
}

export { SetupProvider }
