import { TCreateApiKeyPayloadUnion } from '@/backend/api/apiKeyAPI'
import { TExchangesAvailable } from '@/core/constants'
import { ApiKeyCreationDTO } from '@/backend/models/ApiKeyDTO'
import { BinanceApiWrapper } from './apiWrappers/binance'
import { OkxApiWrapper } from './apiWrappers/okx'
import { AxiosResponse } from 'axios'
import { IApiManager, IApiWrapper } from './types'
import {
  TCancelOpenOrderResponse,
  TCancelAllOpenOrdersPayload,
  TCancelAllOpenOrdersResponse,
  TClosePositionPayload,
  TClosePositionResponse,
  TCloseAllPositionsPayload,
  TCloseAllPositionsResponse,
  TCancelOpenOrderPayloadUnion,
  TSetPositionModePayloadDTO,
} from '@/backend/models/OverviewDTO'
import { TSetLeveragePayloadUnion, TSetLeverageResponse } from '@/redux/leverage/leverage.types'
import { BybitApiWrapper } from './apiWrappers/bybit'
import { TSetMarginPayloadUnion, TSetMarginResponse } from '@/backend/models/LeverageDTO'
import { TSellAssetPayloadUnion, TSellAssetResponseUnion } from '@/redux/overview/overview.types'

export class InternalApiClass implements IApiManager {
  protected exchangeSelected: TExchangesAvailable
  wrappersByExchange: Record<TExchangesAvailable, IApiWrapper> = {
    BINANCE_BROKER_FUTURE: BinanceApiWrapper,
    OKX_BROKER: OkxApiWrapper,
    BYBIT_BROKER: BybitApiWrapper,
    BINANCE_VIP: BinanceApiWrapper,
    OKX_AFFILIATE: OkxApiWrapper,
  }

  constructor(params: { exchange: TExchangesAvailable }) {
    const { exchange } = params
    this.exchangeSelected = exchange
  }

  setExchange(exchange: TExchangesAvailable) {
    this.exchangeSelected = exchange
  }

  get client() {
    return this.wrappersByExchange[this.exchangeSelected]
  }

  methods = {
    apiKey: {
      createApiKey: async (payload: TCreateApiKeyPayloadUnion): Promise<AxiosResponse<ApiKeyCreationDTO>> => {
        return await this.client.caller.apiKey.createApiKey(payload)
      },
    },
    overview: {
      cancelOpenOrder: async (
        payload: TCancelOpenOrderPayloadUnion
      ): Promise<AxiosResponse<TCancelOpenOrderResponse>> => {
        return await this.client.caller.overview.cancelOpenOrder(payload)
      },
      cancelAllOpenOrders: async (
        payload: TCancelAllOpenOrdersPayload
      ): Promise<AxiosResponse<TCancelAllOpenOrdersResponse>> => {
        return await this.client.caller.overview.cancelAllOpenOrders(payload)
      },
      closePosition: async (payload: TClosePositionPayload): Promise<AxiosResponse<TClosePositionResponse>> => {
        return await this.client.caller.overview.closePosition(payload)
      },
      closeAllPositions: async (
        payload: TCloseAllPositionsPayload
      ): Promise<AxiosResponse<TCloseAllPositionsResponse>> => {
        return await this.client.caller.overview.closeAllPositions(payload)
      },
      setPositionMode: async (payload: TSetPositionModePayloadDTO) => {
        return await this.client.caller.overview.setPositionMode(payload)
      },
      sellAsset: async (payload: TSellAssetPayloadUnion): Promise<AxiosResponse<TSellAssetResponseUnion>> => {
        return await this.client.caller.overview.sellAsset(payload)
      },
    },
    leverage: {
      setLeverage: async (payload: TSetLeveragePayloadUnion): Promise<AxiosResponse<TSetLeverageResponse>> => {
        return await this.client.caller.leverage.setLeverage(payload)
      },
      setMargin: async (payload: TSetMarginPayloadUnion): Promise<AxiosResponse<TSetMarginResponse>> => {
        return await this.client.caller.leverage.setMargin(payload)
      },
    },
  }
}

export const InternalApi = new InternalApiClass({
  exchange: 'BINANCE_BROKER_FUTURE',
})
