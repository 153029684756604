import { useAuth0 } from '@auth0/auth0-react'
import { FC, memo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '@/ui/kit'
import { HeaderUserButton } from '@/ui/atoms/HeaderUserButton'
import { HeaderLanguage } from '@/ui/molecules/HeaderLanguage'
import { HeaderKysStatus } from '@/ui/molecules/HeaderKysStatus'
import HeaderLinks from '@/ui/molecules/HeaderLinks'
import { useAppSelector, useMobileSizeDetect } from '@/utils'
import { SVG } from '@/assets/svg'
import s from './style.module.scss'
import { HeaderExchange } from '../HeaderExchange'
import { HeaderBurger } from '../HeaderBurger/HeaderBurger'
import clsx from 'clsx'
import { SystemNotifications } from '@/ui/molecules/SystemNotifications/SystemNotifications'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import useResize from '@/utils/hooks/useResize'
import { EXCHANGES_TYPE } from '@/core/constants'
import { urls } from '@/router/urls'

export interface HeaderProps {
  /** Use this flag to hide back button. @default = false */
  isBackButtonHidden?: boolean
  isBackgroundHidden?: boolean
  isTopMenuHidden?: boolean
}

const Header: FC<HeaderProps> = ({ isBackButtonHidden, isTopMenuHidden, isBackgroundHidden }) => {
  const [isMobile] = useMobileSizeDetect()
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth0()

  const {
    metadata: { redirectTo },
    vipAccess,
  } = useAppSelector(state => state.profile)

  const { exchangeType } = useExchageType()

  const isTopMenuVisible = !isTopMenuHidden && isAuthenticated
  const isBackHidden = isBackButtonHidden || window?.history?.length < 2 || !isAuthenticated

  const { rootWidth } = useResize()

  const url = exchangeType === EXCHANGES_TYPE.OKX_AFFILIATE ? urls.okxAffiliate : redirectTo

  if (isMobile) {
    return (
      <header
        className={clsx(s.mobile, s.header, {
          [s.headerMobileBackground]: !isBackgroundHidden,
          [s.withoutBg]: isBackgroundHidden,
        })}
      >
        <SystemNotifications />

        <div className={s.wrapper}>
          {isBackHidden ? (
            <HeaderBurger isNavigationVisible={isTopMenuVisible} />
          ) : (
            <Button.Primary
              onClick={() => navigate(-1)}
              className={s.arrowBackWrap}
              leftIcon={<SVG.Arrows.ArrowLeft className={s.arrow} />}
            />
          )}

          <Link to={url} className={s.link}>
            <SVG.OtherIcons.Logo />
          </Link>
        </div>
      </header>
    )
  }

  return (
    <header className={s.header}>
      <div
        className={clsx(s.headerDesktopInner, {
          [s.withoutBg]: isBackgroundHidden,
        })}
      >
        <SystemNotifications />

        <div className={s.headerDesktopContent}>
          {!isBackHidden && (
            <Button.Primary
              onClick={() => navigate(-1)}
              className={s.arrowBackWrap}
              leftIcon={<SVG.Arrows.ArrowLeft className={s.arrow} />}
            />
          )}
          <div className={s.left}>
            <Link to={url}>
              <SVG.OtherIcons.Logo />
            </Link>

            {isTopMenuVisible && (
              <>
                <HeaderExchange />
                <HeaderLinks isVipAccess={exchangeType === 'BINANCE_VIP' && vipAccess} />
              </>
            )}
          </div>
          {isAuthenticated && (
            <div className={s.right}>
              <HeaderLanguage />
              <HeaderKysStatus />
              <HeaderUserButton />
            </div>
          )}
        </div>

        {!isBackgroundHidden && (
          <div className={s.headerBackgroundWrap}>
            <div
              className={s.headerBackground}
              style={{
                transform: !!rootWidth ? `translateX(${-rootWidth / 2}px)` : '',
              }}
            ></div>
          </div>
        )}
      </div>
    </header>
  )
}

export default memo(Header)
