import { SVG } from '@/assets/svg'
import { Hint, usePopperRef } from '@/ui/kit'
import { useFlag, useMobileSizeDetect } from '@/utils'

import s from './style.module.scss'
import { FC } from 'react'

type Props = {
  text: string
}

export const DangerWithTooltip: FC<Props> = ({ text }) => {
  const [ref, setRef] = usePopperRef<HTMLDivElement>()
  const [isVisible, , enableHint, disableHint] = useFlag()
  const [isMobile] = useMobileSizeDetect()

  return (
    <div ref={setRef} onMouseOver={enableHint} onFocus={enableHint} onMouseOut={disableHint} onBlur={disableHint}>
      <SVG.Additional.Danger />
      {isVisible && !isMobile && (
        <Hint
          targetRef={ref}
          hitSizeSmall
          placement="bottom"
          text={<div className={s.dangerText}>{text}</div>}
          className={s.hintWrapper}
        />
      )}
    </div>
  )
}
