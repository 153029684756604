import { SVG } from '@/assets/svg'
import { EPartnerAchievements, EPartners } from '@/redux/partner/partner.types'
import { urls } from '@/router/urls'
import { ReactElement } from 'react'

export const ACHIEVEMENTS_CONTENT: Record<
  EPartnerAchievements,
  {
    icon: ReactElement
    link: string
  }
> = {
  kycPassed: {
    icon: <SVG.Achievements.Kyc />,
    link: urls.kyc,
  },
  depositMade: {
    icon: <SVG.Achievements.Deposit />,
    link: urls.transactions,
  },
  apiKeyCreated: {
    icon: <SVG.Achievements.Key />,
    link: urls.api,
  },
  tradeMade: {
    icon: <SVG.Achievements.Deal />,
    link: urls.orders,
  },
  tradingVolume10K: {
    icon: <SVG.Achievements.Volume />,
    link: urls.orders,
  },
}

export const PARTNERS_CONTENT: Record<EPartners, { icon: ReactElement }> = {
  proscalping: {
    icon: <SVG.Partner.Proscalping />,
  },
}

export const PARTNER_TRADING_VOLUME = 10000
