import { EPartnerAchievements, EPartners } from '@/redux/partner/partner.types'
import { t } from '@lingui/macro'

export const getPartnerTranslations = () => {
  return {
    brands: {
      [EPartners.proscalping]: {
        title: t({
          id: 'partner.brands.proscalping',
        }),
      },
    },
    tabs: {
      video: t({
        id: 'partner.tab.video',
      }),
      achievement: t({
        id: 'partner.tab.achievement',
      }),
      different: t({
        id: 'partner.tab.different',
      }),
      study: t({
        id: 'partner.tab.study',
      }),
    },
    achievements: {
      about: t({
        id: 'partner.achievements.about',
      }),
    },
    achievementsVocabulary: {
      [EPartnerAchievements.kycPassed]: {
        title: t({
          id: 'partner.achievement.kycPassed.title',
        }),
        titleShort: t({
          id: 'partner.achievement.kycPassed.titleShort',
        }),
        description: t({
          id: 'partner.achievement.kycPassed.description',
        }),
        todo: t({
          id: 'partner.achievement.kycPassed.todo',
        }),
        disclaimer: '',
      },
      [EPartnerAchievements.depositMade]: {
        title: t({
          id: 'partner.achievement.depositMade.title',
        }),
        titleShort: t({
          id: 'partner.achievement.depositMade.titleShort',
        }),
        description: t({
          id: 'partner.achievement.depositMade.description',
        }),
        todo: t({
          id: 'partner.achievement.depositMade.todo',
        }),
        disclaimer: '',
      },
      [EPartnerAchievements.apiKeyCreated]: {
        title: t({
          id: 'partner.achievement.apiKeyCreated.title',
        }),
        titleShort: t({
          id: 'partner.achievement.apiKeyCreated.titleShort',
        }),
        description: t({
          id: 'partner.achievement.apiKeyCreated.description',
        }),
        todo: t({
          id: 'partner.achievement.apiKeyCreated.todo',
        }),
        disclaimer: '',
      },
      [EPartnerAchievements.tradeMade]: {
        title: t({
          id: 'partner.achievement.tradeMade.title',
        }),
        titleShort: t({
          id: 'partner.achievement.tradeMade.titleShort',
        }),
        description: t({
          id: 'partner.achievement.tradeMade.description',
        }),
        todo: t({
          id: 'partner.achievement.tradeMade.todo',
        }),
        disclaimer: t({
          id: 'partner.achievement.tradeMade.disclaimer',
        }),
      },
      [EPartnerAchievements.tradingVolume10K]: {
        title: t({
          id: 'partner.achievement.tradeVolume10K.title',
        }),
        titleShort: t({
          id: 'partner.achievement.tradeVolume10K.titleShort',
        }),
        description: t({
          id: 'partner.achievement.tradeVolume10K.description',
        }),
        todo: t({
          id: 'partner.achievement.tradeVolume10K.todo',
        }),
        disclaimer: t({
          id: 'partner.achievement.tradeVolume10K.disclaimer',
        }),
      },
    },
    widget: {
      title: t({
        id: 'partner.widget.title',
      }),
      perform: {
        msg1: t({
          id: 'partner.widget.perform.msg1',
        }),
        msg2: t({
          id: 'partner.widget.perform.msg2',
        }),
      },
      recieve: t({
        id: 'partner.widget.recieve',
      }),
    },
    study: {
      empty: {
        title: t({
          id: 'partner.study.empty.title',
        }),
        subtitle: t({
          id: 'partner.study.empty.subtitle',
        }),
      },
    },
    claim: {
      title: t({
        id: 'partner.claim.title',
      }),
      subtitle: t({
        id: 'partner.claim.subtitle',
      }),
      close: t({ id: 'core.close', message: `Close` }),
      widgetAfter: t({
        id: 'partner.claim.widgetAfter',
      }),
    },
  }
}
