import { FC, useEffect, useMemo, useState } from 'react'
import { EChartsType, init } from 'echarts'
import { ServicesWidget } from '@/utils/types/contracts.types'
import { useConstant, useEventEffect } from '@/utils/hooks'
import { v1 } from 'uuid'
import { useLingui } from '@lingui/react'
import { TLanguage } from '@/core/constants'
import { WidgetRowServerData } from './wrapper/settings'
import style from './style.module.scss'
import { applyZoom } from './helpers/applyZoom'
import { downloadEChartScrenshot } from './helpers/downloadEChartScreenshot'
import { SVG } from '@/assets/svg'
import { IconButton } from './IconButton/IconButton'
import clsx from 'clsx'
import './locales'
import { LegendSelectChangeEvent } from './wrapper/generator'
import { EChartsGenerator } from './wrapper/generator/generator'
import { isEmpty, isNumber } from 'lodash-es'
import { t } from '@lingui/macro'
import { LegendWithState, ScrollableLegnds } from './ScrollableLegnds/ScrollableLegnds'

interface EChartEmptyProps {
  className?: string
}

const EChartsEmpty: FC<EChartEmptyProps> = ({ className }) => (
  <div className={clsx(style.container, style.center, className)}>
    <div className={style.noData}>{t({ id: 'trades.dashboard.noData' })}</div>
  </div>
)

interface EChartsViewProps {
  widget: ServicesWidget
  serverData?: WidgetRowServerData[] | null
  className?: string
}

const EChartsView: FC<EChartsViewProps> = ({ widget, serverData, className }) => {
  const { i18n } = useLingui()
  const [legends, setLegends] = useState<LegendWithState[]>([])

  const meta = useConstant(() => ({
    key: v1(),
    generator: null as EChartsGenerator | null,
    echart: null as EChartsType | null,
    currentZoomStep: 0,
    currentZoomLeft: 0,
    currentZoomRight: 100,
    zoomStepZize: 8,
    zoomMaxStep: 6,
  }))

  const onLegendSelectChanged = useEventEffect((params: LegendSelectChangeEvent): void => {
    setLegends(prev => {
      for (const legend of prev) {
        if (legend.name !== params.name) continue
        if (legend.selected === params.selected[params.name]) break

        legend.selected = params.selected[params.name]
        return [...prev]
      }

      return prev
    })
  })

  useEffect(() => {
    let instance: EChartsType | null = null

    meta.generator = new EChartsGenerator({
      widget,
      data: serverData ?? null,
      ref: () => {
        if (!instance) {
          meta.echart = instance = init(document.getElementById(meta.key), 'dark', {
            locale: i18n.locale.toUpperCase(),
          })

          instance.on('dataZoom', (params: any) => {
            let start: number
            let end: number

            if (params.batch?.length) {
              const batch = params.batch[0]

              start = batch.start
              end = batch.end
            } else {
              start = params.start
              end = params.end
            }

            if (isNumber(start) && isNumber(end)) {
              meta.currentZoomLeft = start
              meta.currentZoomRight = end
              return
            }
          })
        }

        return instance
      },
      config: {
        darkTheme: true,
        theme: 'default',
        locale: i18n.locale as TLanguage,
        disabledAnimation: false,
      },
      onLegendSelectChanged: onLegendSelectChanged,
      onLegendsDefined: legends =>
        setLegends(
          legends.map(legend => ({
            ...legend,
            selected: true,
          }))
        ),
    }).init()

    return () => {
      if (instance) {
        instance.dispose()
      }
    }
  }, [meta.key, meta, i18n.locale, onLegendSelectChanged])

  // Zoom "+"
  const zoomIn = () => {
    if (!meta.echart || meta.currentZoomStep >= meta.zoomMaxStep) return

    meta.currentZoomStep += 1

    applyZoom({
      type: 'zoomIn',
      echart: meta.echart,
      stepSize: meta.zoomStepZize,
      currentleftPosition: meta.currentZoomLeft,
      currentRightPosition: meta.currentZoomRight,
    })
  }

  // Zoom "-"
  const zoomOut = () => {
    if (!meta.echart || meta.currentZoomStep === 0) return

    meta.currentZoomStep -= 1

    applyZoom({
      type: 'zoomOut',
      echart: meta.echart,
      stepSize: meta.zoomStepZize,
      currentleftPosition: meta.currentZoomLeft,
      currentRightPosition: meta.currentZoomRight,
    })
  }

  // Zoom "reset"
  const zoomReset = () => {
    if (!meta.echart || meta.currentZoomStep === 0) return

    meta.currentZoomStep = 0

    applyZoom({
      type: 'reset',
      echart: meta.echart,
    })
  }

  // Download EChart screenshot
  const downloadImage = () => {
    if (!meta.generator) return
    downloadEChartScrenshot(meta.generator.GetScreenshot())
  }

  // Download EChart CSV
  const downloadCSV = () => {
    if (!meta.generator) return
    meta.generator.exportCSV()
  }

  // Legend Toggler
  const onLegendToggle = (legendName: string) => {
    if (!meta.echart) return

    meta.echart.dispatchAction({
      type: 'legendToggleSelect',
      name: legendName,
    })
  }

  return (
    <div className={clsx(style.container, !!legends.length && style.withLegends, className)}>
      <div className={style.header}>
        <div className={style.title}>{widget.title}</div>

        <div className={clsx(style.flexAlignCenter, style.toolbar)}>
          <div className={style.flexAlignCenter}>
            <IconButton className={style.iconButton} onClick={zoomIn} leftIcon={<SVG.Chart.ZoomIn size={14} />} />
            <IconButton className={style.iconButton} onClick={zoomOut} leftIcon={<SVG.Chart.ZoomOut size={14} />} />
            <IconButton className={style.iconButton} onClick={zoomReset} leftIcon={<SVG.Chart.ZoomReset size={14} />} />
          </div>

          <div className={style.separator} />

          <div className={style.flexAlignCenter}>
            <IconButton
              className={style.iconButton}
              onClick={downloadImage}
              leftIcon={<SVG.Chart.ScreenShot size={14} />}
            />
            <IconButton className={style.iconButton} onClick={downloadCSV} leftIcon={<SVG.Arrows.LongArrowDown />} />
          </div>
        </div>
      </div>

      <div id={meta.key} className={style.chart} />

      {!!legends.length && <ScrollableLegnds legends={legends} onLegendToggle={onLegendToggle} />}
    </div>
  )
}

interface EChartsProps extends EChartsViewProps, EChartEmptyProps {}

export const ECharts: FC<EChartsProps> = ({ serverData, widget, className }) => {
  if (serverData && !isEmpty(serverData)) {
    return <EChartsView widget={widget} serverData={serverData} className={className} />
  }

  return <EChartsEmpty className={className} />
}
