import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { transactionsAsyncActions } from '@/redux/transactions/transactions.actions'
import { TransactionsTabBar } from '@/ui/molecules'
import { DepositTransaction, TransferTransaction, WithdrawTransaction } from '@/ui/organisms'
import { useActions, useAppSelector } from '@/utils'
import { useGoogleAuthCode, useGoogleAuthSettings, useMobileSizeDetect } from '@/utils/hooks'
import { PopupSequence } from '@/ui/organisms/PopupSequence'
import { t } from '@lingui/macro'
import style from './style.module.scss'
import { Loader } from '@/ui/molecules/Loader'
import { addressActions } from '@/redux/address/address.slice'
import clsx from 'clsx'
import { isExchangeBinance } from '@/utils/lib/exchange'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'

export type TransactionsTabType = {
  id: number
  text: string
  action: () => void
  type?: TabsType
  visible: boolean
}
export type TabsType = 'deposit' | 'transfer' | 'withdraw'

export const Transactions = memo(() => {
  const [isMobile] = useMobileSizeDetect()
  const googleAuthSettings = useGoogleAuthSettings()
  const googleAuthCode = useGoogleAuthCode()
  const { userKYC } = useAppSelector(state => state.kyc)
  const { assetsForTransactions, coinBalance } = useAppSelector(state => state.transactions)
  const { status: withDrawStatus } = useAppSelector(state => state.transactions.withdrawAddresses)
  const { whitelistGetStatus } = useAppSelector(state => state.address)

  const { exchangeType } = useAppSelector(state => state.overview)

  const isPageLoading = useMemo(() => {
    return (
      (isExchangeBinance(exchangeType) && assetsForTransactions.status !== 'succeeded') ||
      coinBalance.status !== 'succeeded' ||
      withDrawStatus !== 'succeeded' ||
      whitelistGetStatus !== 'succeeded'
    )
  }, [assetsForTransactions.status, coinBalance.status, withDrawStatus, whitelistGetStatus, exchangeType])

  const { isDepositEnabled } = useExchangesDisabled()

  const TAB_BAR_TABS: TransactionsTabType[] = useMemo(() => {
    return [
      {
        id: 1,
        text: t({ message: `Transfer`, id: 'core.transfer' }),
        type: 'transfer',
        action: () => void 0,
        visible: isExchangeBinance(exchangeType),
      },
      {
        id: 2,
        text: t({ message: `Deposit`, id: 'core.deposit' }),
        type: 'deposit',
        action: () => void 0,
        visible: isDepositEnabled,
      },
      {
        id: 3,
        text: t({ message: `Withdrawal`, id: 'core.withdrawal' }),
        type: 'withdraw',
        action: () => void 0,
        visible: true,
      },
    ]
  }, [exchangeType, isDepositEnabled])

  const [activeRightBarTab, setActiveRightBarTab] = useState<TabsType | string>(
    TAB_BAR_TABS[0].type || isExchangeBinance(exchangeType) ? 'transfer' : 'deposit'
  )
  const { GetAssetsForWithdrawAndDepositTC, GetAssetsForTransactionsTC, GetCoinsBalanceTC, GetWithdrawAddressTC } =
    useActions(transactionsAsyncActions)
  const { getWhitelistTC } = useActions(addressActions)

  const activeRightBarTabHandler = useCallback((type: TabsType) => {
    localStorage.setItem('transactionType', type)
    setActiveRightBarTab(type)
  }, [])

  useEffect(() => {
    const tabType = localStorage.getItem('transactionType')
    const tabTypeValid = isExchangeBinance(exchangeType) ? tabType : tabType === 'transfer' ? 'deposit' : tabType
    setActiveRightBarTab(tabTypeValid || '')
  }, [])

  useEffect(() => {
    GetAssetsForWithdrawAndDepositTC()
    GetCoinsBalanceTC()
    getWhitelistTC()
    GetWithdrawAddressTC()
  }, [
    GetAssetsForTransactionsTC,
    GetAssetsForWithdrawAndDepositTC,
    GetCoinsBalanceTC,
    GetWithdrawAddressTC,
    getWhitelistTC,
  ])

  useEffect(() => {
    if (isExchangeBinance(exchangeType)) GetAssetsForTransactionsTC()
  }, [exchangeType])

  return (
    <>
      <PopupSequence
        googleAuthCode={googleAuthCode}
        googleAuthSettings={googleAuthSettings}
        allowedPopups={[
          'kyc_unverified',
          'kyc_in_progress_1',
          'kyc_in_progress_2',
          'kyc_failed',
          'kyc_success',
          'twoFA',
          'exchange_init',
        ]}
      />

      <div className={clsx(style.transactions, isMobile && style.mobileTransactions)}>
        <div className={clsx(style.content, isMobile && style.mobileContent)}>
          <TransactionsTabBar
            activeRightBarTab={activeRightBarTab}
            activeRightBarTabHandler={activeRightBarTabHandler}
            tabs={TAB_BAR_TABS}
          />
          {isPageLoading && <Loader.Content />}

          {!isPageLoading && (
            <>
              {activeRightBarTab === 'transfer' && <TransferTransaction />}
              {activeRightBarTab === 'deposit' && <DepositTransaction tier={userKYC.kycTier} />}
              {activeRightBarTab === 'withdraw' && <WithdrawTransaction tier={userKYC.kycTier} />}
            </>
          )}
        </div>
      </div>
    </>
  )
})
