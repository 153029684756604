import { SVG } from '@/assets/svg'
import { profileActions } from '@/redux/profile/profile.slice'
import { Button, Toggle } from '@/ui/kit'
import { Popup } from '@/ui/molecules'
import { GoogleAuthenticatorForm } from '@/ui/organisms/GoogleAuthenticatorForm'
import { Popup2FAWithIntro } from '@/ui/organisms/Popup2FAWithIntro'
import { useActions, useAppSelector, useGoogleAuthCode, useGoogleAuthSettings, useToggle } from '@/utils/hooks'
import { t } from '@lingui/macro'
import { FC, useEffect } from 'react'
import style from '../../style.module.scss'

const TwoFA: FC = () => {
  const googleAuthSettings = useGoogleAuthSettings()
  const googleAuthCode = useGoogleAuthCode()
  const [isOpen, toggle] = useToggle()

  const { loginTwoFa, errors, loginTwoFaToggleStatus } = useAppSelector(state => state.profile)

  const [twoFAModalIsOpen, toggleTwoFAModal] = useToggle()

  const { LoginWith2fa, setErrors, clearLoginTwoFaToggleStatus } = useActions(profileActions)

  useEffect(() => {
    if (loginTwoFaToggleStatus === 'succeeded') {
      toggleTwoFAModal()
    }
    return () => {
      clearLoginTwoFaToggleStatus()
    }
  }, [clearLoginTwoFaToggleStatus, loginTwoFaToggleStatus, toggleTwoFAModal])

  const handleLoginWith2fa = async (value: string) => {
    LoginWith2fa({ codes: { google: value, sms: '' }, enabled: !loginTwoFa })
  }

  return (
    <>
      <section className={style.section}>
        <h6 className={style.sectionHeading}>{t({ id: 'profile.2fa', message: `Two-factor authentication (2FA)` })}</h6>

        <div className={style.setting}>
          <SVG.OtherIcons.Google className={style.sectionIcon} />
          <div>
            <div className={style.settingTitle}>
              {t({ id: 'profile.googleAuth', message: `Google Authentication` })}
            </div>
            <div className={style.settingStatus}>
              {googleAuthSettings.isLoaded && googleAuthSettings.isAccepted ? (
                <>
                  <SVG.Status.Completed className={style.completedIcon} />
                  {t({ id: 'core.linked', message: `Linked` })}
                </>
              ) : null}
              {googleAuthSettings.isLoaded && !googleAuthSettings.isAccepted ? (
                <>
                  <SVG.Status.Failed className={style.crossIcon} />
                  {t({ id: 'core.notLinked', message: `Not linked` })}
                </>
              ) : null}
            </div>
          </div>
          <div className={style.settingActions}>
            {googleAuthSettings.isLoaded && !googleAuthSettings.isAccepted ? (
              <Button.Primary label={t({ id: 'core.button.enable', message: `Enable` })} onClick={toggle} />
            ) : null}
          </div>
        </div>

        {googleAuthSettings.isLoaded && googleAuthSettings.isAccepted && (
          <div className={style.setting}>
            <SVG.OtherIcons.TwoFA className={style.sectionIcon} />
            <div>
              <div className={style.settingTitle}>
                {t({
                  id: 'profile.use2FAToLogin',
                  comment: 'Use 2FA to login',
                })}
              </div>
              <div className={style.settingStatus}>
                {t({
                  id: 'profile.2faToLoginDescription',
                  comment:
                    'When you sign in, you’ll be required to use the security code provided by Google Authenticator.',
                })}
              </div>
            </div>

            <div className={style.settingActions}>
              {googleAuthSettings.isLoaded && !googleAuthSettings.isAccepted ? (
                <Button.Primary label={t({ id: 'core.button.enable', comment: 'Enable' })} onClick={toggle} />
              ) : null}
              <div className={style.settingActions}>
                <Toggle active={loginTwoFa} onChange={toggleTwoFAModal} />
              </div>
            </div>
          </div>
        )}
      </section>

      <Popup2FAWithIntro
        isOpen={isOpen}
        isIgnoreIntro
        header={
          <>
            {t({ id: 'profile.google2faTitle1', comment: 'Enable Google' })}
            <br />
            {t({ id: 'profile.google2faTitle2', comment: 'Authenticator' })}
          </>
        }
        googleAuthSettings={googleAuthSettings}
        googleAuthCode={googleAuthCode}
        onSuccess={toggle}
        onClose={toggle}
      />

      <Popup isOpen={twoFAModalIsOpen}>
        <GoogleAuthenticatorForm
          onCancel={toggleTwoFAModal}
          onSubmit={handleLoginWith2fa}
          errors={errors}
          setErrors={setErrors}
          isSubmitDisabled={loginTwoFaToggleStatus === 'loading'}
        />
      </Popup>
    </>
  )
}

export { TwoFA }
