import { useMemo } from 'react'
import { useAppSelector } from '@/utils'
import { isExchangeBinance, isExchangeOkx } from '@/utils/lib/exchange'
import { OKX_DISABLE_DATE } from '@/core/constants'

//TODO: move to feature flag manager
export interface FeaturesAvailability {
  isSellAssetEnabled: boolean
  isDepositEnabled: boolean
  isLeverageEnabled: boolean
  isAccountSettingsEnabled: boolean
  isApiKeyEnabled: boolean
  isCommissionEnabled: boolean
  isFunctionalNavEnabled: boolean
}

export interface UseExchangesDisabledResult extends FeaturesAvailability {
  isBinanceDisabled: boolean
  isOkxDisabled: boolean
  isBybybitDisabled: boolean
  isCurrentExchangeDisabled: boolean
  isCurrentExchangeDisabledInit: boolean
}

export const useExchangesDisabled = (): UseExchangesDisabledResult => {
  const { userKycLinkState, userKycLinkStatusRequestStatus } = useAppSelector(state => state.kyc)
  const { exchangeType } = useAppSelector(state => state.app)
  const {
    metadata: { firstSesstionTrackedTimestamp, isFirstSessionTracked },
  } = useAppSelector(state => state.profile)

  const isCurrentExchangeDisabled = useMemo(() => {
    if (isExchangeOkx(exchangeType)) return new Date() >= OKX_DISABLE_DATE

    if (!isExchangeBinance(exchangeType)) return false
    if (userKycLinkStatusRequestStatus !== 'succeeded') return true

    return (
      userKycLinkState.status === 'FINAL_REJECT' ||
      userKycLinkState.status === 'REJECT' ||
      userKycLinkState.status === 'INIT'
    )
  }, [exchangeType, userKycLinkState.status])

  const isOkxDisabled = useMemo(() => {
    return new Date() >= OKX_DISABLE_DATE
  }, [exchangeType])

  const isCurrentExchangeDisabledInit = useMemo(() => {
    return (
      isExchangeOkx(exchangeType) &&
      (!isFirstSessionTracked || Number(firstSesstionTrackedTimestamp) >= OKX_DISABLE_DATE.getTime())
    )
  }, [exchangeType, firstSesstionTrackedTimestamp, isFirstSessionTracked])

  const disabledFeatures = useMemo(() => {
    return {
      isSellAssetEnabled: !isCurrentExchangeDisabled || isExchangeOkx(exchangeType),
      isDepositEnabled: !isCurrentExchangeDisabled,
      isLeverageEnabled: !isCurrentExchangeDisabled,
      isAccountSettingsEnabled: !isCurrentExchangeDisabled,
      isApiKeyEnabled: !isCurrentExchangeDisabled,
      isCommissionEnabled: !(isExchangeOkx(exchangeType) && isCurrentExchangeDisabled),
      isFunctionalNavEnabled: !(
        isExchangeOkx(exchangeType) && Number(firstSesstionTrackedTimestamp) >= OKX_DISABLE_DATE.getTime()
      ),
    }
  }, [isCurrentExchangeDisabled, firstSesstionTrackedTimestamp, exchangeType])

  return {
    isOkxDisabled,
    isCurrentExchangeDisabled,
    isBinanceDisabled: false,
    isBybybitDisabled: false,
    isCurrentExchangeDisabledInit,
    ...disabledFeatures,
  }
}

export const useExchangeAlerts = (): { isShowOkxAlert: boolean } => {
  const isShowOkxAlert = true

  return {
    isShowOkxAlert,
  }
}
