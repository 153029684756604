import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'
import clsx from 'clsx'
import { memo, useMemo, useState } from 'react'
import { SVG } from '@/assets/svg'
import { KYCTier } from '@/backend/models/KycDTO'
import { Button, InteractiveModal } from '@/ui/kit'
import { LevelUpgradeButton } from '@/ui/molecules/LevelUpgradeButton'
import { useAppSelector } from '@/utils'
import { getPrecentByTotal } from '@/utils/getPrecentByTotal'
import { useKYC, useMobileSizeDetect } from '@/utils/hooks'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import style from './style.module.scss'

export type PropsType = {
  isDeposit?: boolean
  tier: KYCTier
}

export const WithdrawUpPopup = memo<PropsType>(({ isDeposit }) => {
  const [isMobile] = useMobileSizeDetect()
  const { availableLimits } = useAppSelector(state => state.kyc)
  const { handleKycUpgrade, kycNextTier, nextTierLimits, currentTierLimits } = useKYC()

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const { selectedLimitType, availableLimit, textType, customAvailableLimit } = useMemo<{
    selectedLimitType: string
    availableLimit: string
    textType: 'deposit' | 'withdraw'
    customAvailableLimit: string
  }>(() => {
    const type = isDeposit ? 'depositLimit' : 'withdrawalLimit'
    const customSelectedLimitType = isDeposit
      ? (currentTierLimits?.deposit?.formatted as string)
      : (currentTierLimits?.withdraw?.formatted as string)

    return {
      selectedLimitType: customSelectedLimitType,
      availableLimit: availableLimits[type].availableFormatted,
      textType: isDeposit ? 'deposit' : 'withdraw',
      customAvailableLimit: currentTierLimits?.deposit?.formatted as string,
    }
  }, [availableLimits, isDeposit, currentTierLimits])

  const avaliablePrecent = useMemo(() => {
    let precent = 100
    if (isDeposit) {
      precent = Math.round(
        getPrecentByTotal(
          availableLimits.depositLimit.limit?.value as number,
          availableLimits.depositLimit.available as number
        )
      )
    } else {
      precent = Math.round(
        getPrecentByTotal(
          availableLimits.withdrawalLimit.limit?.value as number,
          availableLimits.withdrawalLimit.available as number
        )
      )
    }
    return `${precent}%`
  }, [
    availableLimits.depositLimit.available,
    availableLimits.depositLimit.limit?.value,
    availableLimits.withdrawalLimit.available,
    availableLimits.withdrawalLimit.limit?.value,
    isDeposit,
  ])

  const texts = useMemo(() => {
    return {
      operationText: {
        verbs: {
          deposit: t({
            id: `core.operation.depositVerb`,
            message: 'deposit',
          }),
          withdraw: t({
            id: `core.operation.withdrawVerb`,
            message: 'withdraw',
          }),
        },
        nouns: {
          deposit: t({
            id: `core.operation.depositNoun`,
            message: 'deposit',
          }),
          withdraw: t({
            id: `core.operation.withdrawNoun`,
            message: 'withdraw',
          }),
        },
      },
    }
  }, [])

  return (
    <div className={clsx(isMobile && style.mobile, style.wrapper)}>
      {!isPopupOpen || isMobile ? (
        <div
          className={clsx(style.smallPopup, style.smallPopupUnlimited)}
          onClick={isMobile ? () => setIsPopupOpen(true) : undefined}
          data-testid={DataTestIds.LimitWidget}
        >
          <div className={style.text}>
            <Trans
              id="core.operation.tooltipVisible.msg"
              values={{
                operation: texts.operationText.verbs[textType],
                amount: availableLimit,
              }}
              components={{
                amount: <span className={style.amount} />,
              }}
            />
          </div>

          <Button.Primary
            onClick={isMobile ? undefined : () => setIsPopupOpen(true)}
            leftIcon={<SVG.OtherIcons.Question />}
            // classNameWrap={style.buttonWrap}
            className={style.buttonWithIcon}
            dataTestId={DataTestIds.LimitWidgetButton}
          />
        </div>
      ) : (
        <div className={style.bigPopup} data-testid={DataTestIds.LimitFullPopup}>
          <Button.Primary
            onClick={() => setIsPopupOpen(false)}
            leftIcon={<SVG.Additional.Close />}
            className={style.buttonClose}
            dataTestId={DataTestIds.LimitPopupCloseButton}
          />

          <div className={style.title} data-testid={DataTestIds.LimitPopupTitle}>
            <Trans
              id="core.operation.tooltipInvisible.title"
              values={{
                availableLimit,
              }}
            />
          </div>

          {kycNextTier && (
            <div className={style.progressBars} data-testid={DataTestIds.LimitPopupProgressBar}>
              <div className={style.pbWrap}>
                <div className={style.progressBar}>
                  <div style={{ width: avaliablePrecent }} className={style.barLine} />
                </div>
                <div className={style.count}>
                  <Trans
                    id="core.transaction.fromSmall"
                    values={{
                      selectedLimitType,
                    }}
                    components={{
                      noSpace: <>&nbsp;</>,
                    }}
                  />
                </div>
              </div>
              <div className={style.pbWrap}>
                <div className={style.progressBar}>
                  <div style={{ width: 0 }} className={style.barLine} />
                </div>
                <div className={clsx(style.count, style.disabledCount)}>
                  <SVG.OtherIcons.Lock />
                  <span className={style._nowrap}>{nextTierLimits?.[textType].formatted}</span>
                </div>
              </div>
            </div>
          )}

          <div className={style.list}>
            <div className={style.listItem} data-testid={DataTestIds.LimitPopupListDescriptions}>
              <div className={style.icon}>
                <SVG.OtherIcons.Okey />
              </div>

              <div className={style.text}>
                <Trans
                  id="core.operation.tooltipInvisible.current.msg"
                  values={{
                    operation: texts.operationText.verbs[textType],
                    count: customAvailableLimit,
                  }}
                  components={{
                    count: <span className={style.count} />,
                  }}
                />

                {!isDeposit && (
                  <>
                    {' '}
                    <Trans id="core.operation.tooltipInvisible.current.msg-2" />
                  </>
                )}
              </div>
            </div>

            <div className={style.listItem} data-testid={DataTestIds.LimitPopupListDescriptions}>
              <div className={style.icon}>
                <SVG.OtherIcons.Eye />
              </div>
              <div className={style.text}>
                <Trans
                  id="core.operation.tooltipInvisible.sure"
                  values={{
                    operation: texts.operationText.nouns[textType],
                  }}
                />
              </div>
            </div>

            {kycNextTier && (
              <div className={style.listItem} data-testid={DataTestIds.LimitPopupListDescriptions}>
                <div className={style.icon}>
                  <SVG.OtherIcons.Unlock />
                </div>
                <div className={style.text}>
                  <Trans
                    id="core.operation.tooltipInvisible.upgrade"
                    values={{
                      operation: texts.operationText.verbs[textType],
                      count: nextTierLimits?.[textType].formatted,
                    }}
                    components={{
                      count: <span className={style.count} />,
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <LevelUpgradeButton
            handleUpgrade={handleKycUpgrade}
            className={style.verificationButton}
            classNameWrap={style.verificationButtonWrap}
          />
        </div>
      )}

      {isMobile && isPopupOpen && (
        <InteractiveModal
          className={clsx(style.mobile, style.mobileModal)}
          onClose={() => setIsPopupOpen(false)}
          isOpen
        >
          <Button.Primary
            onClick={() => setIsPopupOpen(false)}
            leftIcon={<SVG.Additional.Close />}
            className={style.buttonClose}
            dataTestId={DataTestIds.LimitPopupCloseButton}
          />

          <div className={clsx(style.mobile, style.wrapper)}>
            <div className={style.bigPopup}>
              <div className={style.title} data-testid={DataTestIds.LimitPopupTitle}>
                <Trans
                  id="core.operation.tooltipInvisible.title"
                  values={{
                    availableLimit,
                  }}
                />
              </div>

              {kycNextTier && (
                <div className={style.progressBars} data-testid={DataTestIds.LimitPopupProgressBar}>
                  <div className={style.pbWrap}>
                    <div className={style.progressBar}>
                      <div style={{ width: avaliablePrecent }} className={style.barLine} />
                    </div>
                    <div className={style.count}>
                      <Trans
                        id="core.transaction.fromSmall"
                        values={{
                          selectedLimitType,
                        }}
                        components={{
                          noSpace: <>&nbsp;</>,
                        }}
                      />
                    </div>
                  </div>
                  <div className={style.pbWrap}>
                    <div className={style.progressBar}>
                      <div style={{ width: 0 }} className={style.barLine} />
                    </div>
                    <div className={clsx(style.count, style.disabledCount)}>
                      <SVG.OtherIcons.Lock />
                      <span className={style._nowrap}>{nextTierLimits?.[textType].formatted}</span>
                    </div>
                  </div>
                </div>
              )}

              <div className={style.list}>
                <div className={style.listItem} data-testid={DataTestIds.LimitPopupListDescriptions}>
                  <div className={style.icon}>
                    <SVG.OtherIcons.Okey />
                  </div>

                  <div className={style.text}>
                    <Trans
                      id="core.operation.tooltipInvisible.current.msg"
                      values={{
                        operation: texts.operationText.verbs[textType],
                        count: availableLimit,
                      }}
                      components={{
                        count: <span className={style.count} />,
                      }}
                    />

                    {!isDeposit && (
                      <>
                        {' '}
                        <Trans id="core.operation.tooltipInvisible.current.msg-2" />
                      </>
                    )}
                  </div>
                </div>

                <div className={style.listItem} data-testid={DataTestIds.LimitPopupListDescriptions}>
                  <div className={style.icon}>
                    <SVG.OtherIcons.Eye />
                  </div>
                  <div className={style.text}>
                    <Trans
                      id="core.operation.tooltipInvisible.sure"
                      values={{
                        operation: texts.operationText.nouns[textType],
                      }}
                    />
                  </div>
                </div>

                {kycNextTier && (
                  <div className={style.listItem} data-testid={DataTestIds.LimitPopupListDescriptions}>
                    <div className={style.icon}>
                      <SVG.OtherIcons.Unlock />
                    </div>
                    <div className={style.text}>
                      <Trans
                        id="core.operation.tooltipInvisible.upgrade"
                        values={{
                          operation: texts.operationText.verbs[textType],
                          count: nextTierLimits?.[textType].formatted,
                        }}
                        components={{
                          count: <span className={style.count} />,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </InteractiveModal>
      )}
    </div>
  )
})
