import clsx from 'clsx'
import React, { memo, InputHTMLAttributes, ReactNode, useMemo, useRef } from 'react'
import { SVG } from '@/assets/svg'
import { Button } from '@/ui/kit'
import LabelWithQuestion from '@/ui/molecules/LabelWithQuestion'
import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { useMobileSizeDetect } from '@/utils'
import { mergeRefs } from '@/utils/lib/mergeRefs'
import { useScrollToError } from '@/utils/hooks'

export enum InputSize {
  ExtraSmall = 'extraSmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export interface InputProps {
  /** value to display inside Input */
  value: string | number

  /** onChange handler */
  setValue: (val: string, name?: string) => void

  /** placeholder */
  placeholder?: string

  /** native input props */
  inputAttrs?: InputHTMLAttributes<HTMLInputElement>

  /** Input font size **/
  size: InputSize

  /** anything to display below Input */
  children?: React.ReactElement

  /** wrapper className */
  containerClassName?: string

  /** text to display as error */
  errorMessage?: ReactNode | false

  /** is Error state */
  isError?: boolean

  /** regExp to check after onChange fires */
  regExp?: RegExp

  /** string to replace in the value after onChange */
  replacer?: string

  /** Input label text */
  label?: string

  /** Input label hint text */
  labelHintText?: ReactNode

  /** is disabled state */
  disabled?: boolean

  /** is readOnly state */
  readOnly?: boolean

  /** styles for label */
  labelClassName?: string

  /** styles for error message */
  errorClassName?: string

  /** icon before input */
  icon?: ReactNode

  /**
   * Fire if user clicks to copy icon
   */
  onCopyToClipboard?: () => void

  /**
   * Button near input
   */
  button?: ReactNode

  info?: ReactNode

  dataTestId?: string

  autofocus?: boolean
}

const InputComponent = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      disabled,
      readOnly,
      label,
      inputAttrs,
      value,
      replacer = '',
      setValue,
      placeholder,
      regExp,
      isError,
      containerClassName,
      errorMessage,
      onCopyToClipboard,
      labelClassName,
      labelHintText,
      button,
      children,
      errorClassName,
      size,
      icon,
      info,
      dataTestId,
      autofocus,
    },
    ref
  ) => {
    const [isMobile] = useMobileSizeDetect()
    const inputRef = useRef<HTMLInputElement | null>(null)

    useScrollToError({ nodeRef: inputRef, isValid: !isError && !errorMessage })

    const sliceBeforeReplacer = (val: string) => {
      if (replacer) {
        const indexOfReplacer = val.indexOf(replacer)
        if (indexOfReplacer !== -1) {
          return val.slice(0, indexOfReplacer)
        }
      }
      return val
    }
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (regExp) {
        if (!e.target.value || regExp.test(e.target.value)) {
          setValue(sliceBeforeReplacer(e.target.value), e.target.name)
        }
      } else {
        setValue(sliceBeforeReplacer(e.target.value), e.target.name)
      }
    }

    const infoWrapVisible = useMemo(() => {
      return isError || errorMessage || info
    }, [isError, errorMessage, info])

    return (
      <div
        className={clsx(isMobile && style.mobile, style.container, style[size], containerClassName, {
          [style.infoWrapVisible]: infoWrapVisible,
        })}
      >
        {label && (
          <LabelWithQuestion
            hintText={labelHintText}
            className={clsx(style.label, labelClassName)}
            isLighter={disabled}
            label={label}
          />
        )}

        <div className={style.inputWrapper}>
          {icon}
          <input
            className={clsx(style.input, inputAttrs?.className, {
              [style.invalid]: errorMessage || isError,
            })}
            {...inputAttrs}
            disabled={disabled}
            readOnly={readOnly}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            ref={mergeRefs(inputRef, ref)}
            data-testid={dataTestId}
            autoFocus={autofocus}
          />
          {onCopyToClipboard && (
            <Button.Primary
              onClick={onCopyToClipboard}
              className={style.copyButton}
              leftIcon={<SVG.Additional.Copy />}
            />
          )}
          {button}
          {children}
        </div>

        {infoWrapVisible && (
          <div className={style.infoWrap}>
            {(isError || errorMessage) && (
              <span className={clsx(style.errorMessage, errorClassName)} data-testid={DataTestIds.InputAlert}>
                {errorMessage}
              </span>
            )}
            {info && <div className={style.info}>{info}</div>}
          </div>
        )}
      </div>
    )
  }
)

/** Standard Input */
export const Input = memo(InputComponent)
