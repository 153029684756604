import { AxiosResponse } from 'axios'
import { axiosMainService } from '@/services/axios'
import { binanceRestClients } from '@/services/axios/instances/binance_clients'
import { TUsdmIncomeHistory } from '@tigertrade/binance-ts'
import { createUsdmHistoryUid, createUsdmHistoryUidArray } from '@/redux/overview/overview.utils'

const overviewAPI2URL = 'overview/protected/api/v2/'

export const OverviewAPI = {
  async cancelOpenOrder<T, S>(payload: T): Promise<AxiosResponse<S>> {
    return await axiosMainService.post<S>(`${overviewAPI2URL}open-orders/cancel`, payload)
  },

  async cancelAllOpenOrders<T, S>(payload: T): Promise<AxiosResponse<S>> {
    return await axiosMainService.post<S>(`${overviewAPI2URL}open-orders/cancel/all`, payload)
  },

  async closePosition<T, S>(payload: T): Promise<AxiosResponse<S>> {
    return await axiosMainService.post<S>(`${overviewAPI2URL}positions/close`, payload)
  },

  async closeAllPositions<T, S>(payload: T): Promise<AxiosResponse<S>> {
    return await axiosMainService.post<S>(`${overviewAPI2URL}positions/close/all`, payload)
  },

  async getAllCoinsInfo() {
    return (await binanceRestClients.spot?.getAllCoins()) || Promise<undefined>
  },

  async getExchangeInfo() {
    return (await binanceRestClients.spot?.getExchangeInfoRaw()) || Promise<undefined>
  },

  async setPositionMode<T, S>(payload: T): Promise<AxiosResponse<S>> {
    return await axiosMainService.post<S>(`${overviewAPI2URL}positionMode`, payload)
  },

  async sellAsset<T, S>(payload: T): Promise<AxiosResponse<S>> {
    return await axiosMainService.post<S>(`${overviewAPI2URL}assets/sell`, payload)
  },
}

export const getHistorySinceTrx = async (args: {
  moment: number
  uid?: string[]
  page: number
}): Promise<AxiosResponse<TUsdmIncomeHistory[]>> => {
  const { moment, uid = [], page } = args
  const resultCurrent = (await binanceRestClients.usdm?.getIncomeHistory({ page })) as AxiosResponse<
    TUsdmIncomeHistory[]
  >
  const arrayResponseCurrentWithoutDuplicates: TUsdmIncomeHistory[] =
    resultCurrent?.data.filter((historyItem: TUsdmIncomeHistory) => {
      return historyItem.time >= moment && !uid.includes(createUsdmHistoryUid(historyItem))
    }) || []
  let resultBefore = undefined
  let arrayResponseBefore = []

  if (arrayResponseCurrentWithoutDuplicates.length === 0) {
    return { ...resultCurrent, data: arrayResponseCurrentWithoutDuplicates }
  } else if (arrayResponseCurrentWithoutDuplicates[0].time >= moment) {
    resultBefore = await getHistorySinceTrx({
      moment,
      uid: [...uid, ...createUsdmHistoryUidArray(arrayResponseCurrentWithoutDuplicates)],
      page: page + 1,
    })
    arrayResponseBefore = resultBefore.data
    return {
      ...resultBefore,
      data: [...arrayResponseBefore, ...arrayResponseCurrentWithoutDuplicates],
    }
  } else {
    return {
      ...resultCurrent,
      data: arrayResponseCurrentWithoutDuplicates.filter((historyItem: TUsdmIncomeHistory) => {
        return historyItem.time >= moment && !uid.includes(createUsdmHistoryUid(historyItem))
      }),
    }
  }
}
