import { DashboardQueryType } from '@/redux/dashboard/dashboard.types'
import { axiosMainService } from '@/services/axios'
import { BoardChunkedResponse, ServicesTradeDurationType } from '@/utils/types/contracts.types'

const API_URL = 'statistics-gtw/protected/api/v1/statistics'
const TMM_URL = '/proxy/api/v2'
const PREFIX = API_URL + TMM_URL

export const DashboardAPI = {
  async loadBoards(payload: DashboardQueryType) {
    const params = new URLSearchParams({ durationType: payload?.durationType?.toString() ?? '' })
    if (params.get('durationType') === ServicesTradeDurationType.TradeWithoutDuration) {
      params.delete('durationType')
    }
    params.toString()

    return await axiosMainService.get<BoardChunkedResponse>(`${PREFIX}/board/${payload.id}/load?${params}`)
  },
}
