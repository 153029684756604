interface IAppleProps {
  className?: string
}

export const TwoFA = (props: IAppleProps) => {
  const { className } = props

  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="0.5" width="40" height="40" rx="20" fill="url(#paint0_linear_18209_8995)" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5052 30.4306C25.3567 29.0902 28.9078 24.5088 28.9078 19.6172L28.9178 15.2259C28.9178 14.4056 28.2876 13.4753 27.5273 13.1652L21.9556 10.8846C20.7052 10.3744 19.2948 10.3744 18.0444 10.8846L12.4727 13.1652C11.7025 13.4753 11.0823 14.4056 11.0823 15.2259V19.6172C11.0823 24.5088 14.6334 29.0902 19.4849 30.4306C19.815 30.5206 20.1751 30.5206 20.5052 30.4306ZM17.7444 18.9712C17.7444 17.7274 18.7527 16.719 19.9966 16.719C21.2405 16.719 22.2489 17.7274 22.2489 18.9712C22.2489 20.0034 21.5545 20.8734 20.6075 21.1397V24.061C20.6075 24.3984 20.334 24.6719 19.9966 24.6719C19.6593 24.6719 19.3858 24.3984 19.3858 24.061V21.1397C18.4387 20.8734 17.7444 20.0034 17.7444 18.9712Z"
        fill="#FAFAFA"
      />
      <defs>
        <linearGradient
          id="paint0_linear_18209_8995"
          x1="-5.27786e-07"
          y1="40.5"
          x2="35.084"
          y2="-3.42643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC4ED" />
          <stop offset="0.482059" stopColor="#FEA471" />
          <stop offset="1" stopColor="#FF627E" />
        </linearGradient>
      </defs>
    </svg>
  )
}
