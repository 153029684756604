import { FieldErrorType } from '@/types'
import { Button, ButtonSize } from '@/ui/kit'
import { useGoogleAuthCode } from '@/utils/hooks'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { GoogleCodeVerification } from '../GoogleCodeVerification'
import style from './style.module.scss'
import { errorTranslations } from '@/core/constants/errors/errorsTranslations'

interface GoogleAuthenticatorFormProps {
  onSubmit?: (googleCode: string) => void
  onCancel?: () => void
  isSubmitDisabled?: boolean
  errors: FieldErrorType[]
  setErrors: (errors: FieldErrorType[]) => void
  footerText?: string
}

export const GoogleAuthenticatorForm: FC<GoogleAuthenticatorFormProps> = props => {
  const { onSubmit, onCancel, isSubmitDisabled, setErrors, errors, footerText } = props
  const googleCode = useGoogleAuthCode()
  const errorsTrans = errorTranslations()

  const errorsMapped = useMemo<Record<string, string>>(() => {
    return errors.reduce((acc: any, error: any) => {
      return {
        ...acc,
        [error.field]: error.message,
      }
    }, {})
  }, [errors])

  const submitHandler = useCallback(() => {
    const errors: FieldErrorType[] = []

    if (!googleCode.isCorrectCodeLength) {
      errors.push({
        code: 'googleCode',
        field: 'googleCode',
        message: t({
          id: 'services.gAuth.enter.title',
          comment: 'Enter the 6-digit code below',
        }),
      })
    }

    if (errors.length > 0) {
      setErrors(errors)
      return
    }
    setErrors([])

    onSubmit!(googleCode.code.join(''))
  }, [googleCode.isCorrectCodeLength, googleCode.code, setErrors, onSubmit])

  useEffect(() => {
    return () => {
      setErrors([])
    }
  }, [])

  return (
    <div className={style.component}>
      <div className={style.label}>
        <p className={style.title}>{t({ id: 'twoFa.title', comment: 'Two-factor authentication' })}</p>
        <p className={style.smallText}>
          {' '}
          {t({ id: 'twoFa.enterTheSixDigitCode', comment: 'Enter the six digit code from Google Authenticator' })}
        </p>
        <p className={style.enterCodeText}>
          {t({ id: 'twoFa.enterGoogleAuthenticatorCode', comment: 'Enter Google Authenticator code' })}
        </p>
        <GoogleCodeVerification
          code={googleCode.code}
          setCode={googleCode.setCode}
          isDisabled={isSubmitDisabled}
          errorMessage={errorsMapped.googleCode ? errorsTrans.googleCode : undefined}
        />
      </div>
      <div className={style.buttonsWrapper}>
        <Button.Primary
          size={ButtonSize.Medium}
          label={t({
            id: 'profile.whitelist.confirm',
            comment: 'Confirm',
          })}
          className={clsx(style.button, style.confirmButton)}
          onClick={submitHandler}
          disabled={isSubmitDisabled}
        />
        <Button.Primary
          size={ButtonSize.Medium}
          label={t({
            id: 'core.cancel',
            comment: 'cancel',
          })}
          className={style.button}
          onClick={onCancel}
        />
        <p className={clsx(style.smallText, style.footerText)}>
          {t({
            message: 'If you’re having issues, please contact support:',
            id: 'withdrawConfirmation.confirmGoogleAuth.contactSupport',
          })}{' '}
          {t({
            id: 'core.supportEmail',
            message: 'broker.support@tiger.trade',
          })}
        </p>
      </div>
    </div>
  )
}
