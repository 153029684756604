import {
  INotificationConfig,
  NotififcationPresets,
  SystemNotifiactionIcon,
} from '@/ui/molecules/SystemNotifications/notifications.type'
import { Trans } from '@lingui/react'
import styles from './style.module.scss'

const currentDateTimestamp = new Date().getTime()

export function getCashbackDelayAlert(): INotificationConfig {
  const preset: INotificationConfig = {
    id: NotififcationPresets.CashbackDelayAlert,
    icon: SystemNotifiactionIcon.Alert,
    heading: '',
    description: (
      <Trans
        id="core.systemAlert.cashbackDelayed.description"
        components={{
          colored: <span className={styles.colored}></span>,
          gradient: <span className={styles.gradientText}></span>,
        }}
      />
    ),
    shouldShown: currentDateTimestamp < new Date('2024-06-28').getTime(),
    availableExchange: ['BYBIT_BROKER'],
    isClosable: true,
  }

  return preset
}
