import { EPartners } from '@/redux/partner/partner.types'

export enum EROUTER_HISTORY_PARAMS {
  'transactions' = 'transactions',
  'trade' = 'trade',
}

export enum EROUTER_TEAM_PARAMS {
  'members' = 'members',
  'requests' = 'requests',
}

export const urls = {
  root: '/',
  wallet: '/wallet',
  signin: '/auth',
  signup: '/signup',
  orders: '/orders',
  cashback: '/commission',
  transactions: '/transactions',
  historyRoot: '/history',
  historyTransactions: `/history/${EROUTER_HISTORY_PARAMS.transactions}`,
  historyTrade: `/history/${EROUTER_HISTORY_PARAMS.trade}`,
  trades: '/journal/trades',
  summary: '/journal/summary',
  summaryAnalyze: '/journal/summary/analyze',
  dashboard: '/journal/dashboard',
  assets: '/assets',
  api: '/api',
  apiCreate: '/api/create',
  apiEdit: '/api/edit/:id',
  how: '/how-it-works',
  kyc: '/kyc',
  terms: '/terms',
  policy: '/policy',
  profile: '/profile',
  tigerToken: '/tiger-token',
  leverage: '/leverage',
  referrals: '/referrals',
  referralEarnings: '/referrals/earnings',
  howReferral: '/how-referrals-work',
  kycLevels: '/kyc-levels',
  voucher: '/voucher',
  chooseExchange: '/choose-exchange',
  partnerRoot: '/partner',
  partner: '/partner/:partnerId',
  teamRoot: '/team',
  teamMembers: `/team/${EROUTER_TEAM_PARAMS.members}`,
  teamRequest: `/team/${EROUTER_TEAM_PARAMS.requests}`,
  addresses: '/addresses',
  createAddresses: '/addresses/create',
  editAddresses: '/addresses/edit/:id',
  riskManager: '/risk-manager',
  okxAffiliate: '/okx-affiliate',
}

export const ROUTER_HISTORY_PARAM_NAME = 'historyType'
export const ROUTER_TEAM_PARAM_NAME = 'teamType'
export const TRADE_ID = 'tradeId'
export const TRADE_DIARY_WEEK = 'tradeDiaryWeek'
export const TRADE_ANALYZE_MONTH = 'tradeDiaryMonth'

export const INIT_SEARCH_PARAM = 'init'
export const REFERRAL_SEARCH_PARAM = 'referral'

export const PUBLIC_ROUTES = [urls.terms, urls.policy]

// todo create test
export const navigateToApiEdit = (id: string) => urls.apiEdit.replace(/:id/, id)
export const navigateToEditAddresses = (id: string) => urls.editAddresses.replace(/:id/, id)

export const partnerLinkCreator = (partnerId: EPartners) => {
  return `${urls.partnerRoot}/${partnerId}`
}

export const referralLinkCreator = (referralLink: string) => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`
  return `${baseUrl}${urls.signup}?referral=${referralLink}`
}
