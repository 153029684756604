interface HourglassProps {
  className?: string
}

export const Hourglass = (props: HourglassProps) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <rect width="30" height="30" rx="15" fill="#676573" />
    <path
      d="M9.75 10.5C9.75 9.25736 10.7574 8.25 12 8.25H17.9998C19.2425 8.25 20.2499 9.25749 20.2498 10.5002L20.2498 10.796C20.2497 11.5482 19.8737 12.2506 19.2479 12.6679L16.686 14.376C16.2407 14.6728 16.2407 15.3272 16.686 15.624L19.2479 17.3321C19.8737 17.7494 20.2497 18.4518 20.2498 19.204L20.2498 19.4998C20.2499 20.7425 19.2425 21.75 17.9998 21.75H12C10.7574 21.75 9.75 20.7426 9.75 19.5V19.2042C9.75 18.4519 10.126 17.7493 10.7519 17.3321L13.3139 15.624C13.7592 15.3272 13.7592 14.6728 13.3139 14.376L10.7519 12.6679C10.126 12.2507 9.75 11.5481 9.75 10.7958V10.5Z"
      stroke="#FAFAFA"
      strokeWidth="1.125"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.354 17.1842C14.7252 16.9367 15.2746 16.9366 15.6458 17.1841L18.2078 18.8922C18.3121 18.9618 18.3747 19.0788 18.3748 19.2042L18.3748 19.5C18.3748 19.7071 18.2069 19.875 17.9998 19.875H12C11.7929 19.875 11.625 19.7072 11.625 19.5V19.2042C11.625 19.0788 11.6877 18.9617 11.792 18.8922L14.354 17.1842Z"
      fill="#FAFAFA"
    />
  </svg>
)
